@charset "UTF-8";

@import '../../@tmds/tmdscolor';
@import '../../@tmds/tmdsfonts';
@import '../../@tmds/variables/color';

@import '../../basics/variables';
@import '../../basics/mixins';
@import '../../basics/functions';
@import '../../basics/extends';
@import '../../basics/keyframes';
@import '../../basics/tmdscolors';
@import '../../basics/publictranscolors';

.wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;

  margin-top: 92px;

  .image {
    margin-bottom: 24px;

    img {
      width: 184px;
      height: 100px;
    }
  }

  .title {
    @include font-size-18-bold();
    color: $gray-600;
    text-align: center;
  }

  .desc {
    @include font-size-16();
    color: $gray-400;
    text-align: center;
  }
}
