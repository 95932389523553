@charset "UTF-8";

@import '../@tmds/tmdscolor';
@import '../@tmds/tmdsfonts';
@import '../@tmds/variables/color';

@import '../basics/variables';
@import '../basics/mixins';
@import '../basics/functions';
@import '../basics/extends';
@import '../basics/keyframes';
@import '../basics/tmdscolors';
@import '../basics/publictranscolors';

.internal_banner {
  @include common-banner-style;
  @include common-banner-layout;
  height: 56px;

  img {
    height: 100%;
  }
}

.ranking_banner_wrap {
  padding: 20px;
}
