@charset "UTF-8";

@import '../../@tmds/tmdscolor';
@import '../../@tmds/tmdsfonts';
@import '../../@tmds/variables/color';

@import '../../basics/variables';
@import '../../basics/mixins';
@import '../../basics/functions';
@import '../../basics/extends';
@import '../../basics/keyframes';
@import '../../basics/tmdscolors';
@import '../../basics/publictranscolors';

.wrap {
  display: inline-flex;
  align-items: center;
}
.star_point {
  @include tmobi-font-bold(12px, 16px);
  display: inline-flex;
  align-items: center;
  gap: 0 2px;
  margin-right: 4px;
  color: $gray-900;
}
.review {
  @include tmobi-font-medium(12px, 16px);
  color: $gray-600;
}
.info {
  @include tmobi-font-medium(12px, 16px);
  color: $gray-400;
}

.dot {
  * {
    fill: $gray-200;
  }
}
