@charset "UTF-8";

@import '../@tmds/tmdscolor';
@import '../@tmds/tmdsfonts';
@import '../@tmds/variables/color';

@import '../basics/variables';
@import '../basics/mixins';
@import '../basics/functions';
@import '../basics/extends';
@import '../basics/keyframes';
@import '../basics/tmdscolors';
@import '../basics/publictranscolors';

@include keyframeBlink();

.skeleton_wrap {
  position: absolute;
  z-index: $Z-skeleton;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: $wb-white;

  &.alpha0 {
    background-color: $C-white-tr-00;
  }

  .skeleton {
    opacity: 1;
    @include infiniteBlink;
    transition: opacity ease-in-out 0.3s;

    &.fade_out {
      animation: none;
      opacity: 0;
    }

    &.ignore_blink {
      animation: none;
    }
  }
}

.skeleton_list_wrap {
  display: flex;
  flex-direction: column;
  margin-top: 32px;

  .skeleton_item_wrap {
    padding: 0px 16px 63px 20px;

    .position {
      position: relative;
    }

    .skeleton_title {
      width: 120px;
      height: 28px;

      @include gradient;
    }

    .skeleton_desc {
      width: 300px;
      height: 20px;

      margin-top: 8px;
      @include gradient;
    }
  }
}

.skeleton_category_header_wrap {
  display: flex;
  padding: 0 20px;
  padding-top: 46px;
  flex-direction: column;

  .bottom {
    display: inline-flex;
    flex-direction: row;
    width: fit-content;
    background-image: url(#skeleton_gradient);
    position: relative;
    // @include gradient();

    .item {
      width: 80px;
      height: 30px;
      margin-right: 4px;
      background-color: inherit;
      border-radius: 20px;
      mask: linear-gradient(#000 0 0);

      &:last-child {
        margin-right: 0;
      }

      &::before {
        content: '';
        position: absolute;
        z-index: -1;
        inset: 0;
        @include gradient;
      }
    }
  }
}

.skeleton_search_header {
  position: relative;
  padding: 5px 0px 5px 20px;

  .skeleton_filter {
    display: inline-block;
    width: 190px;
    height: 32px;
    @include gradient;
  }
}

.skeleton_poi_main_list {
  padding-top: 5px;
  background-color: $wb-white;

  .skeleton_banner {
    width: 100%;
    height: 56px;
    @include gradient;
  }

  .skeleton_poi_item {
    position: relative;
    padding: 24px 0px 42px 20px;
    border-bottom: 1px solid $gray-100;

    .skeleton_title {
      width: 120px;
      height: 28px;
      margin-bottom: 8px;

      @include gradient;
    }

    .skeleton_desc {
      width: 300px;
      height: 20px;

      @include gradient;
    }
  }
}

.skeleton_place_quick_link {
  padding-left: 16px;
  width: 100vw;

  .item {
    width: 112px;
    height: 34px;
    margin: 2px 4px 5px 0px;
    background-color: $wb-white;
    border-radius: 18px;
    padding: 0px 11px 0px 7px;
    display: inline-flex;
    align-items: center;
    border-radius: 18px;
    box-shadow: 0px 1px 3px $black-alpha-300;

    i {
      display: inline-block;
      width: 22px;
      height: 22px;
      border-radius: 100%;
      margin-right: 4px;
      flex-shrink: 0;
      @include gradient;
      @include infiniteBlink;
    }
    span {
      display: inline-block;
      width: 68px;
      height: 16px;
      @include gradient;
      @include infiniteBlink;
    }
  }
}

.skeleton_search_quick_link {
  padding-left: 16px;
  width: 100vw;

  .item {
    width: 100px;
    height: 32px;
    margin: 2px 4px 5px 0px;
    background-color: $wb-white;
    border-radius: 18px;
    padding: 0px 11px 0px 7px;
    display: inline-flex;
    align-items: center;
    border-radius: 18px;
    box-shadow: 0px 1px 3px $black-alpha-300;

    i {
      display: inline-block;
      width: 18px;
      height: 18px;
      border-radius: 100%;
      margin-right: 4px;
      flex-shrink: 0;
      @include gradient;
      @include infiniteBlink;
    }
    span {
      display: inline-block;
      width: 60px;
      height: 20px;
      @include gradient;
      @include infiniteBlink;
    }
  }
}

.skeleton_search_main {
  margin: 24px 36px 0 24px;

  .quick_link {
    .item {
      @include gradient;
      @include infiniteBlink;
      display: inline-block;
      width: 80px;
      height: 32px;
      border-radius: 24px;
      margin-right: 6px;
      vertical-align: top;
    }
  }
  .list {
    margin-top: 40px;

    .item {
      @include gradient;
      @include infiniteBlink;
      height: 30px;
      margin-top: 20px;

      &:nth-child(2) {
        margin-right: 70px;
      }
      &:last-of-type {
        margin-right: 130px;
      }
    }
  }
}

.skeleton_pick_on_map {
  padding: 3px 0px 0px 20px;

  i {
    @include gradient;
    @include infiniteBlink;

    display: inline-block;
    width: 24px;
    height: 24px;
    margin-right: 4px;
  }

  span {
    @include gradient;
    @include infiniteBlink;

    display: inline-block;
    width: 259px;
    height: 24px;
  }
}

.skeleton_callout {
  .title {
    @include gradient;
    @include infiniteBlink;

    width: 150px;
    height: 28px;
    margin-bottom: 4px;
  }

  .desc {
    @include gradient;
    @include infiniteBlink;

    width: 259px;
    height: 18px;
    margin-bottom: 10px;
  }

  .button {
    display: flex;
    gap: 12px;

    height: 60px;
    padding-top: 12px;
    padding-bottom: 20px;

    span {
      @include gradient;
      @include infiniteBlink;

      display: inline-block;
      width: 28px;
      height: 28px;
    }
  }

  .list {
    padding: 0px 16px 12px 20px;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;

    .text {
      flex-grow: 1;
      .title {
        margin-top: 16px;
        margin-right: 44px;
        margin-bottom: 10px;
        height: 24px;
        @include gradient;

        &::after {
          content: ' ';
          width: 100%;
        }
      }

      .desc {
        @include gradient;
        height: 18px;

        &:nth-last-child(2) {
          margin-right: 64px;
          @include gradient;

          &::after {
            content: ' ';
            width: 100%;
          }
        }
        &:nth-last-child(1) {
          margin-top: 4px;
          margin-right: 153px;
          @include gradient;

          &::after {
            content: ' ';
            width: 100%;
          }
        }
      }
    }
    .image {
      @include gradient;
      @include infiniteBlink;
      width: 100px;
      height: 100px;
      border-radius: 8px;
    }
  }
}

.skeleton_theme_board_recommend {
  padding: 28px 0px 28px 60px;

  .header {
    @include gradient;
    @include infiniteBlink;

    height: 120;
    margin-bottom: 40px;
  }

  .list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 6px;

    .item {
      .image {
        @include gradient;
        @include infiniteBlink;

        width: 130px;
        height: 80px;
        margin-bottom: 10px;
      }
      .desc {
        @include gradient;
        @include infiniteBlink;

        width: 110px;
        height: 38px;
      }
    }
  }
  .landing {
    @include gradient;
    @include infiniteBlink;

    height: 20px;
    margin-top: 32px;
    margin-right: 40px;
  }
}

.skeleton_search_recommendation {
  position: absolute;
  left: 10px;

  .item {
    position: relative;

    width: 20px;
    height: 22px;

    @include gradient;

    &::after {
      content: ' ';
      position: absolute;
      left: 22px;

      width: 200px;
      height: 22px;
      margin-left: 8px;

      @include gradient;
    }
  }
}

.skeleton_search_recommendation_open {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-self: center;

  height: 264px;

  .title {
    width: 120px;
    height: 22px;

    margin: 16px 0 0 20px;

    @include gradient;
  }

  .items {
    display: flex;
    flex-direction: column;
    gap: 22px;

    margin: 20px 0 0 20px;

    .item {
      position: relative;

      width: 22px;
      height: 22px;

      @include gradient;

      &::after {
        content: ' ';
        position: absolute;
        left: 22px;

        width: 200px;
        height: 22px;
        margin-left: 10px;

        @include gradient;
      }
    }
  }
}

.skeleton_favorite_list {
  padding-top: 17px;
  .item {
    padding: 0px 30px 30px 20px;
    display: flex;

    i {
      @include gradient;
      @include infiniteBlink;

      display: inline-block;
      width: 24px;
      height: 24px;
      margin-right: 12px;
    }

    span {
      @include gradient;
      @include infiniteBlink;

      display: inline-block;
      height: 24px;
      flex-grow: 1;
    }
  }
}

.skeleton_search_ranking_dropdown {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  padding-left: 12px;

  .item {
    width: 100px;
    height: 32px;
    border-radius: 8px;

    @include gradient;
    @include infiniteBlink;
  }
}

.skeleton_festival_list {
  .item {
    display: flex;
    gap: 16px;
    padding: 18px 16px;

    .thumbnail {
      width: 80px;
      height: 80px;
      border-radius: 6px;
      overflow: hidden;

      @include gradient;
      @include infiniteBlink;
    }

    .content {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 8px;
      padding-top: 8px;

      .title {
        width: 100%;
        max-width: 180px;
        height: 20px;

        @include gradient;
        @include infiniteBlink;
      }
      .desc {
        width: 100%;
        max-width: 120px;
        height: 16px;

        @include gradient;
        @include infiniteBlink;
      }
    }
  }
}
