@charset "UTF-8";

@import '../../@tmds/tmdscolor';
@import '../../@tmds/tmdsfonts';
@import '../../@tmds/variables/color';

@import '../../basics/variables';
@import '../../basics/mixins';
@import '../../basics/functions';
@import '../../basics/extends';
@import '../../basics/keyframes';
@import '../../basics/tmdscolors';
@import '../../basics/publictranscolors';

.wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px 0 200px;
  background: linear-gradient(180deg, $gray-50 0%, $wb-white 100%);

  .link {
    @include touch-guide(50%);
    @include override-svg($gray-800, 24px, 24px);
    position: relative;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background-color: $wb-white;
    filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.1));
  }
}
