@charset "UTF-8";

@import '../@tmds/tmdscolor';
@import '../@tmds/tmdsfonts';
@import '../@tmds/variables/color';

@import '../basics/variables';
@import '../basics/mixins';
@import '../basics/functions';
@import '../basics/extends';
@import '../basics/keyframes';
@import '../basics/tmdscolors';
@import '../basics/publictranscolors';

.error_wrap {
  display: flex;
  flex-direction: column;
  align-items: center;

  .title {
    margin: 0;
    width: 100%;
    text-align: center;
    color: $gray-600;
    @include font-size-18-bold();
  }

  .desc {
    color: $gray-400;
    margin-top: 8px;
    @include font-size-16();
  }

  .search_button {
    @include font-size-16();
    margin-top: 40px;
    padding: 0 20px;
    height: 40px;
    background-color: $tmobi-blue-500;
    color: $wb-white;
    border-radius: 5px;
    @include font-size-16();

    position: relative;

    @include touch-guide(5px);
  }

  .default {
    color: $gray-900;
    border: 1px solid $gray-300;
    border-radius: 8px;
    background-color: $wb-white;
  }
}
