/**
    TMDS Core 1.0
    2023.11.20
    https://www.figma.com/file/XYc3r90K3z5njGXT6KITfr/TMDS_Core_v1.0?type=design&node-id=655-3284&mode=dev
 */

/* 🌝Light */
/* colors */
$black-alpha-100: #00000014;
$black-alpha-200: #00000024;
$black-alpha-300: #00000033;
$black-alpha-400: #0000004d;
$black-alpha-50: #0000000a;
$black-alpha-500: #00000073;
$black-alpha-600: #00000099;
$black-alpha-700: #000000b2;
$black-alpha-800: #000000cc;
$black-alpha-900: #000000e5;
$gray-100: #eef0f3;
$gray-200: #e0e2e8;
$gray-300: #bec5cc;
$gray-400: #8c9299;
$gray-50: #f8f9fa;
$gray-500: #6e747a;
$gray-600: #51565c;
$gray-700: #36393d;
$gray-800: #27292d;
$gray-900: #171819;
$green-100: #e3f7eb;
$green-200: #c3efd3;
$green-300: #82dea3;
$green-400: #3ecb71;
$green-50: #f7fdf9;
$green-500: #25b856;
$green-600: #1e9445;
$green-700: #176e34;
$green-800: #0e4420;
$green-900: #082b14;
$orange-100: #fff1db;
$orange-200: #ffe1b8;
$orange-300: #ffc880;
$orange-400: #ffa633;
$orange-50: #fffbf5;
$orange-500: #ff9000;
$orange-600: #d17600;
$orange-700: #985b01;
$orange-800: #603901;
$orange-900: #3d2300;
$red-100: #fdebe7;
$red-200: #fddad3;
$red-300: #fca492;
$red-400: #f46548;
$red-50: #fef7f6;
$red-500: #f24724;
$red-600: #ca2c0c;
$red-700: #a3240b;
$red-800: #6e1807;
$red-900: #521205;
$sky-100: #e5f7fc;
$sky-200: #bfedfc;
$sky-300: #89dcfb;
$sky-400: #43c7f9;
$sky-50: #f4fcfe;
$sky-500: #00ace5;
$sky-600: #008ec2;
$sky-700: #00678a;
$sky-800: #004157;
$sky-900: #00374a;
$tmobi-blue-100: #e6f1ff;
$tmobi-blue-200: #cce0ff;
$tmobi-blue-300: #8ab9ff;
$tmobi-blue-400: #3d8bff;
$tmobi-blue-50: #f5f9ff;
$tmobi-blue-500: #0064ff;
$tmobi-blue-600: #0052d1;
$tmobi-blue-700: #09429f;
$tmobi-blue-800: #113063;
$tmobi-blue-900: #15223d;
$tmobi-pink-100: #ffe9f9;
$tmobi-pink-200: #ffd6f3;
$tmobi-pink-300: #ffa3de;
$tmobi-pink-400: #ff61c2;
$tmobi-pink-50: #fef7fc;
$tmobi-pink-500: #fa32aa;
$tmobi-pink-600: #d30d85;
$tmobi-pink-700: #ad0b6c;
$tmobi-pink-800: #7c084d;
$tmobi-pink-900: #4c0530;
$tmobi-purple-100: #f1ebfe;
$tmobi-purple-200: #e3d8fe;
$tmobi-purple-300: #c3aafd;
$tmobi-purple-400: #976cfb;
$tmobi-purple-50: #f9f7ff;
$tmobi-purple-500: #773efa;
$tmobi-purple-600: #5e2dcd;
$tmobi-purple-700: #4d25a7;
$tmobi-purple-800: #351e6c;
$tmobi-purple-900: #221443;
$tmobi-teal-100: #dff6f5;
$tmobi-teal-200: #bfedec;
$tmobi-teal-300: #7cdfdc;
$tmobi-teal-400: #2dcdc7;
$tmobi-teal-50: #f1fbfb;
$tmobi-teal-500: #00b2b2;
$tmobi-teal-600: #009494;
$tmobi-teal-700: #006b6b;
$tmobi-teal-800: #02454b;
$tmobi-teal-900: #012e32;
$wb-black: #000000;
$wb-white: #ffffff;
$white-alpha-100: #ffffff1a;
$white-alpha-200: #ffffff33;
$white-alpha-300: #ffffff4d;
$white-alpha-400: #ffffff66;
$white-alpha-50: #ffffff0d;
$white-alpha-500: #ffffff80;
$white-alpha-600: #ffffff99;
$white-alpha-700: #ffffffb2;
$white-alpha-800: #ffffffcc;
$white-alpha-900: #ffffffe5;
$yellow-100: #fff4d6;
$yellow-200: #ffe8ad;
$yellow-300: #ffda80;
$yellow-400: #ffc94d;
$yellow-50: #fffbf1;
$yellow-500: #ffbd05;
$yellow-600: #e0a500;
$yellow-700: #a37800;
$yellow-800: #664b00;
$yellow-900: #3d2d00;
