@charset "UTF-8";

@import '../@tmds/tmdscolor';
@import '../@tmds/tmdsfonts';
@import '../@tmds/variables/color';

@import '../basics/variables';
@import '../basics/mixins';
@import '../basics/functions';
@import '../basics/extends';
@import '../basics/keyframes';
@import '../basics/tmdscolors';
@import '../basics/publictranscolors';

.detail_action {
  border: none;
  height: 36px;
  border-radius: 22px;
  box-shadow: 0px 1px 3px $black-alpha-300;
  background-color: $wb-white;
  color: $tmobi-blue-500;
  padding: 8px 16px;
  @include font-size-14();

  position: relative;
  @include touch-guide(23px, $opacity: 0.2);

  &:active {
    color: $tmobi-blue-500;
  }
}
