@charset "UTF-8";

@import '../@tmds/tmdscolor';
@import '../@tmds/tmdsfonts';
@import '../@tmds/variables/color';

@import '../basics/variables';
@import '../basics/mixins';
@import '../basics/functions';
@import '../basics/extends';
@import '../basics/keyframes';
@import '../basics/tmdscolors';
@import '../basics/publictranscolors';

.wrap {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 14px;
  padding: 14px;

  border: 1px solid $gray-200;
  border-radius: 8px;
  background: $wb-white;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
}

.contents {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  white-space: nowrap;

  color: $gray-600;
  @include tmobi-font-normal(13px);
  // https://tmobi.atlassian.net/browse/LOCALQA-847
  // ios 18 미만 버전 z-index 이슈, 상위 컴포넌트에 적용해도 하위에 적용되어야함.
  z-index: $Z-tooltip-popup;
}

.link {
  color: $tmobi-blue-600;
  @include font-size-12();
}
