@charset "UTF-8";

@import '../../@tmds/tmdscolor';
@import '../../@tmds/tmdsfonts';
@import '../../@tmds/variables/color';

@import '../../basics/variables';
@import '../../basics/mixins';
@import '../../basics/functions';
@import '../../basics/extends';
@import '../../basics/keyframes';
@import '../../basics/tmdscolors';
@import '../../basics/publictranscolors';

.search_recent_wrap {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.scroll_wrap {
  flex: 1;
  overflow-y: scroll;
  transform: translateZ(0);
}

.no_result {
  display: flex;
  justify-content: center;
  margin-top: 150px;
  font-size: 17px;
  line-height: 1.29;
  color: $gray-400;

  &.is_landscape {
    margin-top: 0;
    align-items: center;
    height: 100%;
  }
}

.bottom_opt_wrap {
  display: flex;
  justify-content: flex-end;
  margin: 0 0 24px 0;

  .btn_select_all {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0 20px 0;
    position: relative;

    color: $gray-600;

    @include font-size-16-bold();

    @include touch-guide(5px, $zIndex: $Z-touch-guide);

    span {
      margin-right: 8px;
    }
  }
}

.banner {
  padding-bottom: 10px;
}
