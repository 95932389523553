html {
  font-family: $font-family;
  user-select: none;
  -webkit-user-select: none;
  outline: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

ul,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

p,
button {
  background: none;
  border: none;
  padding: 0;
  margin: 0;
}

button {
  color: $wb-black;

  &:focus-visible {
    outline: none;
  }
  &:focus {
    outline: none;
  }
  &:active {
    color: $wb-black;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
  margin: 0;
  padding: 0;
}

em {
  font-style: normal;
}

a {
  -webkit-touch-callout: none; /* Safari Touch */
}
