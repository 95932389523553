@charset "UTF-8";

@import '../../@tmds/tmdscolor';
@import '../../@tmds/tmdsfonts';
@import '../../@tmds/variables/color';

@import '../../basics/variables';
@import '../../basics/mixins';
@import '../../basics/functions';
@import '../../basics/extends';
@import '../../basics/keyframes';
@import '../../basics/tmdscolors';
@import '../../basics/publictranscolors';

.suggest_item {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 19px 18px 19px 20px;
  border-bottom: 1px solid $gray-50;
  position: relative;

  @include touch-guide();

  &::before {
    position: absolute;
    content: '';
    left: 0;
    bottom: -1px;
    height: 0px;
    width: 20px;
    border-bottom: 1px solid $wb-white;
  }

  .line_wrap {
    display: flex;
    justify-content: space-between;

    &:not(:first-child) {
      margin-top: 5px;
    }
  }

  .name {
    color: $wb-black;
    font-size: 18px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-bottom: 2px;

    em {
      color: $tmobi-blue-500;
    }
  }

  .address {
    color: $gray-400;
    font-size: 15px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .category {
    color: $gray-400;
    font-size: 15px;
    word-break: keep-all;
  }

  .distance {
    color: $gray-400;
    font-size: 15px;
  }
}
