@charset "UTF-8";

@import '../@tmds/tmdscolor';
@import '../@tmds/tmdsfonts';
@import '../@tmds/variables/color';

@import '../basics/variables';
@import '../basics/mixins';
@import '../basics/functions';
@import '../basics/extends';
@import '../basics/keyframes';
@import '../basics/tmdscolors';
@import '../basics/publictranscolors';

.share {
  width: 28px;
  height: 28px;

  &:not([disabled]) {
    position: relative;
    @include touch-guide(5px, $top: -4px, $left: -4px, $right: -4px, $bottom: -4px);
    @include override-svg($gray-700, 28px, 28px);
  }

  &[disabled] {
    @include override-svg($gray-300, 28px, 28px);
  }

  &[data-on='true'] {
    @include override-svg($C-yellow-500, 28px, 28px);
  }
}
