@charset "UTF-8";

@import '../../@tmds/tmdscolor';
@import '../../@tmds/tmdsfonts';
@import '../../@tmds/variables/color';

@import '../../basics/variables';
@import '../../basics/mixins';
@import '../../basics/functions';
@import '../../basics/extends';
@import '../../basics/keyframes';
@import '../../basics/tmdscolors';
@import '../../basics/publictranscolors';

.search_tab_header {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid $gray-200;
  padding: 0 16px;

  .header_item {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    height: 56px;
    @include font-size-16();
    color: $gray-900;

    &.active {
      color: $tmobi-blue-500;
      font-family: $font-family;
      font-weight: 500;
      position: relative;

      &::after {
        position: absolute;
        content: '';
        left: 0;
        bottom: -1px;
        height: 0px;
        width: 100%;
        border-bottom: 3px solid $tmobi-blue-500;
      }
    }
  }
}
