@charset "UTF-8";

@import '../../@tmds/tmdscolor';
@import '../../@tmds/tmdsfonts';
@import '../../@tmds/variables/color';

@import '../../basics/variables';
@import '../../basics/mixins';
@import '../../basics/functions';
@import '../../basics/extends';
@import '../../basics/keyframes';
@import '../../basics/tmdscolors';
@import '../../basics/publictranscolors';

.tile_item {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 64px;
  width: 33.33%;
  border-right: solid 1px $gray-100;
  border-bottom: solid 1px $gray-100;
  background-color: $wb-white;
  color: $gray-900;
  text-align: center;
  position: relative;

  @include font-size-18();

  @include touch-guide();

  &:nth-child(3n) {
    border-right: 0 none;
  }
}

.list_item {
  display: flex;
  align-items: center;
  padding: 16px 20px;
  border-bottom: solid 1px $gray-100;
  background-color: $wb-white;
  color: $gray-900;
  position: relative;

  @include font-size-18();

  @include touch-guide();
}
