@charset "UTF-8";

@import '../../@tmds/tmdscolor';
@import '../../@tmds/tmdsfonts';
@import '../../@tmds/variables/color';

@import '../../basics/variables';
@import '../../basics/mixins';
@import '../../basics/functions';
@import '../../basics/extends';
@import '../../basics/keyframes';
@import '../../basics/tmdscolors';
@import '../../basics/publictranscolors';

.wrap {
  width: 100%;
  height: 90px;
  overflow: hidden;

  margin-top: 12px;

  &.is_scroll {
    overflow-y: hidden;
    @include scroll-wrap(x);
    transform: translateZ(0);

    .image_list {
      padding: 0;
      width: fit-content;

      .img {
        width: 115px;
        height: 90px;

        &:first-child {
          margin-left: 16px;
        }

        &:last-child {
          margin-right: 16px;
        }
      }
    }
  }

  .image_list {
    display: flex;
    gap: 2px;

    padding: 0 16px;
    height: 90px;

    .img {
      width: calc(100% / var(--img-count, 3));
      height: 90px;

      flex-shrink: 0;

      &:first-child {
        border-radius: 6px 0 0 6px;
      }

      &:last-child {
        border-radius: 0 6px 6px 0;
      }
    }
  }
}
