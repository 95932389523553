@charset "UTF-8";

@import '../@tmds/tmdscolor';
@import '../@tmds/tmdsfonts';
@import '../@tmds/variables/color';

@import '../basics/variables';
@import '../basics/mixins';
@import '../basics/functions';
@import '../basics/extends';
@import '../basics/keyframes';
@import '../basics/tmdscolors';
@import '../basics/publictranscolors';

.wrap {
  @include font-size-14();
  display: flex;
  align-items: center;

  .divider {
    display: inline-flex;
    width: 12px;
    height: 12px;

    @include override-svg($gray-300, 12px, 12px);
  }

  [data-color='pink600'] {
    color: $C-tmobi-pink-600;
  }
}

.text {
  flex-shrink: 0;
}
