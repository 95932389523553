@charset "UTF-8";

@import '../@tmds/tmdscolor';
@import '../@tmds/tmdsfonts';
@import '../@tmds/variables/color';

@import '../basics/variables';
@import '../basics/mixins';
@import '../basics/functions';
@import '../basics/extends';
@import '../basics/keyframes';
@import '../basics/tmdscolors';
@import '../basics/publictranscolors';

.tag {
  @include font-size-11();
  display: inline-flex;
  align-items: center;
  height: 18px;
  border-radius: 4px;
  letter-spacing: -0.48px;
  padding: 0px 4px;
  background: $gray-50;
  color: $gray-600;

  &.icon {
    padding: 0px 4px 0px 2px;
    img {
      width: 18px;
      height: 18px;
    }
  }
}
