@charset "UTF-8";

@import '../@tmds/tmdscolor';
@import '../@tmds/tmdsfonts';
@import '../@tmds/variables/color';

@import '../basics/variables';
@import '../basics/mixins';
@import '../basics/functions';
@import '../basics/extends';
@import '../basics/keyframes';
@import '../basics/tmdscolors';
@import '../basics/publictranscolors';

.marker_wrap {
  display: inline-flex;
  align-items: center;
  flex-direction: column;
  text-align: center;

  &.disable_click {
    pointer-events: none;
  }

  @include marker-poi-name;
}

:global(.vsm-marker) {
  &[data-type='CENTER_POINT'] {
    z-index: 7;
  }

  &[data-type='CURRENT_POSITION'] {
    z-index: 6;
  }

  &[data-type='ACTIVE'],
  &[data-type='ACTIVE_BUS_STATION'],
  &[data-type='ACTIVE_SUBWAY'],
  &[data-type='ACTIVE_RANKING'] {
    z-index: 5;
  }

  &[data-type='INACTIVE'],
  &[data-type='INACTIVE_RANKING'] {
    z-index: 4;
  }

  &[data-type^='ACTIVE_SAVE_POI_'],
  &[data-type='ACTIVE_FAVORITE'],
  &[data-type='ACTIVE_FAVORITE_HOME'],
  &[data-type='ACTIVE_FAVORITE_OFFICE'],
  &[data-type='ACTIVE_FAVORITE_PUBLIC_TRANS'],
  &[data-type='ACTIVE_RECENT_DESTINATION'] {
    z-index: 4;
  }
}
