@charset "UTF-8";

@import '../@tmds/tmdscolor';
@import '../@tmds/tmdsfonts';
@import '../@tmds/variables/color';

@import '../basics/variables';
@import '../basics/mixins';
@import '../basics/functions';
@import '../basics/extends';
@import '../basics/keyframes';
@import '../basics/tmdscolors';
@import '../basics/publictranscolors';

.wrap {
  width: 26px;
  height: 28px;
  position: relative;
  display: inline-flex;
  justify-content: center;
  --label-color: $gray-900;
  --border-color: $wb-white;

  @include marker-poi-name(var(--label-color), inherit, var(--border-color));

  img {
    width: 100%;
    height: 100%;
  }

  i {
    width: 16px;
    height: 16px;
    position: absolute;
    top: 5px;
    left: 5px;

    svg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  &[data-type='ACTIVE'] {
    width: 39px;
    height: 53px;

    img {
      width: 100%;
      height: 100%;
    }

    i {
      width: 23px;
      height: 23px;
      top: 8px;
      left: 8px;
    }
  }

  svg.base {
    width: 100%;
    height: 100%;
  }
}
