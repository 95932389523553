@charset "UTF-8";

@import '../../@tmds/tmdscolor';
@import '../../@tmds/tmdsfonts';
@import '../../@tmds/variables/color';

@import '../../basics/variables';
@import '../../basics/mixins';
@import '../../basics/functions';
@import '../../basics/extends';
@import '../../basics/keyframes';
@import '../../basics/tmdscolors';
@import '../../basics/publictranscolors';

.tag {
  display: inline-flex;
  align-items: center;

  border-radius: 4px;

  color: var(--color);
  border-color: var(--color);
  background-color: $wb-white;

  @include tmobi-font-normal(var(--font-size));

  .text {
    padding: 0px 3px;
    border-radius: 4px;
    border: 1px solid;
    white-space: nowrap;

    overflow: hidden;
    text-overflow: ellipsis;
  }

  &.bold {
    .text {
      @include tmobi-font-bold(var(--font-size));
    }
  }

  &.filled {
    background: var(--background, $wb-white);
    border-color: var(--color);

    .text {
      padding: 4px 4px;
      border: none;
      line-height: var(--font-size);
      letter-spacing: -0.38px;
    }
  }

  &.capsule {
    border-radius: 12px;

    &.filled {
      .text {
        padding: 3px 10px;
        border: none;
      }
    }

    .text {
      @include tmobi-font-bold(var(--font-size));
      border-radius: 12px;
      border: 2px solid;
      padding: 1px 8px;
    }
  }
}
