@charset "UTF-8";

@import '../../@tmds/tmdscolor';
@import '../../@tmds/tmdsfonts';
@import '../../@tmds/variables/color';

@import '../../basics/variables';
@import '../../basics/mixins';
@import '../../basics/functions';
@import '../../basics/extends';
@import '../../basics/keyframes';
@import '../../basics/tmdscolors';
@import '../../basics/publictranscolors';

@include keyframeBlink();

.quick_link {
  padding: 12px 0 12px 16px;

  .item {
    @include gradient;
    @include infiniteBlink;
    display: inline-block;

    width: 90px;
    height: 32px;
    border-radius: 16px;
    margin-right: 4px;
    vertical-align: top;
  }
}

.list {
  @include scroll-wrap(x);
  display: flex;
  gap: 0 4px;
  padding: 12px 16px;

  .item {
    @include font-size-14();
    color: $gray-700;
    position: relative;
    height: 32px;

    .link {
      @include touch-guide(
        $borderRadius: 40px,
        $opacity: 0.06,
        $top: 1px,
        $left: 1px,
        $right: 1px,
        $bottom: 1px
      );
      height: 100%;
      display: inline-flex;
      align-items: center;
      border-radius: 17px;
      border: 1px solid $gray-50;
      background: $gray-50;
      padding: 0 12px;
      @include tmobi-font-medium(14px, 3.2px);
      color: $gray-900;
    }
  }
}
