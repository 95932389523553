@charset "UTF-8";

@import '../@tmds/tmdscolor';
@import '../@tmds/tmdsfonts';
@import '../@tmds/variables/color';

@import '../basics/variables';
@import '../basics/mixins';
@import '../basics/functions';
@import '../basics/extends';
@import '../basics/keyframes';
@import '../basics/tmdscolors';
@import '../basics/publictranscolors';

.banner_wrap {
  @include common-banner-layout;
  height: 80px;

  iframe {
    width: 100%;
    // 아래 contatin 사용시 네이버 광고 그만보기 > 사유 리스트에서 렌더러 버그 발생
    // contain 없이 동작하는거 확인하고 제거
    // object-fit: contain;
  }
}
