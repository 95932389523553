// generated from scripts/generate_tmds_color.js
[data-tmds-color='blackAlpha100'] {
  color: $black-alpha-100;
}
[data-tmds-color='blackAlpha200'] {
  color: $black-alpha-200;
}
[data-tmds-color='blackAlpha300'] {
  color: $black-alpha-300;
}
[data-tmds-color='blackAlpha400'] {
  color: $black-alpha-400;
}
[data-tmds-color='blackAlpha50'] {
  color: $black-alpha-50;
}
[data-tmds-color='blackAlpha500'] {
  color: $black-alpha-500;
}
[data-tmds-color='blackAlpha600'] {
  color: $black-alpha-600;
}
[data-tmds-color='blackAlpha700'] {
  color: $black-alpha-700;
}
[data-tmds-color='blackAlpha800'] {
  color: $black-alpha-800;
}
[data-tmds-color='blackAlpha900'] {
  color: $black-alpha-900;
}
[data-tmds-color='gray100'] {
  color: $gray-100;
}
[data-tmds-color='gray200'] {
  color: $gray-200;
}
[data-tmds-color='gray300'] {
  color: $gray-300;
}
[data-tmds-color='gray400'] {
  color: $gray-400;
}
[data-tmds-color='gray50'] {
  color: $gray-50;
}
[data-tmds-color='gray500'] {
  color: $gray-500;
}
[data-tmds-color='gray600'] {
  color: $gray-600;
}
[data-tmds-color='gray700'] {
  color: $gray-700;
}
[data-tmds-color='gray800'] {
  color: $gray-800;
}
[data-tmds-color='gray900'] {
  color: $gray-900;
}
[data-tmds-color='green100'] {
  color: $green-100;
}
[data-tmds-color='green200'] {
  color: $green-200;
}
[data-tmds-color='green300'] {
  color: $green-300;
}
[data-tmds-color='green400'] {
  color: $green-400;
}
[data-tmds-color='green50'] {
  color: $green-50;
}
[data-tmds-color='green500'] {
  color: $green-500;
}
[data-tmds-color='green600'] {
  color: $green-600;
}
[data-tmds-color='green700'] {
  color: $green-700;
}
[data-tmds-color='green800'] {
  color: $green-800;
}
[data-tmds-color='green900'] {
  color: $green-900;
}
[data-tmds-color='orange100'] {
  color: $orange-100;
}
[data-tmds-color='orange200'] {
  color: $orange-200;
}
[data-tmds-color='orange300'] {
  color: $orange-300;
}
[data-tmds-color='orange400'] {
  color: $orange-400;
}
[data-tmds-color='orange50'] {
  color: $orange-50;
}
[data-tmds-color='orange500'] {
  color: $orange-500;
}
[data-tmds-color='orange600'] {
  color: $orange-600;
}
[data-tmds-color='orange700'] {
  color: $orange-700;
}
[data-tmds-color='orange800'] {
  color: $orange-800;
}
[data-tmds-color='orange900'] {
  color: $orange-900;
}
[data-tmds-color='red100'] {
  color: $red-100;
}
[data-tmds-color='red200'] {
  color: $red-200;
}
[data-tmds-color='red300'] {
  color: $red-300;
}
[data-tmds-color='red400'] {
  color: $red-400;
}
[data-tmds-color='red50'] {
  color: $red-50;
}
[data-tmds-color='red500'] {
  color: $red-500;
}
[data-tmds-color='red600'] {
  color: $red-600;
}
[data-tmds-color='red700'] {
  color: $red-700;
}
[data-tmds-color='red800'] {
  color: $red-800;
}
[data-tmds-color='red900'] {
  color: $red-900;
}
[data-tmds-color='sky100'] {
  color: $sky-100;
}
[data-tmds-color='sky200'] {
  color: $sky-200;
}
[data-tmds-color='sky300'] {
  color: $sky-300;
}
[data-tmds-color='sky400'] {
  color: $sky-400;
}
[data-tmds-color='sky50'] {
  color: $sky-50;
}
[data-tmds-color='sky500'] {
  color: $sky-500;
}
[data-tmds-color='sky600'] {
  color: $sky-600;
}
[data-tmds-color='sky700'] {
  color: $sky-700;
}
[data-tmds-color='sky800'] {
  color: $sky-800;
}
[data-tmds-color='sky900'] {
  color: $sky-900;
}
[data-tmds-color='tmobiBlue100'] {
  color: $tmobi-blue-100;
}
[data-tmds-color='tmobiBlue200'] {
  color: $tmobi-blue-200;
}
[data-tmds-color='tmobiBlue300'] {
  color: $tmobi-blue-300;
}
[data-tmds-color='tmobiBlue400'] {
  color: $tmobi-blue-400;
}
[data-tmds-color='tmobiBlue50'] {
  color: $tmobi-blue-50;
}
[data-tmds-color='tmobiBlue500'] {
  color: $tmobi-blue-500;
}
[data-tmds-color='tmobiBlue600'] {
  color: $tmobi-blue-600;
}
[data-tmds-color='tmobiBlue700'] {
  color: $tmobi-blue-700;
}
[data-tmds-color='tmobiBlue800'] {
  color: $tmobi-blue-800;
}
[data-tmds-color='tmobiBlue900'] {
  color: $tmobi-blue-900;
}
[data-tmds-color='tmobiPink100'] {
  color: $tmobi-pink-100;
}
[data-tmds-color='tmobiPink200'] {
  color: $tmobi-pink-200;
}
[data-tmds-color='tmobiPink300'] {
  color: $tmobi-pink-300;
}
[data-tmds-color='tmobiPink400'] {
  color: $tmobi-pink-400;
}
[data-tmds-color='tmobiPink50'] {
  color: $tmobi-pink-50;
}
[data-tmds-color='tmobiPink500'] {
  color: $tmobi-pink-500;
}
[data-tmds-color='tmobiPink600'] {
  color: $tmobi-pink-600;
}
[data-tmds-color='tmobiPink700'] {
  color: $tmobi-pink-700;
}
[data-tmds-color='tmobiPink800'] {
  color: $tmobi-pink-800;
}
[data-tmds-color='tmobiPink900'] {
  color: $tmobi-pink-900;
}
[data-tmds-color='tmobiPurple100'] {
  color: $tmobi-purple-100;
}
[data-tmds-color='tmobiPurple200'] {
  color: $tmobi-purple-200;
}
[data-tmds-color='tmobiPurple300'] {
  color: $tmobi-purple-300;
}
[data-tmds-color='tmobiPurple400'] {
  color: $tmobi-purple-400;
}
[data-tmds-color='tmobiPurple50'] {
  color: $tmobi-purple-50;
}
[data-tmds-color='tmobiPurple500'] {
  color: $tmobi-purple-500;
}
[data-tmds-color='tmobiPurple600'] {
  color: $tmobi-purple-600;
}
[data-tmds-color='tmobiPurple700'] {
  color: $tmobi-purple-700;
}
[data-tmds-color='tmobiPurple800'] {
  color: $tmobi-purple-800;
}
[data-tmds-color='tmobiPurple900'] {
  color: $tmobi-purple-900;
}
[data-tmds-color='tmobiTeal100'] {
  color: $tmobi-teal-100;
}
[data-tmds-color='tmobiTeal200'] {
  color: $tmobi-teal-200;
}
[data-tmds-color='tmobiTeal300'] {
  color: $tmobi-teal-300;
}
[data-tmds-color='tmobiTeal400'] {
  color: $tmobi-teal-400;
}
[data-tmds-color='tmobiTeal50'] {
  color: $tmobi-teal-50;
}
[data-tmds-color='tmobiTeal500'] {
  color: $tmobi-teal-500;
}
[data-tmds-color='tmobiTeal600'] {
  color: $tmobi-teal-600;
}
[data-tmds-color='tmobiTeal700'] {
  color: $tmobi-teal-700;
}
[data-tmds-color='tmobiTeal800'] {
  color: $tmobi-teal-800;
}
[data-tmds-color='tmobiTeal900'] {
  color: $tmobi-teal-900;
}
[data-tmds-color='wbBlack'] {
  color: $wb-black;
}
[data-tmds-color='wbWhite'] {
  color: $wb-white;
}
[data-tmds-color='whiteAlpha100'] {
  color: $white-alpha-100;
}
[data-tmds-color='whiteAlpha200'] {
  color: $white-alpha-200;
}
[data-tmds-color='whiteAlpha300'] {
  color: $white-alpha-300;
}
[data-tmds-color='whiteAlpha400'] {
  color: $white-alpha-400;
}
[data-tmds-color='whiteAlpha50'] {
  color: $white-alpha-50;
}
[data-tmds-color='whiteAlpha500'] {
  color: $white-alpha-500;
}
[data-tmds-color='whiteAlpha600'] {
  color: $white-alpha-600;
}
[data-tmds-color='whiteAlpha700'] {
  color: $white-alpha-700;
}
[data-tmds-color='whiteAlpha800'] {
  color: $white-alpha-800;
}
[data-tmds-color='whiteAlpha900'] {
  color: $white-alpha-900;
}
[data-tmds-color='yellow100'] {
  color: $yellow-100;
}
[data-tmds-color='yellow200'] {
  color: $yellow-200;
}
[data-tmds-color='yellow300'] {
  color: $yellow-300;
}
[data-tmds-color='yellow400'] {
  color: $yellow-400;
}
[data-tmds-color='yellow50'] {
  color: $yellow-50;
}
[data-tmds-color='yellow500'] {
  color: $yellow-500;
}
[data-tmds-color='yellow600'] {
  color: $yellow-600;
}
[data-tmds-color='yellow700'] {
  color: $yellow-700;
}
[data-tmds-color='yellow800'] {
  color: $yellow-800;
}
[data-tmds-color='yellow900'] {
  color: $yellow-900;
}
[data-tmds-color='backgroundDangerPrimary'] {
  color: $background-danger-primary;
}
[data-tmds-color='backgroundDangerSecondary'] {
  color: $background-danger-secondary;
}
[data-tmds-color='tmobiBrandPrimary'] {
  color: $tmobi-brand-primary;
}
[data-tmds-color='tmobiBrandSecondary'] {
  color: $tmobi-brand-secondary;
}
[data-tmds-color='tmobiBrandBenefit'] {
  color: $tmobi-brand-benefit;
}
[data-tmds-color='tmobiBrandEv'] {
  color: $tmobi-brand-ev;
}
[data-tmds-color='tmobiBrandOil'] {
  color: $tmobi-brand-oil;
}
[data-tmds-color='backgroundButtonDisabled'] {
  color: $background-button-disabled;
}
[data-tmds-color='backgroundButtonNegative'] {
  color: $background-button-negative;
}
[data-tmds-color='backgroundButtonPrimary'] {
  color: $background-button-primary;
}
[data-tmds-color='backgroundButtonSecondary'] {
  color: $background-button-secondary;
}
[data-tmds-color='backgroundDangerBolder'] {
  color: $background-danger-bolder;
}
[data-tmds-color='backgroundInputDefault'] {
  color: $background-input-default;
}
[data-tmds-color='backgroundInputDisabled'] {
  color: $background-input-disabled;
}
[data-tmds-color='backgroundSuccessBolder'] {
  color: $background-success-bolder;
}
[data-tmds-color='backgroundSuccessDisabled'] {
  color: $background-success-disabled;
}
[data-tmds-color='backgroundSuccessPrimary'] {
  color: $background-success-primary;
}
[data-tmds-color='backgroundSuccessSecondary'] {
  color: $background-success-secondary;
}
[data-tmds-color='backgroundSurfaceAds'] {
  color: $background-surface-ads;
}
[data-tmds-color='backgroundSurfaceFloatingOnMap'] {
  color: $background-surface-floating-on-map;
}
[data-tmds-color='backgroundSurfacePopOverlay'] {
  color: $background-surface-pop-overlay;
}
[data-tmds-color='backgroundSurfacePrimaryBase'] {
  color: $background-surface-primary-base;
}
[data-tmds-color='backgroundSurfacePrimaryElevated'] {
  color: $background-surface-primary-elevated;
}
[data-tmds-color='backgroundSurfaceSecondaryBase'] {
  color: $background-surface-secondary-base;
}
[data-tmds-color='backgroundSurfaceTouchTarget'] {
  color: $background-surface-touch-target;
}
[data-tmds-color='backgroundWarningBolder'] {
  color: $background-warning-bolder;
}
[data-tmds-color='backgroundWarningDisabled'] {
  color: $background-warning-disabled;
}
[data-tmds-color='backgroundWarningPrimary'] {
  color: $background-warning-primary;
}
[data-tmds-color='backgroundWarningSecondary'] {
  color: $background-warning-secondary;
}
[data-tmds-color='borderButtonStrokeAccent'] {
  color: $border-button-stroke-accent;
}
[data-tmds-color='borderButtonStrokeDefault'] {
  color: $border-button-stroke-default;
}
[data-tmds-color='borderButtonStrokeDisabled'] {
  color: $border-button-stroke-disabled;
}
[data-tmds-color='borderInputStrokeAccent'] {
  color: $border-input-stroke-accent;
}
[data-tmds-color='borderInputStrokeDanger'] {
  color: $border-input-stroke-danger;
}
[data-tmds-color='borderInputStrokeDefault'] {
  color: $border-input-stroke-default;
}
[data-tmds-color='borderLineWide'] {
  color: $border-line-wide;
}
[data-tmds-color='borderLineWideElevated'] {
  color: $border-line-wide-elevated;
}
[data-tmds-color='borderLineDefault'] {
  color: $border-line-default;
}
[data-tmds-color='borderLineDefaultElevated'] {
  color: $border-line-default-elevated;
}
[data-tmds-color='iconAccent'] {
  color: $icon-accent;
}
[data-tmds-color='iconInverse'] {
  color: $icon-inverse;
}
[data-tmds-color='iconOnCta'] {
  color: $icon-on-cta;
}
[data-tmds-color='iconPrimary'] {
  color: $icon-primary;
}
[data-tmds-color='iconQuaternary'] {
  color: $icon-quaternary;
}
[data-tmds-color='iconSecondary'] {
  color: $icon-secondary;
}
[data-tmds-color='iconTertiary'] {
  color: $icon-tertiary;
}
[data-tmds-color='textBasicAccent'] {
  color: $text-basic-accent;
}
[data-tmds-color='textBasicCaption'] {
  color: $text-basic-caption;
}
[data-tmds-color='textBasicDefault'] {
  color: $text-basic-default;
}
[data-tmds-color='textBasicDescription'] {
  color: $text-basic-description;
}
[data-tmds-color='textBasicDisabled'] {
  color: $text-basic-disabled;
}
[data-tmds-color='textBasicEv'] {
  color: $text-basic-ev;
}
[data-tmds-color='textBasicInverse'] {
  color: $text-basic-inverse;
}
[data-tmds-color='textBasicLink'] {
  color: $text-basic-link;
}
[data-tmds-color='textBasicOil'] {
  color: $text-basic-oil;
}
[data-tmds-color='textBasicPlaceHolder'] {
  color: $text-basic-place-holder;
}
[data-tmds-color='textButtonAccent'] {
  color: $text-button-accent;
}
[data-tmds-color='textButtonDisabled'] {
  color: $text-button-disabled;
}
[data-tmds-color='textButtonDisabledStroke'] {
  color: $text-button-disabled-stroke;
}
[data-tmds-color='textButtonInverse'] {
  color: $text-button-inverse;
}
[data-tmds-color='textButtonPrimary'] {
  color: $text-button-primary;
}
[data-tmds-color='textButtonSecondary'] {
  color: $text-button-secondary;
}
[data-tmds-color='textDangerBolder'] {
  color: $text-danger-bolder;
}
[data-tmds-color='textDangerDefault'] {
  color: $text-danger-default;
}
[data-tmds-color='textSuccessBolder'] {
  color: $text-success-bolder;
}
[data-tmds-color='textSuccessDefault'] {
  color: $text-success-default;
}
[data-tmds-color='textWarningBolder'] {
  color: $text-warning-bolder;
}
[data-tmds-color='textWarningDefault'] {
  color: $text-warning-default;
}
[data-tmds-color='tmobiTrafficDelay'] {
  color: $tmobi-traffic-delay;
}
[data-tmds-color='tmobiTrafficCongestion'] {
  color: $tmobi-traffic-congestion;
}
[data-tmds-color='tmobiTrafficSmooth'] {
  color: $tmobi-traffic-smooth;
}
[data-tmds-bg-color='blackAlpha100'] {
  background-color: $black-alpha-100;
}
[data-tmds-bg-color='blackAlpha200'] {
  background-color: $black-alpha-200;
}
[data-tmds-bg-color='blackAlpha300'] {
  background-color: $black-alpha-300;
}
[data-tmds-bg-color='blackAlpha400'] {
  background-color: $black-alpha-400;
}
[data-tmds-bg-color='blackAlpha50'] {
  background-color: $black-alpha-50;
}
[data-tmds-bg-color='blackAlpha500'] {
  background-color: $black-alpha-500;
}
[data-tmds-bg-color='blackAlpha600'] {
  background-color: $black-alpha-600;
}
[data-tmds-bg-color='blackAlpha700'] {
  background-color: $black-alpha-700;
}
[data-tmds-bg-color='blackAlpha800'] {
  background-color: $black-alpha-800;
}
[data-tmds-bg-color='blackAlpha900'] {
  background-color: $black-alpha-900;
}
[data-tmds-bg-color='gray100'] {
  background-color: $gray-100;
}
[data-tmds-bg-color='gray200'] {
  background-color: $gray-200;
}
[data-tmds-bg-color='gray300'] {
  background-color: $gray-300;
}
[data-tmds-bg-color='gray400'] {
  background-color: $gray-400;
}
[data-tmds-bg-color='gray50'] {
  background-color: $gray-50;
}
[data-tmds-bg-color='gray500'] {
  background-color: $gray-500;
}
[data-tmds-bg-color='gray600'] {
  background-color: $gray-600;
}
[data-tmds-bg-color='gray700'] {
  background-color: $gray-700;
}
[data-tmds-bg-color='gray800'] {
  background-color: $gray-800;
}
[data-tmds-bg-color='gray900'] {
  background-color: $gray-900;
}
[data-tmds-bg-color='green100'] {
  background-color: $green-100;
}
[data-tmds-bg-color='green200'] {
  background-color: $green-200;
}
[data-tmds-bg-color='green300'] {
  background-color: $green-300;
}
[data-tmds-bg-color='green400'] {
  background-color: $green-400;
}
[data-tmds-bg-color='green50'] {
  background-color: $green-50;
}
[data-tmds-bg-color='green500'] {
  background-color: $green-500;
}
[data-tmds-bg-color='green600'] {
  background-color: $green-600;
}
[data-tmds-bg-color='green700'] {
  background-color: $green-700;
}
[data-tmds-bg-color='green800'] {
  background-color: $green-800;
}
[data-tmds-bg-color='green900'] {
  background-color: $green-900;
}
[data-tmds-bg-color='orange100'] {
  background-color: $orange-100;
}
[data-tmds-bg-color='orange200'] {
  background-color: $orange-200;
}
[data-tmds-bg-color='orange300'] {
  background-color: $orange-300;
}
[data-tmds-bg-color='orange400'] {
  background-color: $orange-400;
}
[data-tmds-bg-color='orange50'] {
  background-color: $orange-50;
}
[data-tmds-bg-color='orange500'] {
  background-color: $orange-500;
}
[data-tmds-bg-color='orange600'] {
  background-color: $orange-600;
}
[data-tmds-bg-color='orange700'] {
  background-color: $orange-700;
}
[data-tmds-bg-color='orange800'] {
  background-color: $orange-800;
}
[data-tmds-bg-color='orange900'] {
  background-color: $orange-900;
}
[data-tmds-bg-color='red100'] {
  background-color: $red-100;
}
[data-tmds-bg-color='red200'] {
  background-color: $red-200;
}
[data-tmds-bg-color='red300'] {
  background-color: $red-300;
}
[data-tmds-bg-color='red400'] {
  background-color: $red-400;
}
[data-tmds-bg-color='red50'] {
  background-color: $red-50;
}
[data-tmds-bg-color='red500'] {
  background-color: $red-500;
}
[data-tmds-bg-color='red600'] {
  background-color: $red-600;
}
[data-tmds-bg-color='red700'] {
  background-color: $red-700;
}
[data-tmds-bg-color='red800'] {
  background-color: $red-800;
}
[data-tmds-bg-color='red900'] {
  background-color: $red-900;
}
[data-tmds-bg-color='sky100'] {
  background-color: $sky-100;
}
[data-tmds-bg-color='sky200'] {
  background-color: $sky-200;
}
[data-tmds-bg-color='sky300'] {
  background-color: $sky-300;
}
[data-tmds-bg-color='sky400'] {
  background-color: $sky-400;
}
[data-tmds-bg-color='sky50'] {
  background-color: $sky-50;
}
[data-tmds-bg-color='sky500'] {
  background-color: $sky-500;
}
[data-tmds-bg-color='sky600'] {
  background-color: $sky-600;
}
[data-tmds-bg-color='sky700'] {
  background-color: $sky-700;
}
[data-tmds-bg-color='sky800'] {
  background-color: $sky-800;
}
[data-tmds-bg-color='sky900'] {
  background-color: $sky-900;
}
[data-tmds-bg-color='tmobiBlue100'] {
  background-color: $tmobi-blue-100;
}
[data-tmds-bg-color='tmobiBlue200'] {
  background-color: $tmobi-blue-200;
}
[data-tmds-bg-color='tmobiBlue300'] {
  background-color: $tmobi-blue-300;
}
[data-tmds-bg-color='tmobiBlue400'] {
  background-color: $tmobi-blue-400;
}
[data-tmds-bg-color='tmobiBlue50'] {
  background-color: $tmobi-blue-50;
}
[data-tmds-bg-color='tmobiBlue500'] {
  background-color: $tmobi-blue-500;
}
[data-tmds-bg-color='tmobiBlue600'] {
  background-color: $tmobi-blue-600;
}
[data-tmds-bg-color='tmobiBlue700'] {
  background-color: $tmobi-blue-700;
}
[data-tmds-bg-color='tmobiBlue800'] {
  background-color: $tmobi-blue-800;
}
[data-tmds-bg-color='tmobiBlue900'] {
  background-color: $tmobi-blue-900;
}
[data-tmds-bg-color='tmobiPink100'] {
  background-color: $tmobi-pink-100;
}
[data-tmds-bg-color='tmobiPink200'] {
  background-color: $tmobi-pink-200;
}
[data-tmds-bg-color='tmobiPink300'] {
  background-color: $tmobi-pink-300;
}
[data-tmds-bg-color='tmobiPink400'] {
  background-color: $tmobi-pink-400;
}
[data-tmds-bg-color='tmobiPink50'] {
  background-color: $tmobi-pink-50;
}
[data-tmds-bg-color='tmobiPink500'] {
  background-color: $tmobi-pink-500;
}
[data-tmds-bg-color='tmobiPink600'] {
  background-color: $tmobi-pink-600;
}
[data-tmds-bg-color='tmobiPink700'] {
  background-color: $tmobi-pink-700;
}
[data-tmds-bg-color='tmobiPink800'] {
  background-color: $tmobi-pink-800;
}
[data-tmds-bg-color='tmobiPink900'] {
  background-color: $tmobi-pink-900;
}
[data-tmds-bg-color='tmobiPurple100'] {
  background-color: $tmobi-purple-100;
}
[data-tmds-bg-color='tmobiPurple200'] {
  background-color: $tmobi-purple-200;
}
[data-tmds-bg-color='tmobiPurple300'] {
  background-color: $tmobi-purple-300;
}
[data-tmds-bg-color='tmobiPurple400'] {
  background-color: $tmobi-purple-400;
}
[data-tmds-bg-color='tmobiPurple50'] {
  background-color: $tmobi-purple-50;
}
[data-tmds-bg-color='tmobiPurple500'] {
  background-color: $tmobi-purple-500;
}
[data-tmds-bg-color='tmobiPurple600'] {
  background-color: $tmobi-purple-600;
}
[data-tmds-bg-color='tmobiPurple700'] {
  background-color: $tmobi-purple-700;
}
[data-tmds-bg-color='tmobiPurple800'] {
  background-color: $tmobi-purple-800;
}
[data-tmds-bg-color='tmobiPurple900'] {
  background-color: $tmobi-purple-900;
}
[data-tmds-bg-color='tmobiTeal100'] {
  background-color: $tmobi-teal-100;
}
[data-tmds-bg-color='tmobiTeal200'] {
  background-color: $tmobi-teal-200;
}
[data-tmds-bg-color='tmobiTeal300'] {
  background-color: $tmobi-teal-300;
}
[data-tmds-bg-color='tmobiTeal400'] {
  background-color: $tmobi-teal-400;
}
[data-tmds-bg-color='tmobiTeal50'] {
  background-color: $tmobi-teal-50;
}
[data-tmds-bg-color='tmobiTeal500'] {
  background-color: $tmobi-teal-500;
}
[data-tmds-bg-color='tmobiTeal600'] {
  background-color: $tmobi-teal-600;
}
[data-tmds-bg-color='tmobiTeal700'] {
  background-color: $tmobi-teal-700;
}
[data-tmds-bg-color='tmobiTeal800'] {
  background-color: $tmobi-teal-800;
}
[data-tmds-bg-color='tmobiTeal900'] {
  background-color: $tmobi-teal-900;
}
[data-tmds-bg-color='wbBlack'] {
  background-color: $wb-black;
}
[data-tmds-bg-color='wbWhite'] {
  background-color: $wb-white;
}
[data-tmds-bg-color='whiteAlpha100'] {
  background-color: $white-alpha-100;
}
[data-tmds-bg-color='whiteAlpha200'] {
  background-color: $white-alpha-200;
}
[data-tmds-bg-color='whiteAlpha300'] {
  background-color: $white-alpha-300;
}
[data-tmds-bg-color='whiteAlpha400'] {
  background-color: $white-alpha-400;
}
[data-tmds-bg-color='whiteAlpha50'] {
  background-color: $white-alpha-50;
}
[data-tmds-bg-color='whiteAlpha500'] {
  background-color: $white-alpha-500;
}
[data-tmds-bg-color='whiteAlpha600'] {
  background-color: $white-alpha-600;
}
[data-tmds-bg-color='whiteAlpha700'] {
  background-color: $white-alpha-700;
}
[data-tmds-bg-color='whiteAlpha800'] {
  background-color: $white-alpha-800;
}
[data-tmds-bg-color='whiteAlpha900'] {
  background-color: $white-alpha-900;
}
[data-tmds-bg-color='yellow100'] {
  background-color: $yellow-100;
}
[data-tmds-bg-color='yellow200'] {
  background-color: $yellow-200;
}
[data-tmds-bg-color='yellow300'] {
  background-color: $yellow-300;
}
[data-tmds-bg-color='yellow400'] {
  background-color: $yellow-400;
}
[data-tmds-bg-color='yellow50'] {
  background-color: $yellow-50;
}
[data-tmds-bg-color='yellow500'] {
  background-color: $yellow-500;
}
[data-tmds-bg-color='yellow600'] {
  background-color: $yellow-600;
}
[data-tmds-bg-color='yellow700'] {
  background-color: $yellow-700;
}
[data-tmds-bg-color='yellow800'] {
  background-color: $yellow-800;
}
[data-tmds-bg-color='yellow900'] {
  background-color: $yellow-900;
}
[data-tmds-bg-color='backgroundDangerPrimary'] {
  background-color: $background-danger-primary;
}
[data-tmds-bg-color='backgroundDangerSecondary'] {
  background-color: $background-danger-secondary;
}
[data-tmds-bg-color='tmobiBrandPrimary'] {
  background-color: $tmobi-brand-primary;
}
[data-tmds-bg-color='tmobiBrandSecondary'] {
  background-color: $tmobi-brand-secondary;
}
[data-tmds-bg-color='tmobiBrandBenefit'] {
  background-color: $tmobi-brand-benefit;
}
[data-tmds-bg-color='tmobiBrandEv'] {
  background-color: $tmobi-brand-ev;
}
[data-tmds-bg-color='tmobiBrandOil'] {
  background-color: $tmobi-brand-oil;
}
[data-tmds-bg-color='backgroundButtonDisabled'] {
  background-color: $background-button-disabled;
}
[data-tmds-bg-color='backgroundButtonNegative'] {
  background-color: $background-button-negative;
}
[data-tmds-bg-color='backgroundButtonPrimary'] {
  background-color: $background-button-primary;
}
[data-tmds-bg-color='backgroundButtonSecondary'] {
  background-color: $background-button-secondary;
}
[data-tmds-bg-color='backgroundDangerBolder'] {
  background-color: $background-danger-bolder;
}
[data-tmds-bg-color='backgroundInputDefault'] {
  background-color: $background-input-default;
}
[data-tmds-bg-color='backgroundInputDisabled'] {
  background-color: $background-input-disabled;
}
[data-tmds-bg-color='backgroundSuccessBolder'] {
  background-color: $background-success-bolder;
}
[data-tmds-bg-color='backgroundSuccessDisabled'] {
  background-color: $background-success-disabled;
}
[data-tmds-bg-color='backgroundSuccessPrimary'] {
  background-color: $background-success-primary;
}
[data-tmds-bg-color='backgroundSuccessSecondary'] {
  background-color: $background-success-secondary;
}
[data-tmds-bg-color='backgroundSurfaceAds'] {
  background-color: $background-surface-ads;
}
[data-tmds-bg-color='backgroundSurfaceFloatingOnMap'] {
  background-color: $background-surface-floating-on-map;
}
[data-tmds-bg-color='backgroundSurfacePopOverlay'] {
  background-color: $background-surface-pop-overlay;
}
[data-tmds-bg-color='backgroundSurfacePrimaryBase'] {
  background-color: $background-surface-primary-base;
}
[data-tmds-bg-color='backgroundSurfacePrimaryElevated'] {
  background-color: $background-surface-primary-elevated;
}
[data-tmds-bg-color='backgroundSurfaceSecondaryBase'] {
  background-color: $background-surface-secondary-base;
}
[data-tmds-bg-color='backgroundSurfaceTouchTarget'] {
  background-color: $background-surface-touch-target;
}
[data-tmds-bg-color='backgroundWarningBolder'] {
  background-color: $background-warning-bolder;
}
[data-tmds-bg-color='backgroundWarningDisabled'] {
  background-color: $background-warning-disabled;
}
[data-tmds-bg-color='backgroundWarningPrimary'] {
  background-color: $background-warning-primary;
}
[data-tmds-bg-color='backgroundWarningSecondary'] {
  background-color: $background-warning-secondary;
}
[data-tmds-bg-color='borderButtonStrokeAccent'] {
  background-color: $border-button-stroke-accent;
}
[data-tmds-bg-color='borderButtonStrokeDefault'] {
  background-color: $border-button-stroke-default;
}
[data-tmds-bg-color='borderButtonStrokeDisabled'] {
  background-color: $border-button-stroke-disabled;
}
[data-tmds-bg-color='borderInputStrokeAccent'] {
  background-color: $border-input-stroke-accent;
}
[data-tmds-bg-color='borderInputStrokeDanger'] {
  background-color: $border-input-stroke-danger;
}
[data-tmds-bg-color='borderInputStrokeDefault'] {
  background-color: $border-input-stroke-default;
}
[data-tmds-bg-color='borderLineWide'] {
  background-color: $border-line-wide;
}
[data-tmds-bg-color='borderLineWideElevated'] {
  background-color: $border-line-wide-elevated;
}
[data-tmds-bg-color='borderLineDefault'] {
  background-color: $border-line-default;
}
[data-tmds-bg-color='borderLineDefaultElevated'] {
  background-color: $border-line-default-elevated;
}
[data-tmds-bg-color='iconAccent'] {
  background-color: $icon-accent;
}
[data-tmds-bg-color='iconInverse'] {
  background-color: $icon-inverse;
}
[data-tmds-bg-color='iconOnCta'] {
  background-color: $icon-on-cta;
}
[data-tmds-bg-color='iconPrimary'] {
  background-color: $icon-primary;
}
[data-tmds-bg-color='iconQuaternary'] {
  background-color: $icon-quaternary;
}
[data-tmds-bg-color='iconSecondary'] {
  background-color: $icon-secondary;
}
[data-tmds-bg-color='iconTertiary'] {
  background-color: $icon-tertiary;
}
[data-tmds-bg-color='textBasicAccent'] {
  background-color: $text-basic-accent;
}
[data-tmds-bg-color='textBasicCaption'] {
  background-color: $text-basic-caption;
}
[data-tmds-bg-color='textBasicDefault'] {
  background-color: $text-basic-default;
}
[data-tmds-bg-color='textBasicDescription'] {
  background-color: $text-basic-description;
}
[data-tmds-bg-color='textBasicDisabled'] {
  background-color: $text-basic-disabled;
}
[data-tmds-bg-color='textBasicEv'] {
  background-color: $text-basic-ev;
}
[data-tmds-bg-color='textBasicInverse'] {
  background-color: $text-basic-inverse;
}
[data-tmds-bg-color='textBasicLink'] {
  background-color: $text-basic-link;
}
[data-tmds-bg-color='textBasicOil'] {
  background-color: $text-basic-oil;
}
[data-tmds-bg-color='textBasicPlaceHolder'] {
  background-color: $text-basic-place-holder;
}
[data-tmds-bg-color='textButtonAccent'] {
  background-color: $text-button-accent;
}
[data-tmds-bg-color='textButtonDisabled'] {
  background-color: $text-button-disabled;
}
[data-tmds-bg-color='textButtonDisabledStroke'] {
  background-color: $text-button-disabled-stroke;
}
[data-tmds-bg-color='textButtonInverse'] {
  background-color: $text-button-inverse;
}
[data-tmds-bg-color='textButtonPrimary'] {
  background-color: $text-button-primary;
}
[data-tmds-bg-color='textButtonSecondary'] {
  background-color: $text-button-secondary;
}
[data-tmds-bg-color='textDangerBolder'] {
  background-color: $text-danger-bolder;
}
[data-tmds-bg-color='textDangerDefault'] {
  background-color: $text-danger-default;
}
[data-tmds-bg-color='textSuccessBolder'] {
  background-color: $text-success-bolder;
}
[data-tmds-bg-color='textSuccessDefault'] {
  background-color: $text-success-default;
}
[data-tmds-bg-color='textWarningBolder'] {
  background-color: $text-warning-bolder;
}
[data-tmds-bg-color='textWarningDefault'] {
  background-color: $text-warning-default;
}
[data-tmds-bg-color='tmobiTrafficDelay'] {
  background-color: $tmobi-traffic-delay;
}
[data-tmds-bg-color='tmobiTrafficCongestion'] {
  background-color: $tmobi-traffic-congestion;
}
[data-tmds-bg-color='tmobiTrafficSmooth'] {
  background-color: $tmobi-traffic-smooth;
}
[data-tmds-border-color='blackAlpha100'] {
  border-color: $black-alpha-100;
}
[data-tmds-border-color='blackAlpha200'] {
  border-color: $black-alpha-200;
}
[data-tmds-border-color='blackAlpha300'] {
  border-color: $black-alpha-300;
}
[data-tmds-border-color='blackAlpha400'] {
  border-color: $black-alpha-400;
}
[data-tmds-border-color='blackAlpha50'] {
  border-color: $black-alpha-50;
}
[data-tmds-border-color='blackAlpha500'] {
  border-color: $black-alpha-500;
}
[data-tmds-border-color='blackAlpha600'] {
  border-color: $black-alpha-600;
}
[data-tmds-border-color='blackAlpha700'] {
  border-color: $black-alpha-700;
}
[data-tmds-border-color='blackAlpha800'] {
  border-color: $black-alpha-800;
}
[data-tmds-border-color='blackAlpha900'] {
  border-color: $black-alpha-900;
}
[data-tmds-border-color='gray100'] {
  border-color: $gray-100;
}
[data-tmds-border-color='gray200'] {
  border-color: $gray-200;
}
[data-tmds-border-color='gray300'] {
  border-color: $gray-300;
}
[data-tmds-border-color='gray400'] {
  border-color: $gray-400;
}
[data-tmds-border-color='gray50'] {
  border-color: $gray-50;
}
[data-tmds-border-color='gray500'] {
  border-color: $gray-500;
}
[data-tmds-border-color='gray600'] {
  border-color: $gray-600;
}
[data-tmds-border-color='gray700'] {
  border-color: $gray-700;
}
[data-tmds-border-color='gray800'] {
  border-color: $gray-800;
}
[data-tmds-border-color='gray900'] {
  border-color: $gray-900;
}
[data-tmds-border-color='green100'] {
  border-color: $green-100;
}
[data-tmds-border-color='green200'] {
  border-color: $green-200;
}
[data-tmds-border-color='green300'] {
  border-color: $green-300;
}
[data-tmds-border-color='green400'] {
  border-color: $green-400;
}
[data-tmds-border-color='green50'] {
  border-color: $green-50;
}
[data-tmds-border-color='green500'] {
  border-color: $green-500;
}
[data-tmds-border-color='green600'] {
  border-color: $green-600;
}
[data-tmds-border-color='green700'] {
  border-color: $green-700;
}
[data-tmds-border-color='green800'] {
  border-color: $green-800;
}
[data-tmds-border-color='green900'] {
  border-color: $green-900;
}
[data-tmds-border-color='orange100'] {
  border-color: $orange-100;
}
[data-tmds-border-color='orange200'] {
  border-color: $orange-200;
}
[data-tmds-border-color='orange300'] {
  border-color: $orange-300;
}
[data-tmds-border-color='orange400'] {
  border-color: $orange-400;
}
[data-tmds-border-color='orange50'] {
  border-color: $orange-50;
}
[data-tmds-border-color='orange500'] {
  border-color: $orange-500;
}
[data-tmds-border-color='orange600'] {
  border-color: $orange-600;
}
[data-tmds-border-color='orange700'] {
  border-color: $orange-700;
}
[data-tmds-border-color='orange800'] {
  border-color: $orange-800;
}
[data-tmds-border-color='orange900'] {
  border-color: $orange-900;
}
[data-tmds-border-color='red100'] {
  border-color: $red-100;
}
[data-tmds-border-color='red200'] {
  border-color: $red-200;
}
[data-tmds-border-color='red300'] {
  border-color: $red-300;
}
[data-tmds-border-color='red400'] {
  border-color: $red-400;
}
[data-tmds-border-color='red50'] {
  border-color: $red-50;
}
[data-tmds-border-color='red500'] {
  border-color: $red-500;
}
[data-tmds-border-color='red600'] {
  border-color: $red-600;
}
[data-tmds-border-color='red700'] {
  border-color: $red-700;
}
[data-tmds-border-color='red800'] {
  border-color: $red-800;
}
[data-tmds-border-color='red900'] {
  border-color: $red-900;
}
[data-tmds-border-color='sky100'] {
  border-color: $sky-100;
}
[data-tmds-border-color='sky200'] {
  border-color: $sky-200;
}
[data-tmds-border-color='sky300'] {
  border-color: $sky-300;
}
[data-tmds-border-color='sky400'] {
  border-color: $sky-400;
}
[data-tmds-border-color='sky50'] {
  border-color: $sky-50;
}
[data-tmds-border-color='sky500'] {
  border-color: $sky-500;
}
[data-tmds-border-color='sky600'] {
  border-color: $sky-600;
}
[data-tmds-border-color='sky700'] {
  border-color: $sky-700;
}
[data-tmds-border-color='sky800'] {
  border-color: $sky-800;
}
[data-tmds-border-color='sky900'] {
  border-color: $sky-900;
}
[data-tmds-border-color='tmobiBlue100'] {
  border-color: $tmobi-blue-100;
}
[data-tmds-border-color='tmobiBlue200'] {
  border-color: $tmobi-blue-200;
}
[data-tmds-border-color='tmobiBlue300'] {
  border-color: $tmobi-blue-300;
}
[data-tmds-border-color='tmobiBlue400'] {
  border-color: $tmobi-blue-400;
}
[data-tmds-border-color='tmobiBlue50'] {
  border-color: $tmobi-blue-50;
}
[data-tmds-border-color='tmobiBlue500'] {
  border-color: $tmobi-blue-500;
}
[data-tmds-border-color='tmobiBlue600'] {
  border-color: $tmobi-blue-600;
}
[data-tmds-border-color='tmobiBlue700'] {
  border-color: $tmobi-blue-700;
}
[data-tmds-border-color='tmobiBlue800'] {
  border-color: $tmobi-blue-800;
}
[data-tmds-border-color='tmobiBlue900'] {
  border-color: $tmobi-blue-900;
}
[data-tmds-border-color='tmobiPink100'] {
  border-color: $tmobi-pink-100;
}
[data-tmds-border-color='tmobiPink200'] {
  border-color: $tmobi-pink-200;
}
[data-tmds-border-color='tmobiPink300'] {
  border-color: $tmobi-pink-300;
}
[data-tmds-border-color='tmobiPink400'] {
  border-color: $tmobi-pink-400;
}
[data-tmds-border-color='tmobiPink50'] {
  border-color: $tmobi-pink-50;
}
[data-tmds-border-color='tmobiPink500'] {
  border-color: $tmobi-pink-500;
}
[data-tmds-border-color='tmobiPink600'] {
  border-color: $tmobi-pink-600;
}
[data-tmds-border-color='tmobiPink700'] {
  border-color: $tmobi-pink-700;
}
[data-tmds-border-color='tmobiPink800'] {
  border-color: $tmobi-pink-800;
}
[data-tmds-border-color='tmobiPink900'] {
  border-color: $tmobi-pink-900;
}
[data-tmds-border-color='tmobiPurple100'] {
  border-color: $tmobi-purple-100;
}
[data-tmds-border-color='tmobiPurple200'] {
  border-color: $tmobi-purple-200;
}
[data-tmds-border-color='tmobiPurple300'] {
  border-color: $tmobi-purple-300;
}
[data-tmds-border-color='tmobiPurple400'] {
  border-color: $tmobi-purple-400;
}
[data-tmds-border-color='tmobiPurple50'] {
  border-color: $tmobi-purple-50;
}
[data-tmds-border-color='tmobiPurple500'] {
  border-color: $tmobi-purple-500;
}
[data-tmds-border-color='tmobiPurple600'] {
  border-color: $tmobi-purple-600;
}
[data-tmds-border-color='tmobiPurple700'] {
  border-color: $tmobi-purple-700;
}
[data-tmds-border-color='tmobiPurple800'] {
  border-color: $tmobi-purple-800;
}
[data-tmds-border-color='tmobiPurple900'] {
  border-color: $tmobi-purple-900;
}
[data-tmds-border-color='tmobiTeal100'] {
  border-color: $tmobi-teal-100;
}
[data-tmds-border-color='tmobiTeal200'] {
  border-color: $tmobi-teal-200;
}
[data-tmds-border-color='tmobiTeal300'] {
  border-color: $tmobi-teal-300;
}
[data-tmds-border-color='tmobiTeal400'] {
  border-color: $tmobi-teal-400;
}
[data-tmds-border-color='tmobiTeal50'] {
  border-color: $tmobi-teal-50;
}
[data-tmds-border-color='tmobiTeal500'] {
  border-color: $tmobi-teal-500;
}
[data-tmds-border-color='tmobiTeal600'] {
  border-color: $tmobi-teal-600;
}
[data-tmds-border-color='tmobiTeal700'] {
  border-color: $tmobi-teal-700;
}
[data-tmds-border-color='tmobiTeal800'] {
  border-color: $tmobi-teal-800;
}
[data-tmds-border-color='tmobiTeal900'] {
  border-color: $tmobi-teal-900;
}
[data-tmds-border-color='wbBlack'] {
  border-color: $wb-black;
}
[data-tmds-border-color='wbWhite'] {
  border-color: $wb-white;
}
[data-tmds-border-color='whiteAlpha100'] {
  border-color: $white-alpha-100;
}
[data-tmds-border-color='whiteAlpha200'] {
  border-color: $white-alpha-200;
}
[data-tmds-border-color='whiteAlpha300'] {
  border-color: $white-alpha-300;
}
[data-tmds-border-color='whiteAlpha400'] {
  border-color: $white-alpha-400;
}
[data-tmds-border-color='whiteAlpha50'] {
  border-color: $white-alpha-50;
}
[data-tmds-border-color='whiteAlpha500'] {
  border-color: $white-alpha-500;
}
[data-tmds-border-color='whiteAlpha600'] {
  border-color: $white-alpha-600;
}
[data-tmds-border-color='whiteAlpha700'] {
  border-color: $white-alpha-700;
}
[data-tmds-border-color='whiteAlpha800'] {
  border-color: $white-alpha-800;
}
[data-tmds-border-color='whiteAlpha900'] {
  border-color: $white-alpha-900;
}
[data-tmds-border-color='yellow100'] {
  border-color: $yellow-100;
}
[data-tmds-border-color='yellow200'] {
  border-color: $yellow-200;
}
[data-tmds-border-color='yellow300'] {
  border-color: $yellow-300;
}
[data-tmds-border-color='yellow400'] {
  border-color: $yellow-400;
}
[data-tmds-border-color='yellow50'] {
  border-color: $yellow-50;
}
[data-tmds-border-color='yellow500'] {
  border-color: $yellow-500;
}
[data-tmds-border-color='yellow600'] {
  border-color: $yellow-600;
}
[data-tmds-border-color='yellow700'] {
  border-color: $yellow-700;
}
[data-tmds-border-color='yellow800'] {
  border-color: $yellow-800;
}
[data-tmds-border-color='yellow900'] {
  border-color: $yellow-900;
}
[data-tmds-border-color='backgroundDangerPrimary'] {
  border-color: $background-danger-primary;
}
[data-tmds-border-color='backgroundDangerSecondary'] {
  border-color: $background-danger-secondary;
}
[data-tmds-border-color='tmobiBrandPrimary'] {
  border-color: $tmobi-brand-primary;
}
[data-tmds-border-color='tmobiBrandSecondary'] {
  border-color: $tmobi-brand-secondary;
}
[data-tmds-border-color='tmobiBrandBenefit'] {
  border-color: $tmobi-brand-benefit;
}
[data-tmds-border-color='tmobiBrandEv'] {
  border-color: $tmobi-brand-ev;
}
[data-tmds-border-color='tmobiBrandOil'] {
  border-color: $tmobi-brand-oil;
}
[data-tmds-border-color='backgroundButtonDisabled'] {
  border-color: $background-button-disabled;
}
[data-tmds-border-color='backgroundButtonNegative'] {
  border-color: $background-button-negative;
}
[data-tmds-border-color='backgroundButtonPrimary'] {
  border-color: $background-button-primary;
}
[data-tmds-border-color='backgroundButtonSecondary'] {
  border-color: $background-button-secondary;
}
[data-tmds-border-color='backgroundDangerBolder'] {
  border-color: $background-danger-bolder;
}
[data-tmds-border-color='backgroundInputDefault'] {
  border-color: $background-input-default;
}
[data-tmds-border-color='backgroundInputDisabled'] {
  border-color: $background-input-disabled;
}
[data-tmds-border-color='backgroundSuccessBolder'] {
  border-color: $background-success-bolder;
}
[data-tmds-border-color='backgroundSuccessDisabled'] {
  border-color: $background-success-disabled;
}
[data-tmds-border-color='backgroundSuccessPrimary'] {
  border-color: $background-success-primary;
}
[data-tmds-border-color='backgroundSuccessSecondary'] {
  border-color: $background-success-secondary;
}
[data-tmds-border-color='backgroundSurfaceAds'] {
  border-color: $background-surface-ads;
}
[data-tmds-border-color='backgroundSurfaceFloatingOnMap'] {
  border-color: $background-surface-floating-on-map;
}
[data-tmds-border-color='backgroundSurfacePopOverlay'] {
  border-color: $background-surface-pop-overlay;
}
[data-tmds-border-color='backgroundSurfacePrimaryBase'] {
  border-color: $background-surface-primary-base;
}
[data-tmds-border-color='backgroundSurfacePrimaryElevated'] {
  border-color: $background-surface-primary-elevated;
}
[data-tmds-border-color='backgroundSurfaceSecondaryBase'] {
  border-color: $background-surface-secondary-base;
}
[data-tmds-border-color='backgroundSurfaceTouchTarget'] {
  border-color: $background-surface-touch-target;
}
[data-tmds-border-color='backgroundWarningBolder'] {
  border-color: $background-warning-bolder;
}
[data-tmds-border-color='backgroundWarningDisabled'] {
  border-color: $background-warning-disabled;
}
[data-tmds-border-color='backgroundWarningPrimary'] {
  border-color: $background-warning-primary;
}
[data-tmds-border-color='backgroundWarningSecondary'] {
  border-color: $background-warning-secondary;
}
[data-tmds-border-color='borderButtonStrokeAccent'] {
  border-color: $border-button-stroke-accent;
}
[data-tmds-border-color='borderButtonStrokeDefault'] {
  border-color: $border-button-stroke-default;
}
[data-tmds-border-color='borderButtonStrokeDisabled'] {
  border-color: $border-button-stroke-disabled;
}
[data-tmds-border-color='borderInputStrokeAccent'] {
  border-color: $border-input-stroke-accent;
}
[data-tmds-border-color='borderInputStrokeDanger'] {
  border-color: $border-input-stroke-danger;
}
[data-tmds-border-color='borderInputStrokeDefault'] {
  border-color: $border-input-stroke-default;
}
[data-tmds-border-color='borderLineWide'] {
  border-color: $border-line-wide;
}
[data-tmds-border-color='borderLineWideElevated'] {
  border-color: $border-line-wide-elevated;
}
[data-tmds-border-color='borderLineDefault'] {
  border-color: $border-line-default;
}
[data-tmds-border-color='borderLineDefaultElevated'] {
  border-color: $border-line-default-elevated;
}
[data-tmds-border-color='iconAccent'] {
  border-color: $icon-accent;
}
[data-tmds-border-color='iconInverse'] {
  border-color: $icon-inverse;
}
[data-tmds-border-color='iconOnCta'] {
  border-color: $icon-on-cta;
}
[data-tmds-border-color='iconPrimary'] {
  border-color: $icon-primary;
}
[data-tmds-border-color='iconQuaternary'] {
  border-color: $icon-quaternary;
}
[data-tmds-border-color='iconSecondary'] {
  border-color: $icon-secondary;
}
[data-tmds-border-color='iconTertiary'] {
  border-color: $icon-tertiary;
}
[data-tmds-border-color='textBasicAccent'] {
  border-color: $text-basic-accent;
}
[data-tmds-border-color='textBasicCaption'] {
  border-color: $text-basic-caption;
}
[data-tmds-border-color='textBasicDefault'] {
  border-color: $text-basic-default;
}
[data-tmds-border-color='textBasicDescription'] {
  border-color: $text-basic-description;
}
[data-tmds-border-color='textBasicDisabled'] {
  border-color: $text-basic-disabled;
}
[data-tmds-border-color='textBasicEv'] {
  border-color: $text-basic-ev;
}
[data-tmds-border-color='textBasicInverse'] {
  border-color: $text-basic-inverse;
}
[data-tmds-border-color='textBasicLink'] {
  border-color: $text-basic-link;
}
[data-tmds-border-color='textBasicOil'] {
  border-color: $text-basic-oil;
}
[data-tmds-border-color='textBasicPlaceHolder'] {
  border-color: $text-basic-place-holder;
}
[data-tmds-border-color='textButtonAccent'] {
  border-color: $text-button-accent;
}
[data-tmds-border-color='textButtonDisabled'] {
  border-color: $text-button-disabled;
}
[data-tmds-border-color='textButtonDisabledStroke'] {
  border-color: $text-button-disabled-stroke;
}
[data-tmds-border-color='textButtonInverse'] {
  border-color: $text-button-inverse;
}
[data-tmds-border-color='textButtonPrimary'] {
  border-color: $text-button-primary;
}
[data-tmds-border-color='textButtonSecondary'] {
  border-color: $text-button-secondary;
}
[data-tmds-border-color='textDangerBolder'] {
  border-color: $text-danger-bolder;
}
[data-tmds-border-color='textDangerDefault'] {
  border-color: $text-danger-default;
}
[data-tmds-border-color='textSuccessBolder'] {
  border-color: $text-success-bolder;
}
[data-tmds-border-color='textSuccessDefault'] {
  border-color: $text-success-default;
}
[data-tmds-border-color='textWarningBolder'] {
  border-color: $text-warning-bolder;
}
[data-tmds-border-color='textWarningDefault'] {
  border-color: $text-warning-default;
}
[data-tmds-border-color='tmobiTrafficDelay'] {
  border-color: $tmobi-traffic-delay;
}
[data-tmds-border-color='tmobiTrafficCongestion'] {
  border-color: $tmobi-traffic-congestion;
}
[data-tmds-border-color='tmobiTrafficSmooth'] {
  border-color: $tmobi-traffic-smooth;
}
[data-tmds-svg-color='blackAlpha100'] {
  svg {
    * {
      fill: $black-alpha-100;
    }
  }
}
[data-tmds-svg-color='blackAlpha200'] {
  svg {
    * {
      fill: $black-alpha-200;
    }
  }
}
[data-tmds-svg-color='blackAlpha300'] {
  svg {
    * {
      fill: $black-alpha-300;
    }
  }
}
[data-tmds-svg-color='blackAlpha400'] {
  svg {
    * {
      fill: $black-alpha-400;
    }
  }
}
[data-tmds-svg-color='blackAlpha50'] {
  svg {
    * {
      fill: $black-alpha-50;
    }
  }
}
[data-tmds-svg-color='blackAlpha500'] {
  svg {
    * {
      fill: $black-alpha-500;
    }
  }
}
[data-tmds-svg-color='blackAlpha600'] {
  svg {
    * {
      fill: $black-alpha-600;
    }
  }
}
[data-tmds-svg-color='blackAlpha700'] {
  svg {
    * {
      fill: $black-alpha-700;
    }
  }
}
[data-tmds-svg-color='blackAlpha800'] {
  svg {
    * {
      fill: $black-alpha-800;
    }
  }
}
[data-tmds-svg-color='blackAlpha900'] {
  svg {
    * {
      fill: $black-alpha-900;
    }
  }
}
[data-tmds-svg-color='gray100'] {
  svg {
    * {
      fill: $gray-100;
    }
  }
}
[data-tmds-svg-color='gray200'] {
  svg {
    * {
      fill: $gray-200;
    }
  }
}
[data-tmds-svg-color='gray300'] {
  svg {
    * {
      fill: $gray-300;
    }
  }
}
[data-tmds-svg-color='gray400'] {
  svg {
    * {
      fill: $gray-400;
    }
  }
}
[data-tmds-svg-color='gray50'] {
  svg {
    * {
      fill: $gray-50;
    }
  }
}
[data-tmds-svg-color='gray500'] {
  svg {
    * {
      fill: $gray-500;
    }
  }
}
[data-tmds-svg-color='gray600'] {
  svg {
    * {
      fill: $gray-600;
    }
  }
}
[data-tmds-svg-color='gray700'] {
  svg {
    * {
      fill: $gray-700;
    }
  }
}
[data-tmds-svg-color='gray800'] {
  svg {
    * {
      fill: $gray-800;
    }
  }
}
[data-tmds-svg-color='gray900'] {
  svg {
    * {
      fill: $gray-900;
    }
  }
}
[data-tmds-svg-color='green100'] {
  svg {
    * {
      fill: $green-100;
    }
  }
}
[data-tmds-svg-color='green200'] {
  svg {
    * {
      fill: $green-200;
    }
  }
}
[data-tmds-svg-color='green300'] {
  svg {
    * {
      fill: $green-300;
    }
  }
}
[data-tmds-svg-color='green400'] {
  svg {
    * {
      fill: $green-400;
    }
  }
}
[data-tmds-svg-color='green50'] {
  svg {
    * {
      fill: $green-50;
    }
  }
}
[data-tmds-svg-color='green500'] {
  svg {
    * {
      fill: $green-500;
    }
  }
}
[data-tmds-svg-color='green600'] {
  svg {
    * {
      fill: $green-600;
    }
  }
}
[data-tmds-svg-color='green700'] {
  svg {
    * {
      fill: $green-700;
    }
  }
}
[data-tmds-svg-color='green800'] {
  svg {
    * {
      fill: $green-800;
    }
  }
}
[data-tmds-svg-color='green900'] {
  svg {
    * {
      fill: $green-900;
    }
  }
}
[data-tmds-svg-color='orange100'] {
  svg {
    * {
      fill: $orange-100;
    }
  }
}
[data-tmds-svg-color='orange200'] {
  svg {
    * {
      fill: $orange-200;
    }
  }
}
[data-tmds-svg-color='orange300'] {
  svg {
    * {
      fill: $orange-300;
    }
  }
}
[data-tmds-svg-color='orange400'] {
  svg {
    * {
      fill: $orange-400;
    }
  }
}
[data-tmds-svg-color='orange50'] {
  svg {
    * {
      fill: $orange-50;
    }
  }
}
[data-tmds-svg-color='orange500'] {
  svg {
    * {
      fill: $orange-500;
    }
  }
}
[data-tmds-svg-color='orange600'] {
  svg {
    * {
      fill: $orange-600;
    }
  }
}
[data-tmds-svg-color='orange700'] {
  svg {
    * {
      fill: $orange-700;
    }
  }
}
[data-tmds-svg-color='orange800'] {
  svg {
    * {
      fill: $orange-800;
    }
  }
}
[data-tmds-svg-color='orange900'] {
  svg {
    * {
      fill: $orange-900;
    }
  }
}
[data-tmds-svg-color='red100'] {
  svg {
    * {
      fill: $red-100;
    }
  }
}
[data-tmds-svg-color='red200'] {
  svg {
    * {
      fill: $red-200;
    }
  }
}
[data-tmds-svg-color='red300'] {
  svg {
    * {
      fill: $red-300;
    }
  }
}
[data-tmds-svg-color='red400'] {
  svg {
    * {
      fill: $red-400;
    }
  }
}
[data-tmds-svg-color='red50'] {
  svg {
    * {
      fill: $red-50;
    }
  }
}
[data-tmds-svg-color='red500'] {
  svg {
    * {
      fill: $red-500;
    }
  }
}
[data-tmds-svg-color='red600'] {
  svg {
    * {
      fill: $red-600;
    }
  }
}
[data-tmds-svg-color='red700'] {
  svg {
    * {
      fill: $red-700;
    }
  }
}
[data-tmds-svg-color='red800'] {
  svg {
    * {
      fill: $red-800;
    }
  }
}
[data-tmds-svg-color='red900'] {
  svg {
    * {
      fill: $red-900;
    }
  }
}
[data-tmds-svg-color='sky100'] {
  svg {
    * {
      fill: $sky-100;
    }
  }
}
[data-tmds-svg-color='sky200'] {
  svg {
    * {
      fill: $sky-200;
    }
  }
}
[data-tmds-svg-color='sky300'] {
  svg {
    * {
      fill: $sky-300;
    }
  }
}
[data-tmds-svg-color='sky400'] {
  svg {
    * {
      fill: $sky-400;
    }
  }
}
[data-tmds-svg-color='sky50'] {
  svg {
    * {
      fill: $sky-50;
    }
  }
}
[data-tmds-svg-color='sky500'] {
  svg {
    * {
      fill: $sky-500;
    }
  }
}
[data-tmds-svg-color='sky600'] {
  svg {
    * {
      fill: $sky-600;
    }
  }
}
[data-tmds-svg-color='sky700'] {
  svg {
    * {
      fill: $sky-700;
    }
  }
}
[data-tmds-svg-color='sky800'] {
  svg {
    * {
      fill: $sky-800;
    }
  }
}
[data-tmds-svg-color='sky900'] {
  svg {
    * {
      fill: $sky-900;
    }
  }
}
[data-tmds-svg-color='tmobiBlue100'] {
  svg {
    * {
      fill: $tmobi-blue-100;
    }
  }
}
[data-tmds-svg-color='tmobiBlue200'] {
  svg {
    * {
      fill: $tmobi-blue-200;
    }
  }
}
[data-tmds-svg-color='tmobiBlue300'] {
  svg {
    * {
      fill: $tmobi-blue-300;
    }
  }
}
[data-tmds-svg-color='tmobiBlue400'] {
  svg {
    * {
      fill: $tmobi-blue-400;
    }
  }
}
[data-tmds-svg-color='tmobiBlue50'] {
  svg {
    * {
      fill: $tmobi-blue-50;
    }
  }
}
[data-tmds-svg-color='tmobiBlue500'] {
  svg {
    * {
      fill: $tmobi-blue-500;
    }
  }
}
[data-tmds-svg-color='tmobiBlue600'] {
  svg {
    * {
      fill: $tmobi-blue-600;
    }
  }
}
[data-tmds-svg-color='tmobiBlue700'] {
  svg {
    * {
      fill: $tmobi-blue-700;
    }
  }
}
[data-tmds-svg-color='tmobiBlue800'] {
  svg {
    * {
      fill: $tmobi-blue-800;
    }
  }
}
[data-tmds-svg-color='tmobiBlue900'] {
  svg {
    * {
      fill: $tmobi-blue-900;
    }
  }
}
[data-tmds-svg-color='tmobiPink100'] {
  svg {
    * {
      fill: $tmobi-pink-100;
    }
  }
}
[data-tmds-svg-color='tmobiPink200'] {
  svg {
    * {
      fill: $tmobi-pink-200;
    }
  }
}
[data-tmds-svg-color='tmobiPink300'] {
  svg {
    * {
      fill: $tmobi-pink-300;
    }
  }
}
[data-tmds-svg-color='tmobiPink400'] {
  svg {
    * {
      fill: $tmobi-pink-400;
    }
  }
}
[data-tmds-svg-color='tmobiPink50'] {
  svg {
    * {
      fill: $tmobi-pink-50;
    }
  }
}
[data-tmds-svg-color='tmobiPink500'] {
  svg {
    * {
      fill: $tmobi-pink-500;
    }
  }
}
[data-tmds-svg-color='tmobiPink600'] {
  svg {
    * {
      fill: $tmobi-pink-600;
    }
  }
}
[data-tmds-svg-color='tmobiPink700'] {
  svg {
    * {
      fill: $tmobi-pink-700;
    }
  }
}
[data-tmds-svg-color='tmobiPink800'] {
  svg {
    * {
      fill: $tmobi-pink-800;
    }
  }
}
[data-tmds-svg-color='tmobiPink900'] {
  svg {
    * {
      fill: $tmobi-pink-900;
    }
  }
}
[data-tmds-svg-color='tmobiPurple100'] {
  svg {
    * {
      fill: $tmobi-purple-100;
    }
  }
}
[data-tmds-svg-color='tmobiPurple200'] {
  svg {
    * {
      fill: $tmobi-purple-200;
    }
  }
}
[data-tmds-svg-color='tmobiPurple300'] {
  svg {
    * {
      fill: $tmobi-purple-300;
    }
  }
}
[data-tmds-svg-color='tmobiPurple400'] {
  svg {
    * {
      fill: $tmobi-purple-400;
    }
  }
}
[data-tmds-svg-color='tmobiPurple50'] {
  svg {
    * {
      fill: $tmobi-purple-50;
    }
  }
}
[data-tmds-svg-color='tmobiPurple500'] {
  svg {
    * {
      fill: $tmobi-purple-500;
    }
  }
}
[data-tmds-svg-color='tmobiPurple600'] {
  svg {
    * {
      fill: $tmobi-purple-600;
    }
  }
}
[data-tmds-svg-color='tmobiPurple700'] {
  svg {
    * {
      fill: $tmobi-purple-700;
    }
  }
}
[data-tmds-svg-color='tmobiPurple800'] {
  svg {
    * {
      fill: $tmobi-purple-800;
    }
  }
}
[data-tmds-svg-color='tmobiPurple900'] {
  svg {
    * {
      fill: $tmobi-purple-900;
    }
  }
}
[data-tmds-svg-color='tmobiTeal100'] {
  svg {
    * {
      fill: $tmobi-teal-100;
    }
  }
}
[data-tmds-svg-color='tmobiTeal200'] {
  svg {
    * {
      fill: $tmobi-teal-200;
    }
  }
}
[data-tmds-svg-color='tmobiTeal300'] {
  svg {
    * {
      fill: $tmobi-teal-300;
    }
  }
}
[data-tmds-svg-color='tmobiTeal400'] {
  svg {
    * {
      fill: $tmobi-teal-400;
    }
  }
}
[data-tmds-svg-color='tmobiTeal50'] {
  svg {
    * {
      fill: $tmobi-teal-50;
    }
  }
}
[data-tmds-svg-color='tmobiTeal500'] {
  svg {
    * {
      fill: $tmobi-teal-500;
    }
  }
}
[data-tmds-svg-color='tmobiTeal600'] {
  svg {
    * {
      fill: $tmobi-teal-600;
    }
  }
}
[data-tmds-svg-color='tmobiTeal700'] {
  svg {
    * {
      fill: $tmobi-teal-700;
    }
  }
}
[data-tmds-svg-color='tmobiTeal800'] {
  svg {
    * {
      fill: $tmobi-teal-800;
    }
  }
}
[data-tmds-svg-color='tmobiTeal900'] {
  svg {
    * {
      fill: $tmobi-teal-900;
    }
  }
}
[data-tmds-svg-color='wbBlack'] {
  svg {
    * {
      fill: $wb-black;
    }
  }
}
[data-tmds-svg-color='wbWhite'] {
  svg {
    * {
      fill: $wb-white;
    }
  }
}
[data-tmds-svg-color='whiteAlpha100'] {
  svg {
    * {
      fill: $white-alpha-100;
    }
  }
}
[data-tmds-svg-color='whiteAlpha200'] {
  svg {
    * {
      fill: $white-alpha-200;
    }
  }
}
[data-tmds-svg-color='whiteAlpha300'] {
  svg {
    * {
      fill: $white-alpha-300;
    }
  }
}
[data-tmds-svg-color='whiteAlpha400'] {
  svg {
    * {
      fill: $white-alpha-400;
    }
  }
}
[data-tmds-svg-color='whiteAlpha50'] {
  svg {
    * {
      fill: $white-alpha-50;
    }
  }
}
[data-tmds-svg-color='whiteAlpha500'] {
  svg {
    * {
      fill: $white-alpha-500;
    }
  }
}
[data-tmds-svg-color='whiteAlpha600'] {
  svg {
    * {
      fill: $white-alpha-600;
    }
  }
}
[data-tmds-svg-color='whiteAlpha700'] {
  svg {
    * {
      fill: $white-alpha-700;
    }
  }
}
[data-tmds-svg-color='whiteAlpha800'] {
  svg {
    * {
      fill: $white-alpha-800;
    }
  }
}
[data-tmds-svg-color='whiteAlpha900'] {
  svg {
    * {
      fill: $white-alpha-900;
    }
  }
}
[data-tmds-svg-color='yellow100'] {
  svg {
    * {
      fill: $yellow-100;
    }
  }
}
[data-tmds-svg-color='yellow200'] {
  svg {
    * {
      fill: $yellow-200;
    }
  }
}
[data-tmds-svg-color='yellow300'] {
  svg {
    * {
      fill: $yellow-300;
    }
  }
}
[data-tmds-svg-color='yellow400'] {
  svg {
    * {
      fill: $yellow-400;
    }
  }
}
[data-tmds-svg-color='yellow50'] {
  svg {
    * {
      fill: $yellow-50;
    }
  }
}
[data-tmds-svg-color='yellow500'] {
  svg {
    * {
      fill: $yellow-500;
    }
  }
}
[data-tmds-svg-color='yellow600'] {
  svg {
    * {
      fill: $yellow-600;
    }
  }
}
[data-tmds-svg-color='yellow700'] {
  svg {
    * {
      fill: $yellow-700;
    }
  }
}
[data-tmds-svg-color='yellow800'] {
  svg {
    * {
      fill: $yellow-800;
    }
  }
}
[data-tmds-svg-color='yellow900'] {
  svg {
    * {
      fill: $yellow-900;
    }
  }
}
[data-tmds-svg-color='backgroundDangerPrimary'] {
  svg {
    * {
      fill: $background-danger-primary;
    }
  }
}
[data-tmds-svg-color='backgroundDangerSecondary'] {
  svg {
    * {
      fill: $background-danger-secondary;
    }
  }
}
[data-tmds-svg-color='tmobiBrandPrimary'] {
  svg {
    * {
      fill: $tmobi-brand-primary;
    }
  }
}
[data-tmds-svg-color='tmobiBrandSecondary'] {
  svg {
    * {
      fill: $tmobi-brand-secondary;
    }
  }
}
[data-tmds-svg-color='tmobiBrandBenefit'] {
  svg {
    * {
      fill: $tmobi-brand-benefit;
    }
  }
}
[data-tmds-svg-color='tmobiBrandEv'] {
  svg {
    * {
      fill: $tmobi-brand-ev;
    }
  }
}
[data-tmds-svg-color='tmobiBrandOil'] {
  svg {
    * {
      fill: $tmobi-brand-oil;
    }
  }
}
[data-tmds-svg-color='backgroundButtonDisabled'] {
  svg {
    * {
      fill: $background-button-disabled;
    }
  }
}
[data-tmds-svg-color='backgroundButtonNegative'] {
  svg {
    * {
      fill: $background-button-negative;
    }
  }
}
[data-tmds-svg-color='backgroundButtonPrimary'] {
  svg {
    * {
      fill: $background-button-primary;
    }
  }
}
[data-tmds-svg-color='backgroundButtonSecondary'] {
  svg {
    * {
      fill: $background-button-secondary;
    }
  }
}
[data-tmds-svg-color='backgroundDangerBolder'] {
  svg {
    * {
      fill: $background-danger-bolder;
    }
  }
}
[data-tmds-svg-color='backgroundInputDefault'] {
  svg {
    * {
      fill: $background-input-default;
    }
  }
}
[data-tmds-svg-color='backgroundInputDisabled'] {
  svg {
    * {
      fill: $background-input-disabled;
    }
  }
}
[data-tmds-svg-color='backgroundSuccessBolder'] {
  svg {
    * {
      fill: $background-success-bolder;
    }
  }
}
[data-tmds-svg-color='backgroundSuccessDisabled'] {
  svg {
    * {
      fill: $background-success-disabled;
    }
  }
}
[data-tmds-svg-color='backgroundSuccessPrimary'] {
  svg {
    * {
      fill: $background-success-primary;
    }
  }
}
[data-tmds-svg-color='backgroundSuccessSecondary'] {
  svg {
    * {
      fill: $background-success-secondary;
    }
  }
}
[data-tmds-svg-color='backgroundSurfaceAds'] {
  svg {
    * {
      fill: $background-surface-ads;
    }
  }
}
[data-tmds-svg-color='backgroundSurfaceFloatingOnMap'] {
  svg {
    * {
      fill: $background-surface-floating-on-map;
    }
  }
}
[data-tmds-svg-color='backgroundSurfacePopOverlay'] {
  svg {
    * {
      fill: $background-surface-pop-overlay;
    }
  }
}
[data-tmds-svg-color='backgroundSurfacePrimaryBase'] {
  svg {
    * {
      fill: $background-surface-primary-base;
    }
  }
}
[data-tmds-svg-color='backgroundSurfacePrimaryElevated'] {
  svg {
    * {
      fill: $background-surface-primary-elevated;
    }
  }
}
[data-tmds-svg-color='backgroundSurfaceSecondaryBase'] {
  svg {
    * {
      fill: $background-surface-secondary-base;
    }
  }
}
[data-tmds-svg-color='backgroundSurfaceTouchTarget'] {
  svg {
    * {
      fill: $background-surface-touch-target;
    }
  }
}
[data-tmds-svg-color='backgroundWarningBolder'] {
  svg {
    * {
      fill: $background-warning-bolder;
    }
  }
}
[data-tmds-svg-color='backgroundWarningDisabled'] {
  svg {
    * {
      fill: $background-warning-disabled;
    }
  }
}
[data-tmds-svg-color='backgroundWarningPrimary'] {
  svg {
    * {
      fill: $background-warning-primary;
    }
  }
}
[data-tmds-svg-color='backgroundWarningSecondary'] {
  svg {
    * {
      fill: $background-warning-secondary;
    }
  }
}
[data-tmds-svg-color='borderButtonStrokeAccent'] {
  svg {
    * {
      fill: $border-button-stroke-accent;
    }
  }
}
[data-tmds-svg-color='borderButtonStrokeDefault'] {
  svg {
    * {
      fill: $border-button-stroke-default;
    }
  }
}
[data-tmds-svg-color='borderButtonStrokeDisabled'] {
  svg {
    * {
      fill: $border-button-stroke-disabled;
    }
  }
}
[data-tmds-svg-color='borderInputStrokeAccent'] {
  svg {
    * {
      fill: $border-input-stroke-accent;
    }
  }
}
[data-tmds-svg-color='borderInputStrokeDanger'] {
  svg {
    * {
      fill: $border-input-stroke-danger;
    }
  }
}
[data-tmds-svg-color='borderInputStrokeDefault'] {
  svg {
    * {
      fill: $border-input-stroke-default;
    }
  }
}
[data-tmds-svg-color='borderLineWide'] {
  svg {
    * {
      fill: $border-line-wide;
    }
  }
}
[data-tmds-svg-color='borderLineWideElevated'] {
  svg {
    * {
      fill: $border-line-wide-elevated;
    }
  }
}
[data-tmds-svg-color='borderLineDefault'] {
  svg {
    * {
      fill: $border-line-default;
    }
  }
}
[data-tmds-svg-color='borderLineDefaultElevated'] {
  svg {
    * {
      fill: $border-line-default-elevated;
    }
  }
}
[data-tmds-svg-color='iconAccent'] {
  svg {
    * {
      fill: $icon-accent;
    }
  }
}
[data-tmds-svg-color='iconInverse'] {
  svg {
    * {
      fill: $icon-inverse;
    }
  }
}
[data-tmds-svg-color='iconOnCta'] {
  svg {
    * {
      fill: $icon-on-cta;
    }
  }
}
[data-tmds-svg-color='iconPrimary'] {
  svg {
    * {
      fill: $icon-primary;
    }
  }
}
[data-tmds-svg-color='iconQuaternary'] {
  svg {
    * {
      fill: $icon-quaternary;
    }
  }
}
[data-tmds-svg-color='iconSecondary'] {
  svg {
    * {
      fill: $icon-secondary;
    }
  }
}
[data-tmds-svg-color='iconTertiary'] {
  svg {
    * {
      fill: $icon-tertiary;
    }
  }
}
[data-tmds-svg-color='textBasicAccent'] {
  svg {
    * {
      fill: $text-basic-accent;
    }
  }
}
[data-tmds-svg-color='textBasicCaption'] {
  svg {
    * {
      fill: $text-basic-caption;
    }
  }
}
[data-tmds-svg-color='textBasicDefault'] {
  svg {
    * {
      fill: $text-basic-default;
    }
  }
}
[data-tmds-svg-color='textBasicDescription'] {
  svg {
    * {
      fill: $text-basic-description;
    }
  }
}
[data-tmds-svg-color='textBasicDisabled'] {
  svg {
    * {
      fill: $text-basic-disabled;
    }
  }
}
[data-tmds-svg-color='textBasicEv'] {
  svg {
    * {
      fill: $text-basic-ev;
    }
  }
}
[data-tmds-svg-color='textBasicInverse'] {
  svg {
    * {
      fill: $text-basic-inverse;
    }
  }
}
[data-tmds-svg-color='textBasicLink'] {
  svg {
    * {
      fill: $text-basic-link;
    }
  }
}
[data-tmds-svg-color='textBasicOil'] {
  svg {
    * {
      fill: $text-basic-oil;
    }
  }
}
[data-tmds-svg-color='textBasicPlaceHolder'] {
  svg {
    * {
      fill: $text-basic-place-holder;
    }
  }
}
[data-tmds-svg-color='textButtonAccent'] {
  svg {
    * {
      fill: $text-button-accent;
    }
  }
}
[data-tmds-svg-color='textButtonDisabled'] {
  svg {
    * {
      fill: $text-button-disabled;
    }
  }
}
[data-tmds-svg-color='textButtonDisabledStroke'] {
  svg {
    * {
      fill: $text-button-disabled-stroke;
    }
  }
}
[data-tmds-svg-color='textButtonInverse'] {
  svg {
    * {
      fill: $text-button-inverse;
    }
  }
}
[data-tmds-svg-color='textButtonPrimary'] {
  svg {
    * {
      fill: $text-button-primary;
    }
  }
}
[data-tmds-svg-color='textButtonSecondary'] {
  svg {
    * {
      fill: $text-button-secondary;
    }
  }
}
[data-tmds-svg-color='textDangerBolder'] {
  svg {
    * {
      fill: $text-danger-bolder;
    }
  }
}
[data-tmds-svg-color='textDangerDefault'] {
  svg {
    * {
      fill: $text-danger-default;
    }
  }
}
[data-tmds-svg-color='textSuccessBolder'] {
  svg {
    * {
      fill: $text-success-bolder;
    }
  }
}
[data-tmds-svg-color='textSuccessDefault'] {
  svg {
    * {
      fill: $text-success-default;
    }
  }
}
[data-tmds-svg-color='textWarningBolder'] {
  svg {
    * {
      fill: $text-warning-bolder;
    }
  }
}
[data-tmds-svg-color='textWarningDefault'] {
  svg {
    * {
      fill: $text-warning-default;
    }
  }
}
[data-tmds-svg-color='tmobiTrafficDelay'] {
  svg {
    * {
      fill: $tmobi-traffic-delay;
    }
  }
}
[data-tmds-svg-color='tmobiTrafficCongestion'] {
  svg {
    * {
      fill: $tmobi-traffic-congestion;
    }
  }
}
[data-tmds-svg-color='tmobiTrafficSmooth'] {
  svg {
    * {
      fill: $tmobi-traffic-smooth;
    }
  }
}
