@charset "UTF-8";

@import '../@tmds/tmdscolor';
@import '../@tmds/tmdsfonts';
@import '../@tmds/variables/color';

@import '../basics/variables';
@import '../basics/mixins';
@import '../basics/functions';
@import '../basics/extends';
@import '../basics/keyframes';
@import '../basics/tmdscolors';
@import '../basics/publictranscolors';

.wrap {
  padding: 10px;
  padding-top: 100px;

  .link_wrap {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    button {
      padding: 5px 7px;
      border: 1px solid #ccc;
      border-radius: 3px;
      background-color: #fff;
      margin-right: 10px;
      margin-bottom: 10px;
      min-width: 40px;
      min-height: 40px;
      display: flex;
      flex-direction: column;

      em {
        color: #aaa;
        font-size: 12px;
        margin-top: 1px;
      }
    }
  }

  .log_wrap {
    border: 1px solid #aaa;
    height: 200px;
    overflow-y: scroll;

    div {
      padding: 3px 2px;
      font-size: 10px;

      &.now_item {
        color: rgb(185, 61, 61);
      }
    }
  }
}
