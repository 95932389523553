@charset "UTF-8";

@import '../@tmds/tmdscolor';
@import '../@tmds/tmdsfonts';
@import '../@tmds/variables/color';

@import '../basics/variables';
@import '../basics/mixins';
@import '../basics/functions';
@import '../basics/extends';
@import '../basics/keyframes';
@import '../basics/tmdscolors';
@import '../basics/publictranscolors';

.vsm_compass {
  display: inline-block;
  width: 34px;
  height: 34px;
  border-radius: 100%;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
  background-color: $wb-white;

  &.hide {
    display: none;
  }

  button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 34px;
    height: 34px;
    @include touch-guide(21px, $top: 4px, $left: 4px, $right: 4px, $bottom: 4px, $opacity: 0.2);
  }
}
