@charset "UTF-8";

@import '../@tmds/tmdscolor';
@import '../@tmds/tmdsfonts';
@import '../@tmds/variables/color';

@import '../basics/variables';
@import '../basics/mixins';
@import '../basics/functions';
@import '../basics/extends';
@import '../basics/keyframes';
@import '../basics/tmdscolors';
@import '../basics/publictranscolors';

.bottom_popup_modal {
  width: 100%;
  position: fixed;
  background: $wb-white;
  z-index: $Z-custom-layer;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  padding-top: 20px;
  bottom: 0;
  transform: translateY(100%);
  transition: transform ease-in-out 0.2s;

  &.is_open {
    transform: translateY(0%);
  }

  &.is_landscape {
    width: 360px;
    right: 16px;
    top: var(--hybrid-top);
  }
}
