@charset "UTF-8";

@import '../@tmds/tmdscolor';
@import '../@tmds/tmdsfonts';
@import '../@tmds/variables/color';

@import '../basics/variables';
@import '../basics/mixins';
@import '../basics/functions';
@import '../basics/extends';
@import '../basics/keyframes';
@import '../basics/tmdscolors';
@import '../basics/publictranscolors';

.title {
  margin-top: 0;
  padding: 15px 0;

  button {
    margin-left: 5px;
    font-size: 10px;
    border: 1px solid gray;
    border-radius: 3px;
    padding: 5px;
  }
}

.info_box {
  margin-bottom: 15px;
  border-bottom: 1px solid $gray-300;

  div {
    padding: 10px;
    flex: 1;
    word-break: break-all;
  }
}
