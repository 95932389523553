@charset "UTF-8";

@import '../@tmds/tmdscolor';
@import '../@tmds/tmdsfonts';
@import '../@tmds/variables/color';

@import '../basics/variables';
@import '../basics/mixins';
@import '../basics/functions';
@import '../basics/extends';
@import '../basics/keyframes';
@import '../basics/tmdscolors';
@import '../basics/publictranscolors';

.wrap {
  position: absolute;
  z-index: $Z-current-button;
  left: 16px;

  width: 40px;
  height: 49px;

  top: -119px; // height 49 + bottom 70
}
