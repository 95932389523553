@charset "UTF-8";

@import '../../@tmds/tmdscolor';
@import '../../@tmds/tmdsfonts';
@import '../../@tmds/variables/color';

@import '../../basics/variables';
@import '../../basics/mixins';
@import '../../basics/functions';
@import '../../basics/extends';
@import '../../basics/keyframes';
@import '../../basics/tmdscolors';
@import '../../basics/publictranscolors';

.wrap {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;

  .no_result {
    @include font-size-18-bold();
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    color: $gray-400;
    text-align: center;
    background-color: $wb-white;
  }

  .banner_wrap {
    opacity: 1;
    @include common-banner-layout;
  }

  .list {
    background-color: $wb-white;
    border-bottom: 1px solid $gray-100;
  }
}
