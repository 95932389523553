@charset "UTF-8";

@import '../@tmds/tmdscolor';
@import '../@tmds/tmdsfonts';
@import '../@tmds/variables/color';

@import '../basics/variables';
@import '../basics/mixins';
@import '../basics/functions';
@import '../basics/extends';
@import '../basics/keyframes';
@import '../basics/tmdscolors';
@import '../basics/publictranscolors';

.wrap {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;

  .tmds_tag {
    @include tmobi-font-bold(10px);
    height: 19px;
  }

  .title_wrap {
    @include line-clamp(2);
    text-overflow: ellipsis ' [..]';

    .tag {
      position: relative;
      bottom: 2.5px;

      margin-right: 6px;
    }

    .title {
      @include font-size-18-bold();
      word-break: break-word;
    }

    .category {
      position: relative;
      bottom: 2px;
      margin-left: 4px;

      @include font-size-12();
      color: $gray-400;
    }
  }

  .info_wrap {
    display: flex;
    flex-direction: column;
    gap: 2px;

    margin-top: 6px;

    .address {
      @include font-size-14();
      color: $gray-500;
    }
  }

  .item {
    width: calc(100% - 76px);
  }

  .button_wrap {
    position: absolute;
    top: 0;
    right: 0;

    .route {
      display: flex;
      justify-content: center;
      align-items: center;

      width: 48px;
      height: 48px;
      border-radius: 50%;

      background-color: $tmobi-blue-700;
    }

    .confirm {
      @include font-size-14-bold();

      padding: 8px 16px;
      border-radius: 20px;

      border: 1px solid $gray-300;
      color: $gray-800;
      background-color: $wb-white;

      [data-focus='true'] & {
        border: 1px solid $gray-900;
      }
    }
  }

  .extra_info {
    display: flex;
    align-items: center;
    @include font-size-14();
    color: $gray-800;

    .icon_dot {
      width: 12px;
      height: 12px;

      * {
        fill: $gray-300;
      }
    }

    .distance {
      color: $tmobi-blue-800;
    }

    .count {
      color: $tmobi-blue-400;
      display: inline-flex;
      align-items: center;
    }

    .extra_item {
      display: flex;
      align-items: center;
      white-space: nowrap;
    }

    .icon_arrow {
      margin: 0 2px;
    }

    .ellipsis_parent {
      min-width: 0;
    }

    .ellipsis {
      flex: 1;
      @include text-ellipsis;
    }

    .review_wrap {
      position: relative;
    }

    .review_info {
      display: inline-flex;
      gap: 4px;
      color: $gray-800;
      @include font-size-14();

      .review_count {
        @include font-size-14-bold();
      }
    }
  }

  .special_info {
    margin-top: 6px;
  }

  .special_action {
    margin-top: 10px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    &.is_hide {
      display: none;
    }

    &.bus_tag_wrap {
      gap: 4px;
    }

    .tmds_tag {
      @include tmobi-font-bold(10px);
      height: 19px;
    }

    .tag_list {
      display: inline-flex;
      flex-wrap: wrap;

      &:has(~ .coupon) {
        margin-right: 6px;
      }

      .tag {
        display: inline-flex;
        height: 18px;
        flex-shrink: 0;
        margin-left: 2px;

        &:first-child {
          margin: 0;
        }
      }
    }

    .image_list {
      margin-top: 12px;
      display: grid;
      width: 100%;
      grid-gap: 2px;
      grid-template-columns: repeat(var(--image-count), 1fr);

      &.hide {
        display: none;
      }
    }
  }
}

.image_wrap {
  @include image-holder($width: 100%, $height: 80px);

  &:first-child {
    border-radius: 4px 0 0 4px;
  }

  &:last-child {
    border-radius: 0 4px 4px 0;
  }

  .img {
    width: inherit;
    height: inherit;
    border-radius: inherit;
  }
}
