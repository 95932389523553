@charset "UTF-8";

@import '../../@tmds/tmdscolor';
@import '../../@tmds/tmdsfonts';
@import '../../@tmds/variables/color';

@import '../../basics/variables';
@import '../../basics/mixins';
@import '../../basics/functions';
@import '../../basics/extends';
@import '../../basics/keyframes';
@import '../../basics/tmdscolors';
@import '../../basics/publictranscolors';

.wrap {
  position: relative;
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  &::after {
    content: ' ';
    z-index: $Z-header;
    position: absolute;
    top: 0;
    width: 100%;
    height: 20px;
    background: linear-gradient(180deg, $wb-white 0%, $C-white-tr-00 100%);
    pointer-events: none;
  }

  .vsm_compass {
    position: absolute;
    z-index: $Z-compass;
    margin-left: 11px;
    margin-top: 7px;
  }

  .action {
    position: relative;
    position: absolute;
    z-index: $Z-custom-layer;
    bottom: 0;
    width: 100%;
    display: block;

    .current_position {
      position: absolute;
      left: 12px;
      transform: translateY(calc(-100% - 11px));
    }
  }

  .info_wrap {
    padding-top: 20px;
    border-radius: 16px 16px 0px 0px;
    background: $wb-white;

    &.hide {
      display: none;
    }

    &.invalid_area {
      .address {
        .text {
          color: $gray-400;
        }
      }

      .route_btn {
        button {
          color: $gray-300;
          background-color: $gray-50;
        }
      }
    }

    .address {
      display: flex;
      padding: 4px 20px 24px 20px;

      i {
        @include override-svg($red-500, 20px, 20px);

        display: inline-flex;
        align-items: center;
        justify-content: center;
        height: 20px;
        width: 20px;
        flex-shrink: 0;
        position: relative;
        top: 1px;
      }

      .text {
        @include font-size-16();
        color: $gray-700;
        margin-left: 8px;
      }
    }
    .route_btn {
      padding: 0px 20px 16px 20px;

      button {
        @include font-size-18-bold();
        height: 52px;
        width: 100%;
        border-radius: 8px;
        background: $tmobi-blue-500;
        color: $wb-white;
      }
    }
  }
}
