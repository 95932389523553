@charset "UTF-8";

@import '../../@tmds/tmdscolor';
@import '../../@tmds/tmdsfonts';
@import '../../@tmds/variables/color';

@import '../../basics/variables';
@import '../../basics/mixins';
@import '../../basics/functions';
@import '../../basics/extends';
@import '../../basics/keyframes';
@import '../../basics/tmdscolors';
@import '../../basics/publictranscolors';

.guide_search {
  @include font-size-14();
  @include override-svg($wb-white, 16px, 16px);

  border-radius: 12px;
  opacity: 0.9800000190734863;

  position: fixed;
  bottom: calc(20px + env(safe-area-inset-bottom));
  left: 12px;
  right: 12px;
  z-index: $Z-floating-bottom-buttons;
  padding: 14px 8px 14px 20px;
  background-color: #15223d;
  color: $wb-white;

  display: flex;
  align-items: center;
  justify-content: space-between;

  // 1000ms 중 50% 동안 opacity 1 -> 0 으로 변경
  animation: fade-out 1000ms 5000ms 1 normal forwards;

  &.interaction_hide {
    animation: fade-out 1000ms 300ms normal forwards;
  }

  .text_keyword {
    @include font-size-14-bold();
  }

  .text_around {
    margin: 0 4px;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
    transform: translateX(0);
  }
  50% {
    opacity: 0;
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    transform: translateX(-200vw);
  }
}
