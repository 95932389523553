@charset "UTF-8";

@import '../@tmds/tmdscolor';
@import '../@tmds/tmdsfonts';
@import '../@tmds/variables/color';

@import '../basics/variables';
@import '../basics/mixins';
@import '../basics/functions';
@import '../basics/extends';
@import '../basics/keyframes';
@import '../basics/tmdscolors';
@import '../basics/publictranscolors';

.page_wrap {
  .item {
    width: 300px;
    height: 200px;
    background-color: lightgray;
    margin: 10px;

    .img {
      width: inherit;
      height: inherit;
      opacity: 0;
      transition: opacity ease-in 1s;

      &.fade_in {
        opacity: 1;
      }
    }
  }
}
