@charset "UTF-8";

@import '../@tmds/tmdscolor';
@import '../@tmds/tmdsfonts';
@import '../@tmds/variables/color';

@import '../basics/variables';
@import '../basics/mixins';
@import '../basics/functions';
@import '../basics/extends';
@import '../basics/keyframes';
@import '../basics/tmdscolors';
@import '../basics/publictranscolors';

.main_page_warp {
  display: flex;
  flex-direction: column;
  background-color: $wb-white;
  height: 100%;
  padding-bottom: env(safe-area-inset-bottom);

  button,
  input {
    outline: none;
  }

  &.hide_page {
    position: absolute;
    width: 100%;
    height: 100%;
    transform: translateX(-200vw);
  }

  &.hybrid_page {
    padding-top: 68px;
  }

  .contents_wrap {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
  }
}
