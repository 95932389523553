@charset "UTF-8";

@import '../../@tmds/tmdscolor';
@import '../../@tmds/tmdsfonts';
@import '../../@tmds/variables/color';

@import '../../basics/variables';
@import '../../basics/mixins';
@import '../../basics/functions';
@import '../../basics/extends';
@import '../../basics/keyframes';
@import '../../basics/tmdscolors';
@import '../../basics/publictranscolors';

.search_favorite_wrap {
  flex: 1;
  overflow-y: scroll;
  transform: translateZ(0);
}

.no_result {
  @include font-size-18-bold();
  display: flex;
  justify-content: center;
  margin-top: 60px;
  color: $gray-400;

  &.is_landscape {
    margin: 0;
    align-items: center;
    height: calc(100% - 56px);
  }
}
