@charset "UTF-8";

@import '../../@tmds/tmdscolor';
@import '../../@tmds/tmdsfonts';
@import '../../@tmds/variables/color';

@import '../../basics/variables';
@import '../../basics/mixins';
@import '../../basics/functions';
@import '../../basics/extends';
@import '../../basics/keyframes';
@import '../../basics/tmdscolors';
@import '../../basics/publictranscolors';

.suggest_wrap {
  background-color: $wb-white;
  position: fixed;
  top: 68px;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: $Z-suggest-wrap;
  overflow-y: scroll;
  transform: translateZ(0);
}

.fav_recent_wrap {
  border-bottom: 8px solid $gray-50;
}
