@charset "UTF-8";

@import '../../@tmds/tmdscolor';
@import '../../@tmds/tmdsfonts';
@import '../../@tmds/variables/color';

@import '../../basics/variables';
@import '../../basics/mixins';
@import '../../basics/functions';
@import '../../basics/extends';
@import '../../basics/keyframes';
@import '../../basics/tmdscolors';
@import '../../basics/publictranscolors';

@include keyframeSpin();

.search_address_wrap {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  @include keyframeSpin();

  .spinner {
    color: transparent;
    transition-delay: 0.3s;
    display: flex;
    justify-content: center;
    align-items: center;

    .rotate_icon {
      animation: spin 2s infinite linear;
    }
  }
}

.cate_list_wrap {
  padding: 12px 0;
  overflow-x: scroll;
  border-bottom: 8px solid $gray-50;

  .vertical_scroll_wrap {
    display: inline-flex;
    font-size: 13px;
    white-space: nowrap;
    padding: 0 18px;
  }
}

.type_wrap {
  padding: 10px 18px;
  display: flex;
  align-items: center;

  .icon_dot {
    margin: 0 1px 0 2px;
  }

  .type_btn {
    color: $gray-400;
    position: relative;

    @include font-size-12();

    @include touch-guide(5px, $top: -2px, $bottom: -2px, $left: -4px, $right: -4px);

    &.active {
      color: $gray-900;
      @include font-size-12-bold();
    }
  }
}

.need_first_line {
  ul {
    border-top: solid 1px $gray-100;
  }
}

.address_result_wrap {
  flex: 1;
  overflow-y: scroll;
  overflow-x: hidden;
  position: relative;

  .tile_wrap {
    display: flex;
    flex-wrap: wrap;
    align-content: stretch;
  }

  &.smooth_list {
    transform: translateZ(0);
  }
}

.detail_address_wrap {
  flex: 1;
  opacity: 0;
  position: absolute;
  top: -500px;
  width: 100%;
  height: 50px;

  &.show {
    top: 0;
    opacity: 1;
  }

  .detail_contents_wrap {
    display: flex;
    align-items: center;
    height: 50px;
    margin-top: 50px;
    padding: 0 20px;
  }

  .input_wrap {
    display: flex;
    align-items: center;
    border-bottom: 2px solid $gray-200;
    flex: 1;
    min-width: 0;
  }

  &.focused {
    .input_wrap {
      border-bottom: 2px solid $gray-900;
    }
  }

  input {
    flex: 1;
    @include font-size-18-bold();
    border: 0 none;
    caret-color: $tmobi-blue-500;
    padding: 0;
    min-width: 0; // auto일때 min-width가 좀 크게 잡혀 좁은 화면에서 너비를 뚫고감
    height: 50px;
    align-items: center;
    color: $gray-900;

    &::placeholder {
      color: $gray-300;
    }
  }

  .btn_remove {
    min-width: 20px;
    margin: 0 8px;
  }

  .btn_search {
    background-color: $tmobi-blue-500;
    border-radius: 6px;
    width: 62px;
    height: 48px;
    color: $wb-white;
    margin-left: 8px;
    margin-top: 1px;
  }
}

body[data-orientation='landscape'] {
  .detail_address_wrap.focused {
    position: fixed;
    top: 0;
    left: 0;
    background-color: $wb-white;
    height: 100vh;
  }
}
