@charset "UTF-8";

@import '../../@tmds/tmdscolor';
@import '../../@tmds/tmdsfonts';
@import '../../@tmds/variables/color';

@import '../../basics/variables';
@import '../../basics/mixins';
@import '../../basics/functions';
@import '../../basics/extends';
@import '../../basics/keyframes';
@import '../../basics/tmdscolors';
@import '../../basics/publictranscolors';

.search_favorite_item {
  position: relative;
  display: flex;
  flex-direction: row;
  padding: 0;
  border-bottom: 1px solid $gray-50;

  @include touch-guide();

  &::before {
    position: absolute;
    content: '';
    left: 0;
    bottom: -1px;
    height: 0px;
    width: 20px;
    border-bottom: 1px solid $wb-white;
  }

  .icon {
    width: 24px;
    min-width: 24px;
    height: 24px;
    margin-left: -3px;
    margin-right: 12px;
    margin-top: -1px;

    @include override-svg($gray-700, 24px, 24px);

    &.is_star {
      @include override-svg($C-yellow-500, 24px, 24px);
    }
  }
}

.main_click_wrap {
  display: flex;
  flex-direction: row;
  flex: 1;
  padding: 17px 0 16px 23px;
  position: relative;
  overflow: hidden;

  &:last-child {
    padding-right: 18px;
  }
}

.poi_wrap {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;

  .name {
    @include font-size-18();
    color: $gray-900;

    margin-top: -1px;

    em {
      color: $tmobi-blue-500;
    }
  }

  &.ellipsis_two {
    .name {
      @include line-clamp();
    }
  }

  &.ellipsis {
    .name {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  .address {
    @include font-size-14();
    color: $gray-400;
    margin-top: 2px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}

.route_wrap {
  display: flex;
  flex-direction: column;
  z-index: 0;
  overflow: hidden;

  .route_title_line {
    display: flex;
    margin-bottom: 4px;

    .route_custom_title {
      color: $gray-900;
      @include font-size-14-bold();
      position: relative;
      display: inline-block;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  .route_line {
    word-break: break-all;
    @include font-size-16();
    @include line-clamp();

    .start_name {
      display: inline;
      color: $gray-600;
    }

    .arrow {
      width: 14px;
      height: 14px;
      margin: 0 5px -3px;
    }

    .end_name {
      display: inline;
      color: $gray-900;
    }
  }
}

.btn_wrap {
  display: flex;
  align-items: center;
  padding: 19px 16px 19px 7px;
  position: relative;

  .btn_detail {
    @include font-size-14();
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 32px;
    border-radius: 8px;
    color: $gray-600;
    background-color: $gray-100;
    position: relative;
    @include touch-guide(8px);
  }
}

.favorite_route_wrap {
  @include touch-guide();
}
