@charset "UTF-8";

@import '../../@tmds/tmdscolor';
@import '../../@tmds/tmdsfonts';
@import '../../@tmds/variables/color';

@import '../../basics/variables';
@import '../../basics/mixins';
@import '../../basics/functions';
@import '../../basics/extends';
@import '../../basics/keyframes';
@import '../../basics/tmdscolors';
@import '../../basics/publictranscolors';

@include keyframeFadeOut();

.wrap {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;

  .tmds_tag {
    @include tmobi-font-bold(10);
    height: 19px;
  }

  .title_wrap {
    @include line-clamp(2);
    text-overflow: ellipsis ' [..]';
    display: flex;
    justify-content: space-between;
    margin: 0 16px 2px 16px;

    .tag {
      position: relative;
      bottom: 2.5px;

      margin-right: 6px;
    }

    .text_wrap {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 4px;
      max-width: calc(100% - 48px); // 즐겨찾기 아이콘 크기
    }

    .title_content {
      display: flex;
      align-items: center;
      gap: 4px;
    }

    .title {
      @include tmobi-font-bold(17px);
      word-break: break-word;
      @include text-ellipsis();
    }

    .category {
      position: relative;
      @include text-ellipsis();

      @include font-size-12();
      color: $gray-400;
    }

    .star {
      color: 'red';
    }
  }

  .info_wrap {
    display: flex;
    align-items: center;
    margin: 0 16px;

    @include font-size-14();
    color: $gray-600;

    .count {
      color: $tmobi-blue-400;
    }
  }

  .special_info {
    margin-top: 6px;
  }

  .special_action {
    margin-top: 10px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .tag_list {
      display: inline-flex;
      flex-wrap: wrap;
      margin-left: 16px;

      .tag {
        display: inline-flex;
        height: 18px;
        flex-shrink: 0;
        margin-left: 4px;

        &:first-child {
          margin: 0;
        }
      }
    }
  }
}
