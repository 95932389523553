@charset "UTF-8";

@import '../@tmds/tmdscolor';
@import '../@tmds/tmdsfonts';
@import '../@tmds/variables/color';

@import '../basics/variables';
@import '../basics/mixins';
@import '../basics/functions';
@import '../basics/extends';
@import '../basics/keyframes';
@import '../basics/tmdscolors';
@import '../basics/publictranscolors';

.page_wrap {
  padding: 50px 0px;
  background-color: #ccc;
}
.tool {
  padding: 10px;

  p {
    margin-bottom: 5px;

    a {
      border: 1px solid darkblue;
    }
  }
}

.ad_box {
  margin-bottom: 10px;
  background-color: white;
}

.banner_box {
  height: 56px;
  background-color: $gray-100;
}

.input_box {
  margin-bottom: 20px;
  display: flex;

  .input_el {
    height: 30px;
    border-radius: 3px;
    border: 1px solid $gray-400;
    padding: 3px 5px;
    font-size: 14px;
    width: 200px;
  }

  .btn_submit {
    height: 30px;
    border-radius: 3px;
    border: 1px solid $gray-400;
  }
}

.btn_toggle {
  height: 30px;
  width: 40px;
  border-radius: 3px;
  border: 1px solid $gray-400;
  font-size: 10px;
  margin-left: 10px;

  &.active {
    background-color: $tmobi-blue-400;
    color: $wb-white;
    border: 1px solid $wb-white;
  }
}
