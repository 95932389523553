@charset "UTF-8";

@import '../../@tmds/tmdscolor';
@import '../../@tmds/tmdsfonts';
@import '../../@tmds/variables/color';

@import '../../basics/variables';
@import '../../basics/mixins';
@import '../../basics/functions';
@import '../../basics/extends';
@import '../../basics/keyframes';
@import '../../basics/tmdscolors';
@import '../../basics/publictranscolors';

.item {
  @include touch-guide($borderRadius: 4px);
  position: relative;
  border-bottom: 1px solid $gray-100;
  padding: 16px 16px 20px 20px;
  @include list-focus;
  background-color: $wb-white;

  &.is_ad {
    padding: 16px 0;
  }
}

.sub_item {
  @include touch-guide($borderRadius: 4px);
  position: relative;
  border-bottom: 1px solid $gray-100;
  background-color: #fcfcfe;
  @include list-focus;
}

.sub_more {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 6px;
  position: relative;

  padding: 9px 20px;
  background-color: #fcfcfe;
  border-bottom: 1px solid $gray-100;
  @include touch-guide();

  .text {
    @include font-size-14-medium();
    color: $gray-600;
  }

  .count {
    @include font-size-14-bold();
    color: $tmobi-blue-600;
  }
}

.banner_wrap {
  opacity: 1;
  @include common-banner-style;
  @include common-banner-layout;
}

.keyword_banner_wrap {
  border-bottom: 1px solid $gray-50;
}
