@charset "UTF-8";

@import '../@tmds/tmdscolor';
@import '../@tmds/tmdsfonts';
@import '../@tmds/variables/color';

@import '../basics/variables';
@import '../basics/mixins';
@import '../basics/functions';
@import '../basics/extends';
@import '../basics/keyframes';
@import '../basics/tmdscolors';
@import '../basics/publictranscolors';

.default_img_wrap {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;
  background-color: $gray-300;

  img {
    opacity: 1 !important;
    width: 40px !important;
    height: 10px !important;
    min-width: 40px !important;
    min-height: 10px !important;
    outline: none !important;
  }
}
