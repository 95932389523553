@charset "UTF-8";

@import '../@tmds/tmdscolor';
@import '../@tmds/tmdsfonts';
@import '../@tmds/variables/color';

@import '../basics/variables';
@import '../basics/mixins';
@import '../basics/functions';
@import '../basics/extends';
@import '../basics/keyframes';
@import '../basics/tmdscolors';
@import '../basics/publictranscolors';

.ev_marker_wrap {
  display: inline-flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  position: relative;

  @include marker-poi-name($gray-900, 14px);

  .bi {
    display: flex;
    position: relative;
    width: 26px;
    height: 28px;

    .brand_marker {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0px;
      top: 0px;
    }

    .base_marker {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0px;
      left: 0px;
    }

    .logo {
      position: absolute;
      left: 1px;
      top: 1px;
      width: 24px;
      height: 24px;
    }
  }

  .charger_type {
    position: absolute;
    display: inline-block;

    left: -6px;
    top: -4px;
    width: 18px;
    height: 20px;
  }

  .status {
    color: $sky-500;

    &.invalid {
      color: $gray-300;
    }
  }

  [data-style='DAY'] {
    .invalid {
      color: $gray-600;
    }
  }

  &[data-type='ACTIVE'] {
    .bi {
      width: 39px;
      height: 53px;
      position: relative;

      .base_marker {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0px;
        left: 0px;
      }

      .logo {
        position: absolute;
        width: 35px;
        height: 35px;
        top: 2px;
        left: 2px;
      }
    }

    .charger_type {
      width: 24px;
      height: 26px;
      left: -6px;
      top: -4px;
    }

    p:first-child {
      margin-bottom: 2px;
    }
  }
}
