@charset "UTF-8";

@import '../../@tmds/tmdscolor';
@import '../../@tmds/tmdsfonts';
@import '../../@tmds/variables/color';

@import '../../basics/variables';
@import '../../basics/mixins';
@import '../../basics/functions';
@import '../../basics/extends';
@import '../../basics/keyframes';
@import '../../basics/tmdscolors';
@import '../../basics/publictranscolors';

.wrap {
  position: absolute;
  // 53 - 12 + 8 (RankingFilter height - bottomPadding + gap)
  top: 49px;

  display: flex;
  justify-content: center;
  width: 100%;

  padding: 0 12px;
}

.popup_wrap {
  display: flex;
  flex-direction: column;
  max-height: 400px;
  width: 100%;

  background-color: $wb-white;

  border: 1px solid $gray-500;
  border-radius: 8px;
  z-index: $Z-drawer-header;

  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.14);

  &.is_main_city {
    @media screen and (orientation: landscape) {
      // 상단 gap, 하단 gap
      max-height: calc(100vh - 103px - 12px);
    }
  }
}

.title {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  padding: 15px 0;

  @include font-size-16-bold();
  color: $gray-300;
  border-bottom: 1px solid $border-input-stroke-default;

  &.invisible {
    display: none;
  }

  .name {
    background: linear-gradient(135deg, #783cff 0%, #0064ff 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

.list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  @include scroll-wrap(y);

  .item {
    &:nth-child(3n) {
      border-right: none;
    }
  }

  @media (max-width: 359px) {
    grid-template-columns: repeat(2, 1fr);

    .item {
      &:nth-child(2n) {
        border-right: none;
      }

      &:nth-child(3n) {
        border-right: 1px solid $border-line-default;
      }
    }
  }

  @media (min-width: 640px) {
    grid-template-columns: repeat(6, 1fr);

    .item {
      &:nth-child(3n) {
        border-right: 1px solid $border-line-default;
      }

      &:nth-child(6n) {
        border-right: none;
      }
    }
  }
}

.item {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;

  padding: 16px 12px;
  color: $text-basic-default;
  border-right: 1px solid $border-line-default;
  border-bottom: 1px solid $border-line-default;

  text-align: center;
  word-break: keep-all;
  white-space: normal;
  @include tmobi-font-normal(15px);
  line-height: 21.5px;

  &.hide_border {
    border-bottom: none;
  }

  &.whole_area {
    @include tmobi-font-bold(15px);
  }
}
