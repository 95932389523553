@charset "UTF-8";

@import '../@tmds/tmdscolor';
@import '../@tmds/tmdsfonts';
@import '../@tmds/variables/color';

@import '../basics/variables';
@import '../basics/mixins';
@import '../basics/functions';
@import '../basics/extends';
@import '../basics/keyframes';
@import '../basics/tmdscolors';
@import '../basics/publictranscolors';

.thumbnail_wrap {
  position: relative;
  border-radius: 6px;
  overflow: hidden;

  .status {
    position: absolute;
    top: 0;
    left: 0;
    padding: 2px 6px;
    border-radius: 4px 0;
    background: $tmobi-purple-500;
    color: $wb-white;
    @include tmobi-font-normal(11px, 16px);
  }

  .red_dot {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 16px;
    height: 16px;
  }
}
