@charset "UTF-8";

@import '../../@tmds/tmdscolor';
@import '../../@tmds/tmdsfonts';
@import '../../@tmds/variables/color';

@import '../../basics/variables';
@import '../../basics/mixins';
@import '../../basics/functions';
@import '../../basics/extends';
@import '../../basics/keyframes';
@import '../../basics/tmdscolors';
@import '../../basics/publictranscolors';

.search_recent_item {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 20px 0 20px;
  height: 56px;
  border-bottom: 1px solid $gray-100;

  &.in_suggest {
    padding-left: 20px;
  }

  @include touch-guide();

  &::before {
    position: absolute;
    content: '';
    left: 0;
    bottom: -1px;
    height: 0px;
    width: 20px;
    border-bottom: 1px solid $wb-white;
  }

  .icon {
    width: 24px;
    height: 24px;
    margin-right: 8px;
  }

  .name {
    flex: 1;
    color: $gray-900;
    @include font-size-18();
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    em {
      color: $tmobi-blue-500;
    }
  }

  .date {
    @include font-size-14();
    color: $gray-400;
    width: 40px;
    margin-left: 8px;
    text-align: right;
  }

  .select_box {
    width: 34px;
    height: 34px;
    margin-right: -4px;
    margin-left: 12px;
  }
}
