@charset "UTF-8";

@import '../@tmds/tmdscolor';
@import '../@tmds/tmdsfonts';
@import '../@tmds/variables/color';

@import '../basics/variables';
@import '../basics/mixins';
@import '../basics/functions';
@import '../basics/extends';
@import '../basics/keyframes';
@import '../basics/tmdscolors';
@import '../basics/publictranscolors';

.wrap {
  @include image-holder(
    $width: 100%,
    $height: 110px,
    $border: var(--border-color, $black-alpha-100)
  );
  overflow: hidden;
  position: relative;
  border-radius: inherit;
  background-color: $gray-100;

  img {
    width: inherit;
    height: inherit;
    min-width: 100%;
    min-height: 100%;
    border-radius: inherit;
    vertical-align: top;
  }
}
