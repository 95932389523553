@charset "UTF-8";

@import '../@tmds/tmdscolor';
@import '../@tmds/tmdsfonts';
@import '../@tmds/variables/color';

@import '../basics/variables';
@import '../basics/mixins';
@import '../basics/functions';
@import '../basics/extends';
@import '../basics/keyframes';
@import '../basics/tmdscolors';
@import '../basics/publictranscolors';

.bottom_btn_wrap {
  width: 100%;
  background-color: $wb-white;
  padding: 0px 0px 16px;
  display: flex;
  flex-direction: column;

  &.show_gradient {
    position: relative;

    &::after {
      @include fade-out-gradient($top: -20px, $bottom: inherit, $height: 20px, $direction: top);
    }
  }

  &.floating {
    background-color: transparent;
    position: fixed;
    bottom: 0;
    z-index: $Z-floating-bottom-buttons;
    transform: translateY(100%);
    transition: transform ease-in-out 0.2s;

    &.is_show {
      transform: translateY(0%);
    }
  }

  .button_wrap {
    display: flex;
    flex-direction: row;
    padding: 0 20px;
  }

  button {
    @include font-size-18();

    height: 52px;
    color: $gray-900;
    background-color: $gray-100;
    flex: 1;
    border-radius: 8px;
    margin: 0 4px;
    position: relative;

    @include touch-guide(8px);

    .count {
      margin-left: 5px;
      @include font-size-18-bold();
    }

    &:disabled {
      color: $gray-300;

      @include ignore-touch-guide;
    }

    &.active {
      background-color: $tmobi-blue-500;
      color: $wb-white;
    }

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

body[data-orientation='landscape'] {
  .bottom_btn_wrap.floating {
    width: 360px;
    right: 10px;
  }
}
