@charset "UTF-8";

@import '../@tmds/tmdscolor';
@import '../@tmds/tmdsfonts';
@import '../@tmds/variables/color';

@import '../basics/variables';
@import '../basics/mixins';
@import '../basics/functions';
@import '../basics/extends';
@import '../basics/keyframes';
@import '../basics/tmdscolors';
@import '../basics/publictranscolors';

@include keyframeSpin();

.error_wrap {
  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    font-weight: normal;
  }

  .title {
    margin: 0;
    width: 100%;
    text-align: center;
    color: $gray-600;
    @include font-size-18-bold();
  }

  .desc {
    margin-top: 8px;
    color: $gray-400;
    width: 100%;
    text-align: center;
    @include font-size-16();
  }

  .reload_button {
    padding: 9px 20px;
    border-radius: 6px;
    margin-top: 40px;
    color: $gray-900;
    border: 1px solid $gray-300;
    @include font-size-16();
  }
}
