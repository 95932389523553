@charset "UTF-8";

@import '../@tmds/tmdscolor';
@import '../@tmds/tmdsfonts';
@import '../@tmds/variables/color';

@import '../basics/variables';
@import '../basics/mixins';
@import '../basics/functions';
@import '../basics/extends';
@import '../basics/keyframes';
@import '../basics/tmdscolors';
@import '../basics/publictranscolors';

.main_page_wrap {
  background-color: $wb-white;
  height: 100%;
  padding-bottom: env(safe-area-inset-bottom);
  display: flex;
  flex-direction: column;

  button,
  input {
    outline: none;
  }

  .skeleton {
    display: flex;
    flex-direction: column;
    min-height: 100%;
  }

  &.hide_page {
    position: absolute;
    width: 100%;
    height: 100%;
    transform: translateX(-200vw);
  }

  &.hybrid_page {
    padding-top: calc(var(--hybrid-search-area-height) + var(--hybrid-status-area-height));
  }

  .top_action {
    position: relative;
    display: flex;
    align-items: center;
    padding: 4px 16px 14px 20px;
    border-bottom: 8px solid $gray-50;

    .tab_list {
      position: relative;
      display: inline-flex;
      align-items: center;
      gap: 4px;
    }
    .address_btn {
      @include touch-guide(4px, $top: -4px, $bottom: -4px, $left: -4px);
      @include font-size-14-bold();
      position: relative;
      display: flex;
      align-items: center;
      height: 20px;
      margin-left: auto;
      color: $gray-800;
    }
  }

  .tab_wrap {
    position: relative;
    display: flex;

    &::before {
      content: '';
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      border-bottom: 1px solid $gray-100;
    }
    .item {
      @include touch-guide;
      @include tmobi-font-bold(16px, 24px);
      flex: 1;
      position: relative;
      height: 52px;
      color: $gray-400;

      &::before {
        content: '';
        display: none;
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        border-bottom: 2px solid $gray-900;
      }
      &.active {
        color: $gray-900;

        &::before {
          display: block;
        }
      }
      .new_badge {
        position: absolute;
        width: 14px;
        height: 14px;

        margin-left: 4px;
      }
    }
    .coach_mark {
      @include font-size-12-bold;
      overflow: hidden;
      opacity: 0;
      position: absolute;
      bottom: calc(100% - 14px);
      right: 25%;
      transform: translateX(50%);
      width: 169px;
      height: 53px;
      transition: all 500ms ease-out;

      &.active {
        opacity: 1;
      }
    }
  }

  .content {
    position: relative;
    overflow-y: auto;
    flex: 1;

    .error {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .banner_wrap {
    opacity: 1;
    @include common-banner-layout;
  }

  .user_data_action {
    position: relative;
    padding: 4px 16px 12px;
    border-bottom: 1px solid $gray-100;
    text-align: right;

    .recent_edit_btn {
      @include tmobi-font-medium(12px, 16px);
      @include touch-guide($borderRadius: 4px, $top: -4px, $bottom: -4px, $left: -4%, $right: -4%);
      position: relative;
      display: inline-block;
      color: $gray-400;
      white-space: nowrap;
    }
  }

  .recommend_wrap {
    margin: 0 0 70px;

    .section {
      margin-top: 48px;
    }
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0 16px 0 20px;

      .title {
        @include font-size-16-bold;
        color: $gray-900;
      }
      .more {
        @include touch-guide;
        @include tmobi-font-bold(13px);
        position: relative;
        display: flex;
        align-items: center;
        height: 24px;
        padding-left: 4px;
        color: $tmobi-blue-600;
      }
    }
  }
}
