@charset "UTF-8";

@import '../../@tmds/tmdscolor';
@import '../../@tmds/tmdsfonts';
@import '../../@tmds/variables/color';

@import '../../basics/variables';
@import '../../basics/mixins';
@import '../../basics/functions';
@import '../../basics/extends';
@import '../../basics/keyframes';
@import '../../basics/tmdscolors';
@import '../../basics/publictranscolors';

.search_recent_edit_wrap {
  display: flex;
  flex-direction: column;

  height: 100%;
}

.header {
  z-index: $Z-header;

  border-bottom: 1px solid $gray-100;
}

.scroll_wrap {
  flex: 1;
  overflow-y: scroll;
  transform: translateZ(0);
}

.no_result {
  @include font-size-18-bold();
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  color: $gray-400;
  text-align: center;
}

.bottom_button_wrap {
  .delete {
    background-color: $gray-50;
  }
}
