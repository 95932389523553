@charset "UTF-8";

@import '../../@tmds/tmdscolor';
@import '../../@tmds/tmdsfonts';
@import '../../@tmds/variables/color';

@import '../../basics/variables';
@import '../../basics/mixins';
@import '../../basics/functions';
@import '../../basics/extends';
@import '../../basics/keyframes';
@import '../../basics/tmdscolors';
@import '../../basics/publictranscolors';

.search_pick_on_map_wrap {
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  position: relative;

  &.hide_map {
    position: absolute;
    width: 100%;
    height: 100%;
    transform: translateX(-200vw);
  }

  .vsm_compass {
    position: absolute;
    z-index: $Z-compass;
    margin-left: 11px;
    margin-top: 7px;
  }

  .bottom_button {
    height: 68px;
  }

  .bottom {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    z-index: $Z-floating;

    .current_position {
      position: absolute;
      left: 8px;
      transform: translateY(calc(-100% - 8px));
    }

    .address_info {
      position: relative;
      bottom: 0;
      left: 0;
      right: 0;
      box-shadow: 0 -4px 14px 0 $black-alpha-50, 0 -1px 3px 0 $C-wb-transparent-300;
      padding: 8px 20px;
      background-color: $C-search-pick-map-address;
      color: $gray-900;
      display: flex;
      align-items: center;
      word-break: keep-all;

      @include font-size-16();

      .address_inner_wrap {
        display: flex;
        align-items: center;
      }

      i {
        padding-right: 5px;
        position: relative;
        top: 3px;
      }

      .reload_area {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 25px;
        z-index: 1;
      }
    }
  }
}
