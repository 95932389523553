@charset "UTF-8";

@import '../@tmds/tmdscolor';
@import '../@tmds/tmdsfonts';
@import '../@tmds/variables/color';

@import '../basics/variables';
@import '../basics/mixins';
@import '../basics/functions';
@import '../basics/extends';
@import '../basics/keyframes';
@import '../basics/tmdscolors';
@import '../basics/publictranscolors';

.header_wrap {
  display: flex;
  align-items: center;
  background-color: $wb-white;
  height: 56px;
  position: relative;

  &.gradient {
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
  }

  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: $Z-floating;

    .none {
      @include ignore-touch-guide();
    }
  }

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 48px;
    height: 48px;
    @include touch-guide(100%);
  }

  .title {
    display: flex;
    justify-content: center;
    flex: 1;
    margin: 0;
    color: $gray-900;
    @include font-size-18-bold();

    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    &.is_empty {
      visibility: hidden;
    }
  }

  .title:last-child {
    margin-right: 48px;
  }

  .children {
    display: flex;
    flex: 1;
  }

  .back {
    margin-left: 3px;
    @include override-svg($gray-900, 28px, 28px);
  }

  .close {
    @include override-svg($gray-900, 28px, 28px);
  }

  .share {
    @include override-svg($gray-900, 28px, 28px);
  }
}
