@charset "UTF-8";

@import '../../@tmds/tmdscolor';
@import '../../@tmds/tmdsfonts';
@import '../../@tmds/variables/color';

@import '../../basics/variables';
@import '../../basics/mixins';
@import '../../basics/functions';
@import '../../basics/extends';
@import '../../basics/keyframes';
@import '../../basics/tmdscolors';
@import '../../basics/publictranscolors';

.search_recent_edit_item {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 20px;
  height: 52px;
  border-bottom: 1px solid $gray-100;

  @include touch-guide();

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 20px;
    height: 20px;
    margin-right: 12px;
  }

  .name {
    flex: 1;
    color: $text-basic-default;
    @include tmobi-font-normal(17px, 24px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    em {
      color: $tmobi-blue-500;
    }
  }

  .date {
    @include font-size-14();
    color: $gray-400;
    width: 40px;
    margin-left: 8px;
    text-align: right;
  }

  .select_box {
    width: 34px;
    height: 34px;
    margin-right: -4px;
    margin-left: 12px;
  }
}
