@charset "UTF-8";

@import '../@tmds/tmdscolor';
@import '../@tmds/tmdsfonts';
@import '../@tmds/variables/color';

@import '../basics/variables';
@import '../basics/mixins';
@import '../basics/functions';
@import '../basics/extends';
@import '../basics/keyframes';
@import '../basics/tmdscolors';
@import '../basics/publictranscolors';

.link_wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;

  h2 {
    padding: 10px;
    margin: 0;
  }

  .link_box {
    border: 1px solid $gray-300;
    padding: 10px;
    margin: 10px;
    border-radius: 5px;
    text-align: center;

    &:hover {
      background-color: $gray-300;
    }

    .link_category {
      font-family: $font-family;
      font-weight: 500;
      font-size: 10px;
      margin: 0 4px;
      background-color: $wb-black;
      padding: 1px 2px;
      border-radius: 2px;
      color: $wb-white;
    }
  }
}
