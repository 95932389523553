/**
    TMDS Font v.2.1
    https://tmobi.atlassian.net/wiki/spaces/TMAPWEB/pages/731873673/v2.1+-+medium
 */

@font-face {
  font-family: 'TMOBI';
  font-display: swap;
  src:
    local('TMOBI_Regular'),
    url('https://poi-cdn.tmap.co.kr/font/TMOBI_Regular.woff2') format('woff2'),
    url('https://poi-cdn.tmap.co.kr/font/TMOBI_Regular.woff') format('woff'),
    url('https://poi-cdn.tmap.co.kr/font/TMOBI_Regular.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'TMOBI';
  font-display: swap;
  src:
    local('TMOBI_Medium'),
    url('https://poi-cdn.tmap.co.kr/font/TMOBI_Medium.woff2') format('woff2'),
    url('https://poi-cdn.tmap.co.kr/font/TMOBI_Medium.woff') format('woff'),
    url('https://poi-cdn.tmap.co.kr/font/TMOBI_Medium.ttf') format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'TMOBI';
  font-display: swap;
  src:
    local('TMOBI_Bold'),
    url('https://poi-cdn.tmap.co.kr/font/TMOBI_Bold.woff2') format('woff2'),
    url('https://poi-cdn.tmap.co.kr/font/TMOBI_Bold.woff') format('woff'),
    url('https://poi-cdn.tmap.co.kr/font/TMOBI_Bold.ttf') format('truetype');
  font-weight: 700;
}

@font-face {
  font-family: 'TMOBI';
  font-display: swap;
  src:
    local('TMOBI_Heavy'),
    url('https://poi-cdn.tmap.co.kr/font/TMOBI_Heavy.woff2') format('woff2'),
    url('https://poi-cdn.tmap.co.kr/font/TMOBI_Heavy.woff') format('woff'),
    url('https://poi-cdn.tmap.co.kr/font/TMOBI_Heavy.ttf') format('truetype');
  font-weight: 800;
}

$font-family:
  'TMOBI',
  'Noto Sans',
  'Roboto',
  -apple-system,
  AppleSDGothicNeo,
  AppleSDGothicNeo-Regular,
  '맑은 고딕',
  'Malgun Gothic',
  '나눔고딕',
  NanumGothic,
  '돋움',
  Dotum,
  '굴림',
  Gulim,
  Helvetica,
  HelveticaNeue,
  sans-serif;

html {
  font-family: $font-family;
}

@mixin tmobi-font-normal($size: 14px, $lineHeight: 1.33) {
  font-weight: 400;
  font-family: $font-family;
  line-height: $lineHeight;
  font-size: $size;
}

@mixin tmobi-font-medium($size: 14px, $lineHeight: 1.33) {
  font-weight: 500;
  font-family: $font-family;
  line-height: $lineHeight;
  font-size: $size;
}

@mixin tmobi-font-bold($size: 14px, $lineHeight: 1.33) {
  font-weight: 700;
  font-family: $font-family;
  line-height: $lineHeight;
  font-size: $size;
}

@mixin tmobi-font-heavy($size: 14px, $lineHeight: 1.33) {
  font-weight: 800;
  font-family: $font-family;
  line-height: $lineHeight;
  font-size: $size;
}

@mixin tmobi-font-normal($size: 14px, $lineHeight: 1.33) {
  font-weight: 400;
  font-family: $font-family;
  line-height: $lineHeight;
  font-size: $size;
}

@mixin tmobi-font-medium($size: 14px, $lineHeight: 1.33) {
  font-weight: 500;
  font-family: $font-family;
  line-height: $lineHeight;
  font-size: $size;
}

@mixin tmobi-font-bold($size: 14px, $lineHeight: 1.33) {
  font-weight: 700;
  font-family: $font-family;
  line-height: $lineHeight;
  font-size: $size;
}

@mixin tmobi-font-heavy($size: 14px, $lineHeight: 1.33) {
  font-weight: 800;
  font-family: $font-family;
  line-height: $lineHeight;
  font-size: $size;
}

@mixin font-size-24-bold() {
  @include tmobi-font-bold(24px, 1.33);
}
@mixin font-size-24-medium() {
  @include tmobi-font-medium(24px, 1.33);
}
@mixin font-size-24() {
  @include tmobi-font-normal(24px, 1.33);
}

@mixin font-size-20-bold() {
  @include tmobi-font-bold(20px, 1.4);
}
@mixin font-size-20-medium() {
  @include tmobi-font-medium(20px, 1.4);
}
@mixin font-size-20() {
  @include tmobi-font-normal(20px, 1.4);
}

@mixin font-size-18-bold() {
  @include tmobi-font-bold(18px, 1.33);
}
@mixin font-size-18-medium() {
  @include tmobi-font-medium(18px, 1.33);
}
@mixin font-size-18() {
  @include tmobi-font-normal(18px, 1.33);
}

@mixin font-size-16-bold() {
  @include tmobi-font-bold(16px, 1.38);
}
@mixin font-size-16-medium() {
  @include tmobi-font-medium(16px, 1.38);
}
@mixin font-size-16() {
  @include tmobi-font-normal(16px, 1.38);
}

@mixin font-size-14-bold() {
  @include tmobi-font-bold(14px, 1.43);
}
@mixin font-size-14-medium() {
  @include tmobi-font-medium(14px, 1.43);
}
@mixin font-size-14() {
  @include tmobi-font-normal(14px, 1.43);
}

@mixin font-size-12-bold() {
  @include tmobi-font-bold(12px, 1.5);
}
@mixin font-size-12-medium() {
  @include tmobi-font-medium(12px, 1.5);
}
@mixin font-size-12() {
  @include tmobi-font-normal(12px, 1.5);
}

@mixin font-size-11-bold() {
  @include tmobi-font-bold(11px, 1.45);
}
@mixin font-size-11-medium() {
  @include tmobi-font-medium(11px, 1.45);
}
@mixin font-size-11() {
  @include tmobi-font-normal(11px, 1.45);
}
