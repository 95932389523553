@charset "UTF-8";

@import '../../@tmds/tmdscolor';
@import '../../@tmds/tmdsfonts';
@import '../../@tmds/variables/color';

@import '../../basics/variables';
@import '../../basics/mixins';
@import '../../basics/functions';
@import '../../basics/extends';
@import '../../basics/keyframes';
@import '../../basics/tmdscolors';
@import '../../basics/publictranscolors';

.result_header {
  display: flex;
  justify-content: space-between;

  height: 50px;
  border-bottom: 1px solid $gray-100;
  padding: 0px 12px 0px 16px;

  &.is_default {
    .collection_wrap {
      width: 220px;

      .item {
        width: 73px;
      }
    }
  }

  .collection_wrap {
    display: flex;
    align-items: center;

    width: 146px;
    height: 36px;

    border-radius: 12px;
    border: 1px solid $gray-100;
    background-color: $gray-50;

    @include font-size-14-bold;
    color: $gray-500;

    .item {
      display: flex;
      align-items: center;
      justify-content: center;

      width: 73px;
      height: 36px;

      i {
        display: none;
      }

      &:first-child {
        margin-left: -1px;
      }

      &:last-child {
        margin-right: -1px;
      }

      &.is_active {
        color: $gray-900;
        border-radius: 12px;
        background-color: $wb-white;
        box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3);
      }
    }
  }
}

body[data-orientation='landscape'] {
  .result_header.hybrid {
    margin-top: 0;
  }
}

@media screen and (orientation: landscape) {
  .result_header {
    &.is_default {
      .collection_wrap {
        width: 190px;
      }
    }
  }
}

@media only screen and (orientation: portrait) and (max-width: 360px) {
  .result_header {
    &.is_default {
      .collection_wrap {
        width: 150px;
      }
    }

    .collection_wrap {
      width: 100px;

      .item {
        width: 50px;

        i {
          display: block;
        }

        .text {
          display: none;
        }
      }
    }
  }
}

@media only screen and (orientation: portrait) and (min-width: 361px) and (max-width: 389px) {
  .result_header {
    &.is_default {
      .collection_wrap {
        width: 190px;
      }
    }

    .collection_wrap {
      width: 126px;

      .item {
        width: 63px;

        i {
          display: none;
        }

        .text {
          display: block;
        }
      }
    }
  }
}
