@charset "UTF-8";

@import '../@tmds/tmdscolor';
@import '../@tmds/tmdsfonts';
@import '../@tmds/variables/color';

@import '../basics/variables';
@import '../basics/mixins';
@import '../basics/functions';
@import '../basics/extends';
@import '../basics/keyframes';
@import '../basics/tmdscolors';
@import '../basics/publictranscolors';

@include keyframeSpin();

.callout_popup {
  position: fixed;
  bottom: 0;
  z-index: $Z-custom-layer;

  width: 100%;
  // skeleton 최소 높이를 위해 지정
  min-height: 144px;

  background: $wb-white;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;

  padding: 20px;
  padding-bottom: calc(env(safe-area-inset-bottom) + 20px);
  filter: drop-shadow(0px -1px 3px $C-wb-transparent-300);

  .tag {
    width: 100%;
    margin-bottom: 4px;
  }

  .current_button {
    position: absolute;
    left: 12px;
    top: -16px;
    transform: translateY(-100%);
  }

  .weather_button {
    left: 12px;
  }

  .poi_info {
    display: flex;
    flex-direction: column;

    padding-top: 4px;
    padding-bottom: 8px;

    gap: 4px;

    &.is_subway {
      gap: 19px;
      padding-bottom: 12px;
    }

    &.is_busstop {
      padding-bottom: 13px;
    }

    .title {
      position: relative;

      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      .title_wrap {
        position: relative;

        display: inline-flex;
        align-items: center;
        width: 100%;
        margin-left: -5px;
        min-width: 0;

        @include font-size-20-bold();
        color: $gray-900;

        &:active {
          .text {
            position: relative;
            &::after {
              @include set-touch-guide();
              border-top-left-radius: 4px;
              border-bottom-left-radius: 4px;
            }
          }
          .icon_right {
            position: relative;
            &::after {
              @include set-touch-guide($top: -4px, $bottom: -4px);
              border-top-right-radius: 4px;
              border-bottom-right-radius: 4px;
            }
          }
        }

        .text {
          @include text-ellipsis;
          margin-left: 5px;
        }
      }

      .refresh_wrap {
        display: flex;
        align-items: center;

        gap: 8px;

        .tmds_tag {
          width: 42px;
          height: 20px;
        }

        .refresh_button {
          position: relative;
          @include touch-guide($borderRadius: 50%);

          display: flex;
          align-items: center;
          justify-content: center;

          width: 28px;
          height: 28px;
          border-radius: 50%;
          border: 1px solid $gray-100;

          &.rotate {
            animation: spin 2s infinite linear;
          }
        }
      }
    }

    .address {
      color: $gray-400;
      @include font-size-14;
      @include text-ellipsis;
    }
  }
}

body[data-orientation='landscape'] {
  .callout_popup {
    width: 360px;
    bottom: 0;
    right: 10px;
  }
}
