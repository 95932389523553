@charset "UTF-8";

@import '../@tmds/tmdscolor';
@import '../@tmds/tmdsfonts';
@import '../@tmds/variables/color';

@import '../basics/variables';
@import '../basics/mixins';
@import '../basics/functions';
@import '../basics/extends';
@import '../basics/keyframes';
@import '../basics/tmdscolors';
@import '../basics/publictranscolors';

.coupon_link {
  @include font-size-14-bold();
  color: $tmobi-blue-600;

  display: inline-flex;
  align-items: center;

  i {
    display: inline-block;
    width: 14px;
    height: 14px;
    @include override-svg($tmobi-blue-600, 14px, 14px);
  }
}
