@charset "UTF-8";

@import '../../@tmds/tmdscolor';
@import '../../@tmds/tmdsfonts';
@import '../../@tmds/variables/color';

@import '../../basics/variables';
@import '../../basics/mixins';
@import '../../basics/functions';
@import '../../basics/extends';
@import '../../basics/keyframes';
@import '../../basics/tmdscolors';
@import '../../basics/publictranscolors';

.header_wrap {
  @include font-size-14();
  display: flex;
  flex-direction: column;

  &.has_filter {
    height: initial;

    .filter_wrap {
      display: flex;
    }
  }

  .filter_menu {
    margin-left: 2px;
  }

  .filter_wrap {
    display: none;
    padding: 8px 16px 12px 16px;
    box-sizing: content-box;
    @include scroll-wrap(x);
    border-bottom: 1px solid $gray-100;
    align-items: center;

    .filter {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      padding: 5px 12px;

      &.has_error {
        color: $gray-400;
        background-color: $gray-50;

        path {
          fill: $gray-400;
        }
      }

      &.menu {
        width: 32px;
        height: 32px;
        padding: 7px;
      }

      &.has_content {
        width: auto;
        height: 32px;
        padding: 7px 10px 7px 6px;
      }

      flex-shrink: 0;
      margin-right: 4px;
      position: relative;
      border: 1px solid $gray-200;
      color: $gray-900;
      border-radius: 20px;
      background-color: $wb-white;

      @include font-size-14();
      @include touch-guide(18px);

      &.on {
        @include font-size-14-bold();
        background-color: $gray-100;
        border-color: $gray-600;
      }

      .text {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        flex: 1;
      }

      &:last-child {
        margin-right: 0px;
      }
    }

    .divider {
      display: inline-flex;
      align-items: center;
      justify-content: center;

      margin-right: 4px;
      @include override-svg($gray-100, 12px, 30px);
    }
  }
}
