@charset "UTF-8";

@import '../@tmds/tmdscolor';
@import '../@tmds/tmdsfonts';
@import '../@tmds/variables/color';

@import '../basics/variables';
@import '../basics/mixins';
@import '../basics/functions';
@import '../basics/extends';
@import '../basics/keyframes';
@import '../basics/tmdscolors';
@import '../basics/publictranscolors';

.wrapper {
  display: flex;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  position: fixed;
  z-index: $Z-custom-layer;

  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  flex-direction: column;

  width: 100%;

  .popup_wrap {
    width: 312px;
    max-width: 312px;
  }

  .content {
    position: relative;
    flex-shrink: 0;
    max-height: 390px;
    padding: 32px 20px;
    text-align: center;
    background-color: $wb-white;
    word-break: keep-all;

    border-top-left-radius: 8px;
    border-top-right-radius: 8px;

    .close {
      position: absolute;
      top: 0;
      right: 0;
      button {
        width: 48px;
        height: 48px;
        svg {
          width: 18px;
          height: 18px;
        }
      }
    }

    .title {
      color: $gray-900;

      @include font-size-18();

      mark {
        color: $tmobi-blue-500;
        background-color: $wb-white;

        b {
          @include font-size-18-bold();
        }
      }
    }

    .detail {
      margin-top: 12px;

      @include font-size-14();
      color: $gray-400;

      mark {
        color: $tmobi-blue-500;
        background-color: $wb-white;
      }
    }
  }

  .actions {
    height: 50px;
    display: flex;
    width: 100%;

    button {
      background-color: $gray-100;
      flex-basis: 100%;
      height: 52px;
      color: $gray-900;
      @include font-size-16();

      &[data-action-type='confirm'] {
        background-color: $tmobi-blue-500;
        color: $wb-white;
      }

      &[data-action-type='warning'] {
        background-color: $red-500;
        color: $wb-white;
      }

      &:first-child {
        border-bottom-left-radius: 8px;
      }

      &:last-child {
        border-bottom-right-radius: 8px;
      }
    }
  }
}
