@charset "UTF-8";

@import '../@tmds/tmdscolor';
@import '../@tmds/tmdsfonts';
@import '../@tmds/variables/color';

@import '../basics/variables';
@import '../basics/mixins';
@import '../basics/functions';
@import '../basics/extends';
@import '../basics/keyframes';
@import '../basics/tmdscolors';
@import '../basics/publictranscolors';

.build_info_wrap {
  position: fixed;
  right: 0;
  bottom: 0;
  background-color: $red-500;
  word-break: break-all;
  z-index: $Z-infinite-top;

  .build_info_inner_box {
    display: none;
  }

  &.view_detail {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $black-alpha-600;

    .build_info_inner_box {
      display: flex;
      flex-direction: column;
      background: white;
      margin: 30px 5px 0;
      height: calc(100% - 80px - 30px);
      border-radius: 5px;
      margin-top: var(--hybrid-top);
      overflow: hidden;
    }
  }
}

.info_wrap {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  background-color: $gray-500;
  height: 100%;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.tab_header {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid $gray-500;
  padding: 0 16px;

  .header_item {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    height: 55px;
    font-size: 16px;
    color: $gray-600;

    &.active {
      color: $tmobi-blue-500;
      font-family: $font-family;
      font-weight: 500;
      position: relative;

      &::after {
        position: absolute;
        content: '';
        left: 0;
        bottom: -1px;
        height: 0px;
        width: 100%;
        border-bottom: 3px solid $tmobi-blue-500;
      }
    }
  }
}

.list_item {
  background-color: white;
  margin: 5px 5px 0;
  padding: 15px;
  border-radius: 4px;

  &.value_data:active {
    background-color: #eee;
  }

  &:last-child {
    border-bottom: 0 none;
  }

  h3 {
    color: $gray-700;
    font-size: 12px;
    padding: 0;
    margin: 0 0 3px 0;
  }

  .data_wrap {
    display: flex;
    flex-direction: column;
    min-height: 12px;
    font-size: 14px;

    &.desc {
      margin: 10px 0px;

      p {
        color: $black-alpha-400;
        font-size: 3px;
      }
    }

    &.performance_data {
      flex-direction: row;
      padding: 5px 0px;

      .timestamp {
        color: $black-alpha-400;
        font-size: 3px;
      }

      :first-child {
        flex-basis: 50%;
      }

      :nth-child(2) {
        flex-basis: 30%;
        flex-grow: 0;
        text-align: center;
      }

      :nth-child(3) {
        font-weight: 900;
        text-align: center;
        flex-grow: 1;
      }
    }
  }

  &.array_data .data_wrap {
    margin-bottom: 2px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &.table_data .data_wrap {
    display: flex;
    flex-direction: column;

    .key_value_data {
      display: flex;
      flex-direction: row;
      border-bottom: 1px solid $gray-100;
      align-items: center;

      &:active {
        background-color: $gray-100;
      }

      &:last-child {
        border: 0 none;
      }
    }

    .table_header {
      color: $tmobi-blue-600;
      padding: 5px 5px 5px 0;
      min-width: 100px;
      width: 100px;
    }

    .table_value {
      word-break: break-all;
      padding: 5px 0;
      overflow: auto;
    }
  }
}

.end_item {
  padding: 50px 0;
  text-align: center;
  color: $gray-100;
  background-color: $wb-white;
  margin: 5px;
  border-radius: 4px;
}

.btn_env {
  color: $red-500;
  border: 1px solid $red-500;
  font-size: 10px;
  padding: 3px;

  position: fixed;

  right: 10px;
  bottom: var(--bottom, 100px);
}

.input_wrap {
  display: flex;
  justify-content: space-between;

  .title {
    min-width: 80px;
  }

  input {
    flex: 1;
    width: 100px;
  }
  button {
    background-color: $gray-200;
    margin-left: 5px;
    padding: 3px 5px;
    border-radius: 3px;
    font-size: 12px;
  }
}

.btn_wrap {
  button {
    width: 100%;
    border: 1px solid $gray-200;
    padding: 10px 5px;
    border-radius: 3px;
    font-size: 12px;
  }

  &.inline_button {
    button {
      width: initial;
    }
  }
}

.origin_input {
  padding: 10px 0px;

  input {
    display: inline-block;
    height: 30px;
    width: 250px;
  }

  span {
    line-height: 30px;
  }
}

.flex_row {
  display: flex;
  flex-direction: row;
}

.webview_cover {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(200, 251, 220, 0.5);
  pointer-events: none;
}

:global(#log_stack) {
  z-index: 9999999;
  position: fixed;
  left: 0;
  bottom: 0;
  color: $wb-white;
  background: $black-alpha-400;
  margin: 10px;
  font-size: 12px;
  max-height: 300px;
  pointer-events: none;
  max-width: 80vw;
  line-break: anywhere;
}
