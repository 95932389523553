@charset "UTF-8";

// # template
// @mixin mixinName($varName: block) {
//   &:before {
//     display: $varName;
//   }
//   display: $varName;
// }

// # use case
// div {
//   @include mixinName(flex);
// }

// # result
// div {
//   &:before {
//     display: flex;
//   }
//   display: flex;
// }

@mixin fade-out-gradient(
  $height: 15px,
  $top: 0,
  $bottom: 0,
  $color: $wb-white,
  $direction: bottom
) {
  content: '';
  position: absolute;
  width: 100%;
  height: $height;
  top: $top;
  bottom: $bottom;
  z-index: $Z-gradient;
  background: linear-gradient(to $direction, $color 0%, $C-white-tr-00 100%);
}

@mixin set-touch-guide(
  $borderRadius: '',
  $top: 0,
  $left: 0,
  $right: 0,
  $bottom: 0,
  $opacity: 0.06,
  $zIndex: ''
) {
  content: ' ';
  pointer-events: none;
  position: absolute;
  top: $top;
  left: $left;
  right: $right;
  bottom: $bottom;
  background-color: $wb-black;
  opacity: $opacity;
  @if $borderRadius != '' {
    border-radius: $borderRadius;
  }
  @if $zIndex != '' {
    z-index: $zIndex;
  }
}

@mixin touch-guide(
  $borderRadius: '',
  $top: 0,
  $left: 0,
  $right: 0,
  $bottom: 0,
  $opacity: 0.06,
  $zIndex: ''
) {
  &:active:after {
    @include set-touch-guide($borderRadius, $top, $left, $right, $bottom, $opacity, $zIndex);
  }
}

@mixin ignore-touch-guide {
  &:active:after {
    display: none;
  }
}

@mixin disabled-button {
  pointer-events: none;
  color: $gray-100;

  g {
    stroke: $gray-100;
  }
}

@mixin line-clamp($line: 2) {
  display: -webkit-box;
  -webkit-line-clamp: $line;
  -webkit-box-orient: vertical;
  word-break: break-all;
  overflow: hidden;
}

@mixin marker-poi-name($color: $gray-900, $fontSize: 14px, $borderColor: $wb-white) {
  .label {
    display: none;
    white-space: nowrap;
    text-shadow:
      -1px 0 $gray-900,
      0 1px $gray-900,
      1px 0 $gray-900,
      0 -1px $gray-900;
    color: $wb-white;
    pointer-events: none;

    &[data-style='DAY'] {
      text-shadow:
        -1px 0 $borderColor,
        0 1px $borderColor,
        1px 0 $borderColor,
        0 -1px $borderColor;
      color: $color;
    }
  }
  .price {
    display: none;
  }

  &[data-type='ACTIVE'],
  &[data-type='ACTIVE_BUS_STATION'],
  &[data-type='ACTIVE_SUBWAY'],
  &[data-type^='ACTIVE_SAVE_POI_'],
  &[data-type='ACTIVE_FAVORITE'],
  &[data-type='ACTIVE_FAVORITE_HOME'],
  &[data-type='ACTIVE_FAVORITE_OFFICE'],
  &[data-type='ACTIVE_FAVORITE_PUBLIC_TRANS'],
  &[data-type='ACTIVE_RECENT_DESTINATION'],
  &[data-type='ACTIVE_RANKING'] {
    .label {
      display: inline-block;
      font-size: $fontSize;
      position: absolute;
      top: 51px;
      font-family: $font-family;
      @include tmobi-font-bold($fontSize);
      margin-top: 2px;
      pointer-events: none;
    }
    .price {
      display: inline-block;
      font-size: 12px;
      font-family: $font-family;
      @include font-size-12-bold();
      margin-top: 2px;
      pointer-events: none;

      white-space: nowrap;
      text-shadow:
        -1px 0 $wb-white,
        0 1px $wb-white,
        1px 0 $wb-white,
        0 -1px $wb-white;
      color: $C-tmobi-pink-600;
      pointer-events: none;
    }
  }

  &[data-type='ACTIVE_RANKING'] {
    .label {
      top: 48px;
    }
  }
}

@mixin override-svg($color, $width: 18px, $height: 18px) {
  svg {
    width: $width;
    height: $height;

    @if $color != '' {
      * {
        fill: $color;
      }
    }
  }
}

@mixin scroll-wrap($direction) {
  @if $direction == x {
    overflow-x: scroll;
  } @else if $direction == y {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;
  white-space: nowrap;

  &::-webkit-scrollbar {
    display: none;
  }
}

@mixin temp-icon($width, $height) {
  display: inline-block;
  width: $width;
  height: $height;
  background-color: $wb-black;
}
@mixin image-holder($width, $height, $bg: $gray-100, $border: $black-alpha-100, $radius: 0px) {
  display: inline-flex;
  position: relative;
  height: $height;
  width: $width;
  background-color: $bg;
  border-radius: $radius;
  align-items: center;
  justify-content: center;

  outline: 1px solid $border;
  outline-offset: -1px;

  img {
    opacity: 0;
    object-fit: cover;
    transition: opacity ease-in 0.3s;
    border-radius: $radius;
    width: 100%;
    height: 100%;

    &.fade_in {
      opacity: 1;
    }
  }
}

@mixin wide-view {
  @media (min-width: 640px) {
    @content;
  }
}

@mixin narrow-view {
  @media (max-width: 320px) {
    @content;
  }
}

@mixin text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin common-banner-style {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: #f5f7f9;
}

@mixin common-banner-layout {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

@mixin place-default-quick-link {
  position: relative;
  display: flex;
  flex-shrink: 0;
  padding: 6px 10px 6px 8px;
  margin: 2px 2px 4px 2px;
  background-color: $wb-white;
  box-shadow: 0 1px 3px 0 $black-alpha-300;

  @include font-size-14();

  border-radius: 17px;
  color: $gray-900;
  align-items: center;

  @include touch-guide($borderRadius: 17px);

  .icon {
    margin-right: 2px;
    display: inline-flex;
    width: 18px;
    height: 18px;
  }

  span {
    position: relative;
    top: 1px;
  }
}

@mixin map-background {
  background-image: url('../../resource/images/map-bg-img-type-1.jpg');
  background-size: contain;

  @media (-webkit-min-device-pixel-ratio: 2) {
    background-image: url('../../resource/images/map-bg-img-type-1@2x.jpg');
  }

  @media (-webkit-min-device-pixel-ratio: 3) {
    background-image: url('../../resource/images/map-bg-img-type-1@3x.jpg');
  }
}

@mixin list-focus {
  &[data-focus='true'] {
    background-color: $tmobi-blue-50;

    [data-action='CONFIRM'] {
      button {
        border: 1px solid $gray-700;
      }
    }
  }
}

@mixin gradient {
  background: linear-gradient(to right, $C-wb-transparent-300, $black-alpha-50);
}

@mixin infiniteBlink {
  animation: blink 2s ease-in-out infinite;
}
