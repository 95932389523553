@charset "UTF-8";

@import '../@tmds/tmdscolor';
@import '../@tmds/tmdsfonts';
@import '../@tmds/variables/color';

@import '../basics/variables';
@import '../basics/mixins';
@import '../basics/functions';
@import '../basics/extends';
@import '../basics/keyframes';
@import '../basics/tmdscolors';
@import '../basics/publictranscolors';

.wrap {
  padding: 100px 0px;

  h3 {
    @include font-size-16-bold();
    padding-bottom: 5px;
  }

  h4 {
    @include font-size-14-bold();
    padding-top: 5px;
  }

  p {
    @include font-size-12();
    padding: 2px 0px;

    button {
      border: 1px solid $gray-500;
      padding: 3px;
      border-radius: 3px;
    }
  }

  li {
    border-bottom: 1px solid $gray-200;
    line-break: anywhere;
    padding: 10px;
  }
}
