@charset "UTF-8";

@import '../../@tmds/tmdscolor';
@import '../../@tmds/tmdsfonts';
@import '../../@tmds/variables/color';

@import '../../basics/variables';
@import '../../basics/mixins';
@import '../../basics/functions';
@import '../../basics/extends';
@import '../../basics/keyframes';
@import '../../basics/tmdscolors';
@import '../../basics/publictranscolors';

.sort_wrap {
  display: flex;
  gap: 4px;
  margin-top: 9px;

  .sort_item {
    position: relative;
    @include touch-guide(
      $borderRadius: 4px,
      $left: -3.5px,
      $right: -3.5px,
      $bottom: -5px,
      $top: -5px
    );

    display: flex;
    align-items: center;
    gap: 2px;
    height: 18px;

    color: $gray-600;
    @include font-size-12();

    outline: none;
    border: none;

    .label {
      text-wrap: nowrap;
    }
  }
}
