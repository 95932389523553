@charset "UTF-8";

@import '../../@tmds/tmdscolor';
@import '../../@tmds/tmdsfonts';
@import '../../@tmds/variables/color';

@import '../../basics/variables';
@import '../../basics/mixins';
@import '../../basics/functions';
@import '../../basics/extends';
@import '../../basics/keyframes';
@import '../../basics/tmdscolors';
@import '../../basics/publictranscolors';

.cate_item {
  margin: 0 4px 0 0;
  padding: 3px 12px;
  border-radius: 15px;
  position: relative;
  background-color: $gray-50;
  color: $gray-300;
  height: 28px;
  @include font-size-14-bold();

  &:active {
    color: $gray-300;
  }

  &.active {
    background-color: $tmobi-blue-100;
    color: $tmobi-blue-500;

    &:active {
      color: $wb-white;
    }
  }

  @include touch-guide(15px);

  &:last-child {
    margin-right: 0;
  }
}
