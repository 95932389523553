@charset "UTF-8";

@import '../../@tmds/tmdscolor';
@import '../../@tmds/tmdsfonts';
@import '../../@tmds/variables/color';

@import '../../basics/variables';
@import '../../basics/mixins';
@import '../../basics/functions';
@import '../../basics/extends';
@import '../../basics/keyframes';
@import '../../basics/tmdscolors';
@import '../../basics/publictranscolors';

.wrap {
  position: relative;
  display: inline-flex;
  z-index: $Z-tooltip-popup;
}

.icon_wrap {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
}

.tooltip_wrap {
  position: absolute;
  top: 30px;
  white-space: nowrap;
}

.link {
  display: flex;
  align-items: center;
  color: $tmobi-blue-600;
}
