@charset "UTF-8";

@import '../@tmds/tmdscolor';
@import '../@tmds/tmdsfonts';
@import '../@tmds/variables/color';

@import '../basics/variables';
@import '../basics/mixins';
@import '../basics/functions';
@import '../basics/extends';
@import '../basics/keyframes';
@import '../basics/tmdscolors';
@import '../basics/publictranscolors';

.asum_banner {
  @include common-banner-style;
  height: 56px;

  opacity: 0;
  position: absolute;
  left: -1000px;

  &.show {
    position: static;
    opacity: 1;
  }

  iframe {
    height: 56px !important;
    background-color: $gray-100 !important;
  }
}
