@charset "UTF-8";

@import '../../@tmds/tmdscolor';
@import '../../@tmds/tmdsfonts';
@import '../../@tmds/variables/color';

@import '../../basics/variables';
@import '../../basics/mixins';
@import '../../basics/functions';
@import '../../basics/extends';
@import '../../basics/keyframes';
@import '../../basics/tmdscolors';
@import '../../basics/publictranscolors';

.favorite_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 12px 20px 12px 18px;
  border-bottom: 8px solid $gray-50;

  &.no_result {
    border-bottom: 0 none;
  }

  .tab_header_wrap {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .tab_item {
    @include font-size-14-bold();

    width: 50px;
    height: 28px;
    border-radius: 15px;
    border: solid 1px $tmobi-blue-400;
    background-color: $wb-white;
    color: $tmobi-blue-500;
    margin-right: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    @include touch-guide(15px);

    &.active {
      border: solid 1px $tmobi-blue-500;
      background-color: $tmobi-blue-500;
      color: $wb-white;
    }
  }

  .is_ios {
    .tab_item {
      padding-top: 1px;
    }
  }

  .sort_wrap {
    display: flex;
    align-items: center;
    color: $gray-600;
    position: relative;
    outline: none;
    border: none;

    @include touch-guide(5px, $left: -3px, $right: -4px, $bottom: -2px, $top: -1px);

    svg {
      width: 18px;
      height: 18px;

      path {
        fill: $gray-600;
      }
    }

    .text {
      margin-left: 2px;
      @include font-size-12();
    }

    &.none_click {
      opacity: 0.6;
    }
  }
}
