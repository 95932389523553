@charset "UTF-8";

@import '../../@tmds/tmdscolor';
@import '../../@tmds/tmdsfonts';
@import '../../@tmds/variables/color';

@import '../../basics/variables';
@import '../../basics/mixins';
@import '../../basics/functions';
@import '../../basics/extends';
@import '../../basics/keyframes';
@import '../../basics/tmdscolors';
@import '../../basics/publictranscolors';

.wrap {
  position: relative;
}

.more_checker {
  height: 1px;
  width: 100%;
}

.list {
  @include touch-guide($borderRadius: 4px);
  position: relative;
  border-bottom: 1px solid $gray-100;
  padding: 18px 0px 20px 0;
  @include list-focus;
  background-color: $wb-white;
}

.keyword_banner_wrap {
  border-bottom: 1px solid $gray-100;
}

.no_result_button {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;

  .icon {
    display: flex;
    justify-content: center;
    align-items: center;

    path {
      fill: $wb-white;
    }
  }
}

.regist_new_place {
  @include touch-guide($borderRadius: 4px);
  width: 100%;
  height: 68px;
  position: absolute;
  border-bottom: 1px solid $gray-100;
  background-color: $wb-white;

  .btn_regist {
    position: relative;
    display: flex;
    align-items: center;
    margin: 24px auto 24px 20px;

    @include touch-guide(5px);
    i {
      display: inline-flex;
      align-items: center;
    }

    .notice {
      @include override-svg($tmobi-blue-600, 18px, 18px);
    }
    .landing {
      @include override-svg($tmobi-blue-600, 14px, 14px);
    }

    .text {
      @include font-size-14-bold();
      padding-left: 4px;
      color: $tmobi-blue-600;
    }
  }
}
