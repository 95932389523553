@charset "UTF-8";

@import '../@tmds/tmdscolor';
@import '../@tmds/tmdsfonts';
@import '../@tmds/variables/color';

@import '../basics/variables';
@import '../basics/mixins';
@import '../basics/functions';
@import '../basics/extends';
@import '../basics/keyframes';
@import '../basics/tmdscolors';
@import '../basics/publictranscolors';

.wrap {
  display: flex;
  align-items: center;
  gap: 6px;
  margin-top: 24px;

  button {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 16px;
    gap: 4px;
    flex: 1;

    color: $tmobi-blue-500;
    border: 1px solid $gray-300;
    border-radius: 8px;

    @include font-size-14-bold();
    @include touch-guide(8px);

    [data-focus='true'] & {
      &:not(.inactive) {
        border-color: $tmobi-blue-500;
      }
      background-color: $wb-white;
    }
  }

  .waiting_btn {
    flex: 2;

    &.inactive {
      color: $gray-300;
    }
  }

  .content_wrap {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .reason {
    color: $gray-600;
    @include font-size-11();
  }
}
