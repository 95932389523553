@charset "UTF-8";

@import '../@tmds/tmdscolor';
@import '../@tmds/tmdsfonts';
@import '../@tmds/variables/color';

@import '../basics/variables';
@import '../basics/mixins';
@import '../basics/functions';
@import '../basics/extends';
@import '../basics/keyframes';
@import '../basics/tmdscolors';
@import '../basics/publictranscolors';

.debug_info {
  position: absolute;
  left: 10px;
  top: 2px;
  font-size: 8px;
  display: inline-flex;
  background-color: $C-wb-transparent-600;
  color: $wb-white;
  z-index: $Z-banner-debug;

  .debug_box {
    border: 1px solid $C-tmobi-pink-300;

    &:not(:last-child) {
      border-right: 0 none;
    }

    div {
      padding: 0 3px;
      width: 60px;
      display: flex;
      justify-content: space-between;

      &:not(:last-child) {
        border-bottom: 1px solid $C-tmobi-pink-300;
      }

      .global_time {
        color: $tmobi-blue-400;
      }
    }

    &.header {
      div {
        width: 50px;
      }
    }
  }
}

.reload_btn {
  position: absolute;
  left: 10px;
  top: 81px;
  background-color: $C-wb-transparent-600;
  font-size: 8px;
  padding: 7px 7px;
  border: 1px solid $gray-100;
  z-index: $Z-banner-debug;
}

.order_btn {
  position: absolute;
  right: 10px;
  top: 81px;
  background-color: $C-wb-transparent-600;
  font-size: 8px;
  padding: 7px 7px;
  border: 1px solid $gray-100;
  z-index: $Z-banner-debug;
}

.order_info {
  position: absolute;
  left: 0px;
  right: 0px;
  top: calc(100% + 10px);
  padding: 20px;
  background-color: $black-alpha-600;
  z-index: $Z-banner-debug + 1;
  font-size: 10px;

  .close {
    @include font-size-18-bold();
    position: absolute;
    right: 20px;
    top: 20px;
  }

  h4 {
    @include font-size-12-bold();
    background-color: $wb-white;
    padding: 3px;
    margin: 0;
    color: $gray-900;
  }

  .step {
    margin: 0;
    background-color: $wb-white;
    padding: 5px 10px;

    input[type='checkbox'] {
      margin-right: 10px;
    }
    button {
      margin-top: 3px;
      background-color: $C-wb-transparent-600;
      color: $wb-white;
      font-size: 8px;
      padding: 5px;
      border: 1px solid $gray-400;
    }
  }
}
