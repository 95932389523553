@charset "UTF-8";

@import '../../@tmds/tmdscolor';
@import '../../@tmds/tmdsfonts';
@import '../../@tmds/variables/color';

@import '../../basics/variables';
@import '../../basics/mixins';
@import '../../basics/functions';
@import '../../basics/extends';
@import '../../basics/keyframes';
@import '../../basics/tmdscolors';
@import '../../basics/publictranscolors';

.wrap {
  display: flex;
  gap: 0 8px;
  margin: 16px 16px 0;

  // 세로형
  &.vertical {
    display: block;
    gap: normal;
    margin: 0;
  }
}

.cell {
  flex: 1;

  // 세로형
  .vertical & {
    flex: initial;
  }
}

.item {
  @include touch-guide(8px);
  position: relative;
  width: 100%;
  text-align: left;

  .thumbnail {
    border-radius: 8px;
  }

  .info {
    display: flex;
    flex-direction: column;
    margin-top: 8px;

    .location {
      @include tmobi-font-medium(12px, 16px);
      color: $gray-600;
    }
    .name {
      @include tmobi-font-medium(14px, 20px);
      overflow: hidden;
      display: -webkit-box;
      margin-top: 4px;
      word-break: break-all;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      color: $gray-900;
    }
    .date {
      @include tmobi-font-medium(12px, 16px);
      margin-top: 6px;
      color: $gray-400;

      .dot {
        display: none;
      }
    }
  }
  .ico {
    display: none;
  }

  // 세로형
  .vertical & {
    @include touch-guide;
    position: relative;
    display: flex;
    align-items: center;
    gap: 16px;
    padding: 16px;
    border-bottom: 1px solid $gray-100;

    .thumbnail {
      border-radius: 6px;
    }

    .info {
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      flex: 1;
      margin-top: initial;

      .name {
        @include tmobi-font-bold(16px, 24px);
        width: 100%;
        margin-top: initial;
        margin-bottom: 4px;
        -webkit-line-clamp: 1;
        order: 1;
        color: $gray-900;
      }
      .date {
        @include tmobi-font-medium(12px, 18px);
        width: 100%;
        margin-top: initial;
        margin-bottom: 4px;
        order: 2;
        color: $gray-900;
      }
      .location {
        @include tmobi-font-medium(13px, 18px);
        order: 3;
        margin-top: initial;
        color: $gray-500;
      }
      .going_count {
        @include tmobi-font-medium(13px, 18px);
        display: flex;
        align-items: center;
        order: 4;
        margin-top: initial;
        color: $tmobi-blue-400;

        .dot {
          display: inline-flex;
          width: 12px;
          height: 12px;
        }

        & > strong {
          @include tmobi-font-bold(13px, 18px);
        }
      }
    }
    .ico {
      display: inline-flex;
    }
  }
}

.no_data {
  @include font-size-18-bold;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: $wb-white;
  color: $gray-400;
}
