@charset "UTF-8";

@import '../@tmds/tmdscolor';
@import '../@tmds/tmdsfonts';
@import '../@tmds/variables/color';

@import '../basics/variables';
@import '../basics/mixins';
@import '../basics/functions';
@import '../basics/extends';
@import '../basics/keyframes';
@import '../basics/tmdscolors';
@import '../basics/publictranscolors';

$S-quick-link-list-padding-right: 16px;

.result_page_wrap {
  height: 100%;
  background-color: $wb-white;
  padding-bottom: env(safe-area-inset-bottom);

  &.hide_page {
    position: absolute;
    width: 100%;
    height: 100%;
    transform: translateX(-200vw);
  }
  .ad_loading {
    height: 56px;

    &.is_hide {
      display: none;
    }
  }
}

.header {
  z-index: $Z-header;
  width: 100%;
  position: fixed;
  top: 0;

  .top_actions {
    position: absolute;
    display: inline-block;
    top: 0;
    left: 0;
    width: 100%;

    .vsm_compass {
      position: absolute;
      left: 12px;
      top: calc(100% + 12px);
    }
  }

  .title {
    color: $gray-900;
    @include font-size-18-bold();
  }
}

.place_link_list {
  padding: 0px $S-quick-link-list-padding-right 0px 14px;

  display: flex;
  @include scroll-wrap(x);

  .item {
    @include place-default-quick-link;
  }
}

.focus_label {
  position: fixed;
  right: 200vw;
}

body[data-orientation='landscape'] {
  .header {
    .place_link_list {
      padding-right: calc(
        #{$S-landscape-drawer-width} + #{$S-landscape-drawer-margin-right} + #{$S-quick-link-list-padding-right}
      );
    }
  }
}

.sort_wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 16px 12px 20px; // 1px SearchResultSorPoi margin-top
  border-bottom: 1px solid $gray-100;

  .sort_item {
    margin-top: 9px;
  }

  .tooltip {
    left: -4px;
  }
}
