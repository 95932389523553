@charset "UTF-8";

@import '../../@tmds/tmdscolor';
@import '../../@tmds/tmdsfonts';
@import '../../@tmds/variables/color';

@import '../../basics/variables';
@import '../../basics/mixins';
@import '../../basics/functions';
@import '../../basics/extends';
@import '../../basics/keyframes';
@import '../../basics/tmdscolors';
@import '../../basics/publictranscolors';

.map_wrap {
  flex: 1;
  position: relative;
  z-index: $Z-map;
  width: 100%;
  height: 100%;
}

.current_position {
  position: absolute;
  left: 8px;
}
