@charset "UTF-8";

@import '../../@tmds/tmdscolor';
@import '../../@tmds/tmdsfonts';
@import '../../@tmds/variables/color';

@import '../../basics/variables';
@import '../../basics/mixins';
@import '../../basics/functions';
@import '../../basics/extends';
@import '../../basics/keyframes';
@import '../../basics/tmdscolors';
@import '../../basics/publictranscolors';

.tab {
  display: flex;
  align-items: center;
  gap: 4px;

  color: $gray-300;
  @include font-size-14-bold;

  .item {
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;

    gap: 4px;
    height: 20px;

    @include touch-guide(4px, $top: -4px, $bottom: -4px, $left: -12px, $right: -12px);
  }

  &.no_gap {
    gap: 0;

    .item {
      @include touch-guide(4px, $top: -4px, $bottom: -4px, $left: -12px, $right: -20px);
    }
  }

  &.has_data {
    color: $gray-800;

    .icon {
      @include override-svg($gray-800, 16px, 16px);
    }
  }

  .icon {
    width: 16px;
    height: 16px;
  }
}
