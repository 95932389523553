@charset "UTF-8";

@import '../@tmds/tmdscolor';
@import '../@tmds/tmdsfonts';
@import '../@tmds/variables/color';

@import '../basics/variables';
@import '../basics/mixins';
@import '../basics/functions';
@import '../basics/extends';
@import '../basics/keyframes';
@import '../basics/tmdscolors';
@import '../basics/publictranscolors';

.current_position_search {
  display: flex;
  justify-content: center;
  position: absolute;
  transform: translateX(calc(50vw - 50%)) translateY(-100%);
  padding-bottom: 16px;
  max-width: calc(100vw - 122px);
  z-index: $Z-refresh-button;

  .action_button {
    white-space: nowrap;
    width: 100%;
    display: flex;

    .static_label {
      @include override-svg($tmobi-blue-500, 20px, 20px);
      display: inline-flex;
      align-items: center;
      text-overflow: ellipsis;
      overflow: hidden;

      span {
        margin-left: 4px;
      }
    }

    .region {
      @include font-size-14-bold();
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .guide {
      color: $gray-900;
      flex-shrink: 0;
    }
  }
}

body[data-orientation='landscape'] {
  .current_position_search {
    transform: translateX(-50%) translateY(-100%);
    // left: calc((100vw - 449px) / 2 + 68px);
    left: calc((100vw - 372px) / 2);
    max-width: calc(100vw - 449px); // 370 + 46 + 11*2 + 11 = 449
  }
}
