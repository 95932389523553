@charset "UTF-8";

@import '../../@tmds/tmdscolor';
@import '../../@tmds/tmdsfonts';
@import '../../@tmds/variables/color';

@import '../../basics/variables';
@import '../../basics/mixins';
@import '../../basics/functions';
@import '../../basics/extends';
@import '../../basics/keyframes';
@import '../../basics/tmdscolors';
@import '../../basics/publictranscolors';

.search_box {
  width: 100%;
  background-color: $wb-white;
  padding: 10px 12px;
  display: flex;
  flex-direction: row;

  .form_wrap {
    flex: 1;
    position: relative;
    border: 1px solid $gray-100;
    height: 48px;
    border-radius: 24px;
    display: flex;
    flex-direction: row;
    align-items: center;

    input {
      outline: none;
    }
  }

  .btn_back {
    margin-left: 6px;
    min-width: 40px;
  }

  input {
    flex: 1;
    font-size: 17px;
    border: 0 none;
    margin-left: 10px;
    color: $wb-black;
    width: 10px;
    padding: 0 0 2px;
    height: 40px;
    line-height: 1.4;
    text-overflow: ellipsis;
    caret-color: $tmobi-blue-500;

    &::placeholder {
      color: $gray-400;
    }

    &:last-child {
      margin-right: 11px;
    }
  }

  .btn_remove {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    .remove_icon {
      min-width: 20px;
    }
  }

  .btn_search {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    margin-right: 6px;

    .search_icon {
      min-width: 40px;
    }
  }
}
