@charset "UTF-8";

@import '../@tmds/tmdscolor';
@import '../@tmds/tmdsfonts';
@import '../@tmds/variables/color';

@import '../basics/variables';
@import '../basics/mixins';
@import '../basics/functions';
@import '../basics/extends';
@import '../basics/keyframes';
@import '../basics/tmdscolors';
@import '../basics/publictranscolors';

.item {
  display: flex;
  justify-content: space-between;
  gap: 3px;

  .title {
    flex: 1;
    color: $gray-500;
    @include text-ellipsis;

    &.is_close {
      @include font-size-14-bold();
      color: $gray-300;
    }
  }

  .time {
    color: $gray-900;
    max-width: 60%;
    @include text-ellipsis;
  }

  .red {
    color: $red-500;
  }

  .blue {
    color: $tmobi-blue-500;
  }
}
