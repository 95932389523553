@charset "UTF-8";

@import '../../@tmds/tmdscolor';
@import '../../@tmds/tmdsfonts';
@import '../../@tmds/variables/color';

@import '../../basics/variables';
@import '../../basics/mixins';
@import '../../basics/functions';
@import '../../basics/extends';
@import '../../basics/keyframes';
@import '../../basics/tmdscolors';
@import '../../basics/publictranscolors';

.wrap {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  .order {
    @include font-size-16-bold();
    color: $gray-400;
    padding: 16px 0px 0px 20px;

    margin-right: 12px;

    span {
      display: inline-block;
      width: 24px;
      height: 24px;
      text-align: center;
    }
  }

  .main {
    flex-grow: 1;
    padding: 16px 0px 16px 0px;

    .name {
      @include font-size-16-bold();
      color: $gray-700;
    }

    .special_info {
      flex-shrink: 0;
      margin-top: 2px;
    }

    .special_action {
      margin-top: 8px;

      .tag_list {
        display: inline-flex;
        flex-wrap: wrap;

        &:has(~ .coupon) {
          margin-right: 6px;
        }

        .tag {
          display: inline-flex;
          height: 18px;
          flex-shrink: 0;
          margin-left: 2px;

          &:first-child {
            margin: 0;
          }
        }
      }
    }
  }

  .button_wrap {
    margin-right: 16px;

    .route {
      display: flex;
      justify-content: center;
      align-items: center;

      width: 36px;
      height: 36px;
      padding: 9px;
      margin-top: 10px;

      border-radius: 50%;
      border: 1px solid $gray-200;
      background-color: $wb-white;

      [data-focus='true'] & {
        border: 1px solid $tmobi-blue-400;
      }
    }

    .confirm {
      @include font-size-14-bold;

      padding: 8px 16px;
      margin-top: 8px;

      border: 1px solid $gray-200;
      background-color: $wb-white;
      border-radius: 20px;

      text-wrap: nowrap;

      [data-focus='true'] & {
        border: 1px solid $tmobi-blue-800;
      }
    }
  }
}
