@charset "UTF-8";

@import '../@tmds/tmdscolor';
@import '../@tmds/tmdsfonts';
@import '../@tmds/variables/color';

@import '../basics/variables';
@import '../basics/mixins';
@import '../basics/functions';
@import '../basics/extends';
@import '../basics/keyframes';
@import '../basics/tmdscolors';
@import '../basics/publictranscolors';

.keyword_wrap {
  display: flex;
  flex-direction: row;

  width: 100%;
  background-color: $wb-white;
  height: 148px;
  padding: 16px 16px 16px 20px;

  &.visible {
    visibility: hidden;
  }
}

.default_wrap {
  width: 100%;
  position: relative;

  .default_bar {
    background: linear-gradient(90deg, $C-wb-transparent-300 0%, $black-alpha-50 100%);

    &.title {
      width: 120px;
      height: 24px;
      margin-bottom: 12px;
    }

    &.description {
      width: 280px;
      height: 20px;
      margin-bottom: 4px;
    }

    &.event {
      width: 280px;
      height: 20px;
      margin-bottom: 12px;
    }

    &.url {
      width: 189px;
      height: 16px;
    }
  }
}

.contents_wrap {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
  position: relative;

  .title {
    width: 100%;
    color: $tmobi-blue-800;
    @include line-clamp(1);
    @include font-size-16-bold();
    margin-bottom: 4px;
  }

  .description {
    font-size: 14px;
    line-height: 18px;

    width: 100%;
    left: 0;
    top: 47px;
    color: $gray-500;
    @include font-size-14();
    @include line-clamp();
    margin-bottom: 2px;
  }

  .event {
    span {
      position: relative;
      color: $tmobi-pink-700;
      @include tmobi-font-normal(13px);
      @include line-clamp(1);
    }
  }

  .url_wrap {
    display: flex;
    align-items: center;
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    margin-top: 10px;
    gap: 4px;

    .url {
      color: $C-ad-text;
      @include font-size-12();
      @include line-clamp(1);
    }
  }
}

.right_wrap {
  min-width: 2px;

  .image_box {
    position: relative;
    height: 60px;
    width: 60px;
    background-color: $gray-50;
    border-radius: 2px;
    margin-top: 2px;
    margin-left: 8px;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border: 1px solid $black-alpha-50;
      border-radius: 2px;
    }

    img {
      opacity: 0;
      object-fit: cover;
      transition: opacity ease-in 1s;
      width: 100%;
      height: 100%;
      border-radius: 2px;

      &.fade_in {
        opacity: 1;
      }
    }
  }
}
