@charset "UTF-8";

@import '../../@tmds/tmdscolor';
@import '../../@tmds/tmdsfonts';
@import '../../@tmds/variables/color';

@import '../../basics/variables';
@import '../../basics/mixins';
@import '../../basics/functions';
@import '../../basics/extends';
@import '../../basics/keyframes';
@import '../../basics/tmdscolors';
@import '../../basics/publictranscolors';

.wrap {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;

  &.is_detail_view {
    .address_list {
      opacity: 0;
      display: none;
    }
    .detail_wrap {
      opacity: 1;
      top: 0;
    }
  }

  .header {
    border-bottom: 1px solid $gray-100;
  }

  .filter {
    background-color: $gray-50;
    border-bottom: 1px solid $gray-100;

    .depth {
      height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $wb-white;
      padding: 18px;

      @include override-svg($gray-300, 16px, 16px);

      svg {
        margin: 0px 8px;
      }

      .label {
        @include touch-guide(
          $borderRadius: 4px,
          $left: -10px,
          $right: -10px,
          $top: -8px,
          $bottom: -8px
        );
        position: relative;
        height: 24px;

        @include font-size-16();
        color: $gray-300;

        &[data-select='true'] {
          color: $tmobi-blue-500;
          @include font-size-18-bold();
        }
      }
    }

    .road {
      @include override-svg($gray-300, 12px, 12px);
      border-top: 1px solid $gray-100;
      padding: 9px 20px;
      display: flex;
      align-items: center;
      background-color: $gray-50;

      svg {
        margin: 0px 4px;
      }

      button {
        @include font-size-12();
        color: $gray-500;

        &[data-select='true'] {
          @include font-size-12-bold();
          color: $gray-900;
        }
      }
    }
  }

  .address_list {
    overflow-y: scroll;

    .item {
      @include font-size-16();
      @include touch-guide($borderRadius: 4px);
      position: relative;
      padding: 14px 20px;
      border-bottom: 1px solid $gray-100;
      word-break: keep-all;
    }

    &.tile_view {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      overflow-y: scroll;

      .item {
        @include font-size-16();
        @include touch-guide($borderRadius: 4px);

        position: relative;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        height: 64px;
        color: $gray-900;
        border-right: 1px solid $gray-100;
      }
    }
  }

  .detail_wrap {
    position: relative;
    opacity: 0;
    top: -100vh;

    .detail {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      padding: 60px 40px;

      .label {
        @include font-size-14();
        color: $gray-400;
      }

      .input_wrap {
        display: flex;
        position: relative;

        &::after {
          content: ' ';
          position: absolute;
          bottom: 0px;
          height: 2px;
          width: 100%;
          background-color: $gray-200;
        }

        .action {
          position: relative;
          display: flex;
          align-items: center;
          width: 100%;

          input {
            @include font-size-18();
            color: $gray-900;
            padding: 14px 0px;
            border: none;
            caret-color: $tmobi-blue-500;
            width: 100%;

            &.has_content {
              @include font-size-18-bold();
            }

            &::placeholder {
              color: $gray-300;
            }
          }

          button {
            @include override-svg($gray-300, 22px, 22px);
            display: inline-flex;
            height: 22px;
            width: 22px;
            flex-shrink: 0;
          }
        }
      }
      .search_btn {
        display: flex;
        justify-content: center;

        button {
          @include font-size-18();
          width: 100px;
          height: 48px;
          margin-top: 24px;
          border-radius: 11px;
          color: $wb-white;
          background: $tmobi-blue-500;
        }
      }
    }
  }

  &.detail_landscape {
    .header {
      display: none;
    }
    .filter {
      display: none;
    }
    .detail {
      padding: 24px 40px;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
    }
  }
}
