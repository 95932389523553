@charset "UTF-8";

@import '../../@tmds/tmdscolor';
@import '../../@tmds/tmdsfonts';
@import '../../@tmds/variables/color';

@import '../../basics/variables';
@import '../../basics/mixins';
@import '../../basics/functions';
@import '../../basics/extends';
@import '../../basics/keyframes';
@import '../../basics/tmdscolors';
@import '../../basics/publictranscolors';

.wrap {
  position: relative;

  + .wrap {
    border-top: 1px solid $gray-100;
  }
}

.item {
  display: flex;
  align-items: center;
  padding: 14px 20px;
  padding-right: 0;

  @include touch-guide($borderRadius: 4px, $bottom: -1px);

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 20px;
    height: 20px;
    margin-right: 12px;
  }

  .meta {
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: space-between;

    overflow: hidden;
    padding-right: 44px;

    .name {
      @include tmobi-font-normal(17px, 24px);
      @include text-ellipsis();
      color: $text-basic-default;
      margin-right: 12px;
    }
    .date {
      @include font-size-12-medium();
      color: $text-basic-caption;
      flex-basis: 32px;
      flex-shrink: 0;
    }
  }
}

.delete {
  @include touch-guide(4px);
  @include override-svg($gray-300, 16px, 16px);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  right: 8px;
  transform: translateY(-50%);
  width: 32px;
  height: 32px;
}
