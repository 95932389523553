@charset "UTF-8";

@import '../../@tmds/tmdscolor';
@import '../../@tmds/tmdsfonts';
@import '../../@tmds/variables/color';

@import '../../basics/variables';
@import '../../basics/mixins';
@import '../../basics/functions';
@import '../../basics/extends';
@import '../../basics/keyframes';
@import '../../basics/tmdscolors';
@import '../../basics/publictranscolors';

.item_wrap {
  display: flex;
  flex-direction: row;
  padding: 16px 18px 16px 20px;
  border-bottom: 1px solid $gray-200;
  align-items: center;
}

.contents_wrap {
  flex: 1;
  align-items: center;
  @include line-clamp();

  .name {
    font-size: 16px;
    line-height: 1.3;
    font-family: $font-family;
    font-weight: 500;
    color: $wb-black;
  }
}

.btn_wrap {
  display: flex;
  align-items: center;
  margin: auto 0;
  margin-left: 8px;

  .btn_detail {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 54px;
    height: 28px;
    border-radius: 14px;
    border: 1px solid $gray-200;
    font-size: 13px;
    color: $gray-500;
    background-color: $wb-white;
  }
}
