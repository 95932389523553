@charset "UTF-8";

@import '../@tmds/tmdscolor';
@import '../@tmds/tmdsfonts';
@import '../@tmds/variables/color';

@import '../basics/variables';
@import '../basics/mixins';
@import '../basics/functions';
@import '../basics/extends';
@import '../basics/keyframes';
@import '../basics/tmdscolors';
@import '../basics/publictranscolors';

.wrap {
  display: flex;
  width: 100%;

  @include font-size-14();

  .list {
    display: flex;
    flex-direction: column;
    // 13 = .bar margin + width
    width: calc(50% - 13px);
    gap: 4px;
  }

  .bar {
    background-color: $gray-100;
    width: 1px;
    height: 44px;
    margin: 0 12px;
  }
}
