@charset "UTF-8";

@import '../@tmds/tmdscolor';
@import '../@tmds/tmdsfonts';
@import '../@tmds/variables/color';

@import '../basics/variables';
@import '../basics/mixins';
@import '../basics/functions';
@import '../basics/extends';
@import '../basics/keyframes';
@import '../basics/tmdscolors';
@import '../basics/publictranscolors';

.wrap {
  position: relative;

  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  height: 40px;

  .left_buttons {
    display: flex;
    align-items: center;
    gap: 12px;
  }

  .right_buttons {
    display: flex;
    align-items: center;
    gap: 8px;

    .action_button {
      position: relative;

      width: 34px;
      height: 24px;

      @include font-size-18-bold();
      color: $tmobi-blue-500;

      &:not([disabled]) {
        @include touch-guide(
          $borderRadius: 4px,
          $top: -4px,
          $left: -13px,
          $right: -13px,
          $bottom: -4px
        );
      }

      &[disabled] {
        color: $gray-300;
      }
    }
  }
}
