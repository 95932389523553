@charset "UTF-8";

@import '@tmds/tmdscolor';
@import '@tmds/tmdsfonts';
@import '@tmds/variables/color';

@import 'basics/variables';
@import 'basics/mixins';
@import 'basics/functions';
@import 'basics/extends';
@import 'basics/keyframes';
@import 'basics/tmdscolors';
@import 'basics/publictranscolors';

@import './basics/index';
@import './common/index';

html,
body,
#root {
  width: 100%;
  height: 100%;
}

* {
  box-sizing: border-box;
}

button,
input {
  outline: none;
}

a,
a:hover,
a:visited,
a:active {
  color: inherit;
  text-decoration: none;

  // 롱프레스 이슈 및 복사 방지
  // https://tmobi.atlassian.net/browse/CLIENTQA-5004
  -webkit-touch-callout: none; /* iOS의 길게 누르기 메뉴 방지 */
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE/Edge */
  user-select: none; /* 표준 */
  -webkit-tap-highlight-color: transparent; /* 탭 시 하이라이트 제거 */

  *,
  span {
    -webkit-touch-callout: none; /* iOS의 길게 누르기 메뉴 방지 */
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE/Edge */
    user-select: none; /* 표준 */
    -webkit-tap-highlight-color: transparent; /* 탭 시 하이라이트 제거 */
  }
}

:root {
  --sab: env(safe-area-inset-bottom);
}
