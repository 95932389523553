@charset "UTF-8";

@import '../../@tmds/tmdscolor';
@import '../../@tmds/tmdsfonts';
@import '../../@tmds/variables/color';

@import '../../basics/variables';
@import '../../basics/mixins';
@import '../../basics/functions';
@import '../../basics/extends';
@import '../../basics/keyframes';
@import '../../basics/tmdscolors';
@import '../../basics/publictranscolors';

@include keyframeBlink();

.skeleton {
  position: relative;
  height: 76px;
  margin: 24px 0 0 24px;

  .list {
    position: absolute;
    top: 0;
    left: 0;

    .item {
      @include gradient;
      @include infiniteBlink;
      display: inline-block;

      width: 80px;
      height: 32px;
      border-radius: 24px;
      margin-right: 6px;
      vertical-align: top;
    }
  }
}

.wrap {
  @include scroll-wrap(x);
  display: flex;
  flex-direction: column;
  gap: 12px 0;
  margin-top: 24px;
}

.row {
  display: flex;
  gap: 0 6px;
  white-space: nowrap;

  &::before,
  &::after {
    content: '';
    border: 5px solid transparent;
  }
}

.item {
  @include font-size-14-medium;
  @include touch-guide(40px);
  position: relative;
  display: flex;
  align-items: center;
  gap: 0 4px;
  height: 32px;
  padding: 0 12px;
  border-radius: 40px;
  background-color: $tmobi-blue-50;
  color: $gray-900;
}
