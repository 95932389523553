@mixin keyframeSpin() {
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
}

@mixin keyframeBlink() {
  @keyframes blink {
    50% {
      opacity: 0.3;
    }
  }
}

@mixin keyframeFadeOut() {
  @keyframes fadeout {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
}
