@charset "UTF-8";

@import '../../@tmds/tmdscolor';
@import '../../@tmds/tmdsfonts';
@import '../../@tmds/variables/color';

@import '../../basics/variables';
@import '../../basics/mixins';
@import '../../basics/functions';
@import '../../basics/extends';
@import '../../basics/keyframes';
@import '../../basics/tmdscolors';
@import '../../basics/publictranscolors';

.wrap {
}
.date {
  @include tmobi-font-medium(12px, 16px);
  margin: 4px 0 8px 20px;
  color: $text-basic-caption;
}
.panel {
  position: relative;
  width: calc(100% - 110px);
  margin-left: 16px;

  &.full {
    width: calc(100% - 32px);
    margin: 0 16px;

    .cell {
      &:last-of-type {
        .item {
          &::before {
            display: none;
          }
        }
      }
    }
  }
}
.list {
  width: 100%;
}
.cell {
  &:nth-child(3) {
    .item {
      &::before {
        display: none;
      }
    }
  }
}
.item {
  @include touch-guide;
  position: relative;
  display: flex;
  align-items: center;
  gap: 0 12px;
  width: 100%;
  padding: 14px 16px 14px 4px;
  text-align: left;
  border-radius: 8px;

  &::before {
    content: '';
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    border-bottom: 1px solid $gray-100;
  }
  .ranking {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .no {
      @include tmobi-font-bold(15, 22px);
      width: 22px;
      text-align: center;
    }
    .ico {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 12px;

      &:empty {
        display: none;
      }
    }
  }
  .info {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    flex: 1;

    .name {
      @include tmobi-font-medium(14px, 20px);
      overflow: hidden;
      display: -webkit-box;
      width: 100%;
      margin-bottom: 4px;
      word-break: break-all;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
    .category {
      @include tmobi-font-medium(12px, 16px);
      color: $gray-400;
    }
  }
}
.more {
  @include touch-guide(50%);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  right: -74px;
  transform: translateY(-50%);
  width: 48px;
  height: 48px;
  border: 1px solid $gray-200;
  border-radius: 50%;
}
