@charset "UTF-8";

@import '../@tmds/tmdscolor';
@import '../@tmds/tmdsfonts';
@import '../@tmds/variables/color';

@import '../basics/variables';
@import '../basics/mixins';
@import '../basics/functions';
@import '../basics/extends';
@import '../basics/keyframes';
@import '../basics/tmdscolors';
@import '../basics/publictranscolors';

.banner {
  @include common-banner-layout;
  @include common-banner-style;
  min-height: 56px;

  position: relative;
  z-index: $Z-ad-banner;

  &.hide {
    display: none;
  }

  .banner_box {
    @include common-banner-style;
    overflow: hidden;
    margin: 0 auto;
  }

  .fallback_banner {
    @include common-banner-layout;
    @include common-banner-style;
    height: 56px;
    img {
      height: 100%;
    }
  }
}
